import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import './Reports.scss';
import DateField from "../../components/SharedComponents/DateField/DateField";
import { withUserContext } from '../../../contexts/UserContext';
import TalentVoucher from './TalentVoucher';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SelectField from '../SharedComponents/SelectField/SelectField';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { downloadUrl, extractFilenameFromUrl } from "../../Common/Helper";
import messages from '../../Common/Messages.json';
import './Contract.scss';
import ReportButton from './ReportButton';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import MessageModal from '../SharedComponents/MessageModal';

class SagTimeReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportDetails: [],
            ContractModal: false,
            pdfRes: null,
            enable: false
        }
    }

    onChangeCallBack = (field, val) => {
        this.setState({
            [field]: val,
            postInitiated: false
        });
    }

    getReportNameByReportType(reportType, contractType) {
        if (reportType?.toLowerCase() === 'contract only') {
            let contractReportName = null;
            if (contractType === "Day Player") {
                contractReportName = "rptContract";
            } else if (contractType === "Sag Daily") {
                contractReportName = "rptSagDailyContract";
            }
            return contractReportName;
        } else if (reportType?.toLowerCase() === 'packet') {
            let contractPacketReportName = null;
            if (contractType === "Day Player") {
                contractPacketReportName = "rptContractPacket";
            } else if (contractType === "Sag Daily") {
                contractPacketReportName = "rptSagDailyContractPacket";
            }
            return contractPacketReportName;
        } else if (reportType?.toLowerCase() === 'with talent voucher') {
            let contractPacketReportName = null;
            if (contractType === "Day Player") {
                contractPacketReportName = "rptContractTalentVoucher";
            } else if (contractType === "Sag Daily") {
                contractPacketReportName = "rptSagDailyContractTalentVoucher";
            }
            return contractPacketReportName;
        }
    }

    onClickGenratePdf() {
        if (this.props?.reportDetails.show_season_id && this.props?.reportDetails.episode_id && this.props?.reportDetails.contract_id && this.props?.reportDetails?.show_id) {
            this.setState({ postInitiated: true, enable: false });
            const selectedPerformer = this.props?.performerOption.filter(performer => performer.value === this.props?.reportDetails.contract_id);
            this.props.reportDetails.performer_id = selectedPerformer[0]?.performer_id;
            let searchPayload = {
                show_season_id: this.props?.reportDetails.show_season_id,
                episode_id: this.props?.reportDetails.episode_id,
                performer_id: this.props?.reportDetails.performer_id,
                contract_date: selectedPerformer[0]?.contract_date,
                contract_id: this.props?.reportDetails.contract_id
            };
            Object.keys(searchPayload).map((obj) => {
                if (searchPayload[obj] === undefined) {
                    searchPayload[obj] = null;
                }
            });
            let encryptTedStr = btoa(JSON.stringify(searchPayload));
            let load = {
                payload: "casterAnimationGenerateReport",
                reportName: this.getReportNameByReportType(this.props?.selectedReportType, selectedPerformer[0]?.contract_type),
                fileName: "rptContract",                
                searchJson: encryptTedStr,
                showHtml: 0,
                encryptionType: 1
            };
            CasterService.getDataWS(load).then(
                (webSocketConn) =>
                    (webSocketConn.onmessage = (e) => {
                        let response = JSON.parse(e.data);
                        if (response?.statusCode == 200 && response?.body?.message) {
                            this.setState({ ContractModal: true, pdfRes: response.body.message })
                        }
                        else {
                            this.setState({ postInitiated: false })
                            const filename = extractFilenameFromUrl(response?.body);
                            let decodedFile = decodeURIComponent(filename)
                            downloadUrl(response?.body, decodedFile);
                        }
                    })
            );
        } else {
            this.setState({ postInitiated: false });
            this.setState({ enable: true });
        }
    }

    render() {
        return (
            <div>
                <MDBContainer className="reportContainer TalentVoucherContainer">
                    <span className="header">
                        The file will generate in your browser as PDF.
                    </span>
                    <MDBRow>
                        <MDBCol md={8}>
                            <SearchSelectField
                                id={"show"}
                                label={"Select Show"}
                                isMandatory={true}
                                showMandatory={(!this.props?.reportDetails?.show_id && this.state.enable) || false}
                                searchSelect={true}
                                mandatoryWarning={messages.mandatoryWarning}
                                defaultMenuText={"-"}
                                options={this.props?.showOption}
                                valueSelected={(e, value) =>
                                    this.props.handleGeneralEdit("show_id", value)
                                }
                                searchText={(e) => this.props?.getShow("SHOW", e.target.value)}
                                detail_selected={this.props?.reportDetails?.show_id}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}>
                            <SelectField
                                id={"season"}
                                value={this.props?.reportDetails?.show_season_id}
                                label={"Select season"}
                                isMandatory={true}
                                showMandatory={(!this.props?.reportDetails?.show_season_id && this.state.enable)|| false}
                                mandatoryWarning={messages.mandatoryWarning}
                                defaultMenuText={"-"}
                                options={this.props?.seasonOption}
                                onChange={(e) =>
                                    this.props.handleGeneralEdit("show_season_id", e.target.value)
                                }
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}>
                            <SelectField
                                id={"episode"}
                                value={this.props?.reportDetails?.episode_id}
                                label={"Select episode"}
                                isMandatory={true}
                                showMandatory={(!this.props?.reportDetails?.episode_id && this.state.enable) || false}
                                mandatoryWarning={messages.mandatoryWarning}
                                defaultMenuText={"-"}
                                options={this.props?.episodeOption}
                                onChange={(e) =>
                                    this.props.handleGeneralEdit("episode_id", e.target.value)
                                }
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}>
                            <SelectField
                                id={"performer"}
                                value={this.props?.reportDetails?.performer_id}
                                label={"Select Performer"}
                                isMandatory={true}
                                showMandatory={(!this.props?.reportDetails?.contract_id && this.state.enable) || false}
                                mandatoryWarning={messages.mandatoryWarning}
                                defaultMenuText={"-"}
                                options={this.props?.performerOption}
                                onChange={(e) =>
                                    this.props.handleGeneralEdit("contract_id", e.target.value)
                                }
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                className="radio-font"
                            >
                                <MDBCol md={4}>                                   
                                    <FormControlLabel
                                        className="LabelSize"
                                        value="Contract Only"
                                        control={<Radio checked={this.props?.selectedReportType === 'Contract Only'} />}
                                        label={<span style={{ whiteSpace: 'nowrap' }}>Contract Only</span>}
                                        onChange={(e) => this.props.handleGeneralEdit("Contract Only", e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={5}>
                                    <FormControlLabel
                                        className="LabelSize"
                                        value="With Talent Voucher"
                                        control={<Radio checked={this.props?.selectedReportType === 'With Talent Voucher'} />}
                                        label={<span style={{ whiteSpace: 'nowrap' }}>With Talent Voucher</span>}
                                        onChange={(e) => this.props.handleGeneralEdit("With Talent Voucher", e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={3}>
                                    <FormControlLabel
                                        className="LabelSize"
                                        value="Packet"
                                        control={<Radio checked={this.props?.selectedReportType === 'Packet'} />}
                                        label="Packet"
                                        onChange={(e) => this.props.handleGeneralEdit("Packet", e.target.value)}
                                    />
                                </MDBCol>
                            </RadioGroup>
                        </FormControl>
                    </MDBRow>
                    <ReportButton
                        onClickGenratePdf={this.onClickGenratePdf.bind(this)}
                        handleClose={this.props?.handleClose}
                        postInitiated={this.state.postInitiated}
                    />
                </MDBContainer>
                {
                    this.state.ContractModal &&
                    <MessageModal
                        title={"PDF Download Failed"}
                        open={this.state.ContractModal}
                        message={
                            this.state.pdfRes
                        }
                        primaryButtonText={""}
                        secondaryButtonText={"Cancel"}
                        handleClose={(e) => this.setState({ ContractModal: false })}
                    />
                }
            </div>
        )
    }
}

export default withUserContext(SagTimeReport)
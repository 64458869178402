import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import ActionButtons from '../../components/SharedComponents/ActionButtons/BasicActionButtons';
import BasicCheckBox from '../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import './AddressComponent.scss';
import { validateCharNumHashSpaceOnly, validateCharNumCommaDotWithSpace, validateCharctersOnly, validateNumbersOnly, validateCharctersSpaceOnly, formatZipCode, validateNumHyphenOnly, formatPhoneNumbers, checkZipCode } from '../Helper';
import BasicTextArea from '../../components/SharedComponents/BasicTextArea/BasicTextArea';
import messages from '../Messages.json';
import sizeLimits from '../SizeLimits.json';
import { withUserContext } from '../../../contexts/UserContext';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import { Popover } from '@material-ui/core';
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';

class AddressComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addressTypes: [],
            selectedCountryValue: 0,
            selectedStateValue: '',
            intlCountryStatus: false,
            intlStateStatus: false,
            address: { ...props?.address },
            showError: false
        }
    }

    componentDidMount() {
        this.getLookupValues('Address');
        this.getLookupValues('Country');
        this.getLookupValues('State');
        if (this.props?.id && this.props?.mode == 'edit') {
            let address = this.props?.selectedAddress;
            address = { ...address, country: { value: address?.country, text: address?.country } };
            address?.country?.value === 'United States' || address?.country?.value === 'USA' || address?.country?.value === 'US' ? address?.state ? address = { ...address, state: { value: address?.state, text: address?.state } } : null : null
            this.setState({ address: address }, () => {

            })
        }
    }

    getLookupValues = (type) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {

                let formattedList = response.data?.map(item => ({ value: item?.lookup_value, label: item?.lookup_value, id: item?.lookup_id }));
                if (type == "Address") {
                    this.setState({ addressTypes: formattedList });
                } else if (type == "Country") {
                    let formattedListCountry = response.data?.map(item => ({ value: item?.lookup_value, text: item?.lookup_value, id: item?.lookup_id }));
                    let defaultCountry = formattedListCountry.filter(item => {
                        return (item.label === "United States" || item.label === "USA" || item.label === "US")
                    })
                    if (this.props?.isTalent) {
                        this.setState((prevState) => ({
                            address: {
                                ...prevState?.address,
                                ["country"]: this.state.address?.country
                            },
                            countryList: formattedListCountry
                        }))
                    }
                    else {
                        this.setState((prevState) => ({
                            address: {
                                ...prevState?.address,
                                ["country"]: this.state.address?.country ? this.state.address?.country : defaultCountry[0]?.value
                            },
                            countryList: formattedListCountry
                        }))
                    }
                } else if (type == "State") {
                    let formattedListState = response.data?.map(item => ({ value: item?.lookup_value, text: item?.lookup_value, id: item?.lookup_id }));
                    this.setState({ stateList: formattedListState });
                }
            },
                (err) => {
                    console.log("Error in fetching lookup:", err)
                })
    }
    onChangeCallback = (field, value) => {
        value = value == "" ? null : value;
        if (field === "is_primary") {
            value = value === true ? 1 : 0;
        }
        this.setState((prevState) => ({
            address: {
                ...prevState?.address,
                [field]: value,
                ...(field == 'country') && { 'state': null }
            }
        }), () => {
            if (field == 'state' && (this.state.address?.state?.value)) {
                let defaultCountry = this.state.countryList.find(item => {
                    return (item.text === "United States" || item.text === "USA" || item.text === "US")
                })
                this.setState((prevState) => ({
                    address: {
                        ...prevState?.address,
                        ['country']: defaultCountry,
                        // ...(field == 'country') && { 'state': null }
                    }
                }))
            }
        })

    }

    onClick = () => {
        this.setState({ open: true })
    }
    validateAddress() {
        let isValid = false;
        this.setState({ showError: false })
        let address = { ...this.state?.address }
        address = { ...address, country: address?.country?.value };
        address?.country === 'United States' || address?.country === 'USA' || address?.country === 'US' ? address?.state ? address = { ...address, state: address?.state?.value } : null : null
        isValid = address?.address_type && address?.address &&
            address?.country && (address?.zip?.length > 0 ? (address?.country === 'United States' || address?.country === 'USA' || address?.country === 'US' ? (address?.zip.length <= sizeLimits?.intlZipCharacterLimit && (address?.zip?.length == sizeLimits?.intlZipCharacterLimit ? checkZipCode(address?.zip) : true)) : true) : true)
        if (isValid) {
            this.props?.onSave(address);
        }
        else {
            this.setState({ showError: true })
        }

    }
    render() {
        return (
            <>
                <MDBContainer className="AddressComponentContainer">
                    <MDBCol>
                        <Popover
                            open={this.props?.open}
                            anchorEl={this.props?.setPopOver}
                            onClose={() => { this.props?.popOverClose() }}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            PaperProps={{ style: { width: '30%', backgroundColor: '#F2F2F2' } }}
                            className={this.props?.className}
                        >
                            <div className="pop-over-style">
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <SelectField
                                            id={"type"}
                                            label={"Type"}
                                            isMandatory={true}
                                            showMandatory={this.state?.showError}
                                            value={this.state?.address?.address_type}
                                            // placeHolderText={"Type"}
                                            onChange={(e) => this.onChangeCallback('address_type', e.target.value)}
                                            options={this.state.addressTypes || []}
                                            disabled={this.props.disabled || false}
                                        />
                                    </MDBCol>
                                    <MDBCol md={6}>
                                        <BasicCheckBox
                                            label="Check if Primary"
                                            id={"isPrimary"}
                                            disabled={this.props.disabled || false}
                                            checked={this.state?.address?.is_primary == 1 ? true : false}
                                            onChange={(e) => this.onChangeCallback('is_primary', e.target.checked)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={8}>
                                        <BasicTextArea
                                            id={"addressLine"}
                                            rows={4}
                                            isMandatory={true}
                                            showMandatory={this.state?.showError}
                                            value={this.state?.address?.address}
                                            limit={sizeLimits.addressCharacterLimit}
                                            limitWarning={messages.exceed100CharacterWarning || ""}
                                            label={"Address"}
                                            // placeholder={"Address"}
                                            showAsLabel={this.props.disabled || false}
                                            onChange={(e) => this.onChangeCallback("address", e.target.value.length > 0 ? e.target.value : null)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicTextField id="city"
                                            value={this.state?.address?.city}
                                            onChange={(e) => this.onChangeCallback('city', e.target.value)}
                                            label={"City"}
                                            // placeholder="City"
                                            disabled={this.props.disabled || false}
                                            limit={sizeLimits.cityCharacterLimit}
                                            showAsLabel={this.props.disabled || false}
                                            inValidInput={messages.invalidInput || ""}
                                            limitWarning={messages.exceed20CharacterWarning || ""} />
                                    </MDBCol>
                                    <MDBCol md={6} className="state">
                                        {
                                            (this.state?.address?.country?.value === 'United States' || this.state?.address?.country?.value === 'USA' || this.state?.address?.country?.value === 'US' || !this.state?.address?.country?.value) ?
                                                <SearchSelectField
                                                    id={"state"}
                                                    disabled={this.props.disabled || false}
                                                    value={this.state?.address?.state || []}
                                                    options={this.state.stateList || []}
                                                    onChange={(e, value) => this.onChangeCallback('state', value)}
                                                    width={'100%'}
                                                    showAsLabel={this.props.disabled || false}
                                                    label={"State"}
                                                />
                                                // <SelectField
                                                //     id={"state"}
                                                //     // placeHolderText={"State/Province"}
                                                //     disabled={this.props.disabled || false}
                                                //     label={"State"}
                                                //     value={this.state?.address?.state}
                                                //     options={this.state.stateList || []}
                                                //     onChange={(e) => this.onChangeCallback('state', e.target.value)}
                                                //     showAsLabel={this.props.disabled || false}
                                                //     stateLabel={this.props.disabled ? true : false}
                                                // />
                                                :
                                                <BasicTextField id="state"
                                                    limit={sizeLimits.stateCharacterLimit}
                                                    disabled={this.props.disabled || false}
                                                    inValidInput={messages.invalidInput || ""}
                                                    label={"State"}
                                                    limitWarning={messages.exceed20CharacterWarning || ""}
                                                    // placeholder={"State/Province"}
                                                    value={this.state?.address?.state}
                                                    onChange={(e) => this.onChangeCallback('state', e.target.value)}
                                                    // disabled={this.props.list[addressConfig?.countryId] ? false : true}
                                                    showAsLabel={this.props.disabled || false} />

                                        }
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='mt-2'>
                                    <MDBCol md={4}>
                                        <BasicTextField id="zipCode"
                                            disabled={this.props.disabled || false}
                                            // limit={(this.state?.address?.country == 'United States' && this.state?.address?.country == 'USA' && this.state?.address?.country == 'US') ? sizeLimits.intlZipCharacterLimit : null}
                                            // limitWarning={(this.state?.address?.country != 'United States' && this.state?.address?.country != 'USA' && this.state?.address?.country != 'US') ? null :  messages.addressLimitIntl}
                                            value={this.state?.address?.zip}
                                            onChange={(e) => (this.state?.address?.country?.value == 'United States' || this.state?.address?.country?.value == 'USA' || this.state?.address?.country?.value == 'US') ? this.onChangeCallback('zip', formatZipCode(e.target.value)) :
                                                this.onChangeCallback('zip', e.target.value)}
                                            // onChange={(e) => 
                                            //     this.onChangeCallback('zip', this.props?.isTalent ? formatZipCode(e.target.value) : e.target.value)}
                                            // placeholder="Zip Code"
                                            label={"Postal Code"}
                                            showAsLabel={this.props.disabled || false}
                                            inValidInput={messages.invalidInput}
                                            fieldValid={this.state?.address?.zip ? ((this.state?.address?.country?.value == 'United States' || this.state?.address?.country?.value == 'USA' || this.state?.address?.country?.value == 'US') ? (this.state.address?.zip?.length > 9 ? !checkZipCode(this.state.address?.zip) ? true : false : false) : false) : false} />
                                    </MDBCol>
                                    <MDBCol md={6} className="countryRow">
                                        <SearchSelectField
                                            id={"country"}
                                            disabled={this.props.disabled || false}
                                            value={this.state?.address?.country || []}
                                            options={this.state.countryList || []}
                                            onChange={(e, value) => this.onChangeCallback('country', value)}
                                            width={'100%'}
                                            showMandatory={this.state?.showError}
                                            isMandatory={true}
                                            showAsLabel={this.props.disabled || false}
                                            label={"Country"}
                                        />
                                        {/* <SelectField id={"country"}
                                            // placeHolderText={"Country"}
                                            disabled={this.props.disabled || false}
                                            value={this.state?.address?.country}
                                            options={this.state.countryList || []}
                                            isMandatory={true}
                                            label={"Country"}
                                            showMandatory={this.state?.showError}
                                            onChange={(e) => this.onChangeCallback('country', e.target.value)}
                                            showAsLabel={this.props.disabled || false}
                                        /> */}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='mt-2'>
                                    <MDBCol md={8}></MDBCol>
                                    <MDBCol md={2} className='d-flex justify-content-end'>
                                        <BasicButton
                                            className={"save-address"}
                                            disabled={this.props.disabled || false}
                                            variant="contained"
                                            text="save"
                                            icon={"save"}
                                            onClick={this.validateAddress.bind(this)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={2} className='d-flex justify-content-end'>
                                        <BasicButton
                                            variant="outlined"
                                            text=" "
                                            icon={"times"}
                                            onClick={this.props?.handleClose}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        </Popover>
                    </MDBCol>
                </MDBContainer>
            </>
        );
    }
}

export default withUserContext(AddressComponent);
import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from "../../components/SharedComponents/BasicTextField/BasicTextField";
import SelectField from "../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextArea from "../../components/SharedComponents/BasicTextArea/BasicTextArea";
import "./ProjectModal.scss";
import { withUserContext } from "../../../contexts/UserContext";
import messages from "../../Common/Messages.json";
import BasicLabel from "../../components/SharedComponents/BasicLabel/BasicLabel";
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { projectDetails } from "../Project/ProjectConfig";
import { CircularProgress } from '@material-ui/core';
import { validateThreeNumbersOnly, validateNumbersOnly, validateSapGlCode } from "../../Common/Helper";
import NotificationComponent from '../../../caster-tv-jwp/Common/NotificationComponent/NotificationComponent';

class NewProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newProjectData: projectDetails,
      projectTypeOptions: [],
      fromYearOptions: [],
      toYearOptions: [],
      outletOptions: [],
      productionOptions: [],
      intendedMarketOptions: [],
      unionOptions: [],
      postInitiated: false,
      isSubmit: false,
      isLoader: false,
      showError: false,
      showSuccess: false,
      showFail: false,
      unsavedTitle: "Unsaved Changes",
      fieldChanged: false,
      failMessage: "Saving changes failed!",
      saveTitle: "Success",
      saveEditMessage: "The changes are saved successfully!"
    };
  }

  componentDidMount = () => {
    this.getSeasonData("season");
    this.getProjectTypeData("lu_project_type", "");
    this.getOutletData("lu_outlet", "");
    this.getIntendedMarketData("lu_intended_market", "");
    this.getProductionEntityData("lu_production_entity", "");
    this.getUnionData("lu_union", "");
    if (this.props.mode === "Edit" || this.props.mode === "Copy") {
      this.getAllProjectData(this.props.id)
    }
  };

  componentWillUnmount = () => {
    this.getSeasonData("season");
    this.getProjectTypeData("lu_project_type", "");
    this.getOutletData("lu_outlet", "");
    this.getIntendedMarketData("lu_intended_market", "");
    this.getProductionEntityData("lu_production_entity", "");
    this.getUnionData("lu_union", "");
  };

  getAllProjectData = (projectId) => {
    this.setState({ isSubmit: true, isLoader: true })
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterTvJwpProject?projectId=${projectId}`,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let projectData=res?.data?.[0];
        if(this.props.mode==='Copy'){
          projectData.season_no=null,
          projectData.season_id=null,
          projectData.gl_code=null,
          projectData.project_notes=null
        }   
        this.setState({ newProjectData: projectData, isSubmit: false, isLoader: false })
        if (this.props.mode === "Edit" || this.props.mode === "Copy") {
          this.setState({
            newProjectData: {
              ...res?.data?.[0],
              production_entity: {
                value: res?.data?.[0]?.production_entity_id,
                text: res?.data?.[0]?.production_entity_name
              }
            }
          });
        }
      })
      .catch((error) => {
        this.setState({ isSubmit: false, isLoader: false })
        console.log(error, "error in getAllProjectData method");
      });
  };

  handleOnchange = (field, value) => {
    this.setState((prevState) => ({
      newProjectData: {
        ...prevState.newProjectData,
        [field]: value,
      },
    }));
  };

  notificationComponent = (value, mode = "fail") => {
    switch (mode) {
      case "success":
        return this.setState({ showSuccess: value });
      case "fail":
        return this.setState({ showFail: value });
    }
  };

  getSeasonData = (objectType) => {
    this.setState({ isSubmit: true })
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterTvJwpLookupDetails?objectType=${objectType}`,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let filteredList = res.data?.map((item) => ({
          value: item.season_id,
          label: item.start_year,
        }));
        let filterToyearList = res.data?.map((item) => ({
          value: item.season_id,
          label: item.end_year,
        }));
        this.setState({
          fromYearOptions: filteredList,
          toYearOptions: filterToyearList,
          isSubmit: false
        });
      })
      .catch((error) => {
        this.setState({ isSubmit: false })
        console.log(error, "error in getSeasonData method");
      });
  };

  getProjectTypeData = (objectType, searchString) => {
    this.setState({ isSubmit: true })
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterTvJwpLookupDetails?objectType=${objectType}&searchString=${searchString}`,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let filteredList = res.data?.map((item) => ({
          value: item.id,
          label: item.name,
          is_active: item.is_active
        }));
        this.setState({
          projectTypeOptions: filteredList,
          isSubmit: false
        });
      })
      .catch((error) => {
        this.setState({ isSubmit: false })
        console.log(error, "error in getProjectTypeData method");
      });
  };

  getOutletData = (objectType, searchString) => {
    this.setState({ isSubmit: true })
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterTvJwpLookupDetails?objectType=${objectType}&searchString=${searchString}`,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let filteredList = res.data?.map((item) => ({
          value: item.id,
          label: item.name,
          is_active: item.is_active
        }));
        this.setState({
          outletOptions: filteredList,
          isSubmit: false
        });
      })
      .catch((error) => {
        this.setState({ isSubmit: false })
        console.log(error, "error in getOutletData method");
      });
  };

  getProductionEntityData = (objectType, searchString) => {
    this.setState({ isSubmit: true })
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterTvJwpLookupDetails?objectType=${objectType}&searchString=${searchString}`,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let filteredList = res.data?.map((item) => ({
          value: item.id,
          text: item.name,
          is_active: item.is_active
        }));
        let removeDuplicates = filteredList.filter((value, index, arr) =>
          index === arr.findIndex((item) => (
            item.value === value.value))
        )
        this.setState({
          productionOptions: removeDuplicates,
          isSubmit: false
        });
      })
      .catch((error) => {
        this.setState({ isSubmit: false })
        console.log(error, "error in getProductionEntityData method");
      });
  };

  getIntendedMarketData = (objectType, searchString) => {
    this.setState({ isSubmit: true })
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterTvJwpLookupDetails?objectType=${objectType}&searchString=${searchString}`,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let filteredList = res.data?.map((item) => ({
          value: item.id,
          label: item.name,
          is_active: item.is_active
        }));
        this.setState({
          intendedMarketOptions: filteredList,
          isSubmit: false
        });
      })
      .catch((error) => {
        this.setState({ isSubmit: false })
        console.log(error, "error in getIntendedMarketData method");
      });
  };

  getUnionData = (objectType, searchString) => {
    this.setState({ isSubmit: true })
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterTvJwpLookupDetails?objectType=${objectType}&searchString=${searchString}`,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let filteredList = res.data?.map((item) => ({
          value: item.id,
          label: item.name,
          is_active: item.is_active
        }));
        this.setState({
          unionOptions: filteredList,
          isSubmit: false
        });
      })
      .catch((error) => {
        this.setState({ isSubmit: false })
        console.log(error, "error in getUnionData method");
      });
  };

  validateBeforeSave = () => {
    let canSubmit = false;
    canSubmit =
    this.state.newProjectData?.project_name &&
    this.state.newProjectData?.project_type_id &&
      validateNumbersOnly(this.state.newProjectData?.season_no) &&
      this.state.newProjectData?.union_id &&
      this.state.newProjectData?.season_id &&
      validateSapGlCode(this.state.newProjectData?.gl_code)
        ? true
        : false;
    return canSubmit;
  };

  getProjectDetails = (projectId) => {
    this.setState({ isProjectDetailsLoader: true })
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterTvJwpProject?projectId=${projectId}`,
      this.props?.userContext?.user_profile?.tenant_id, 1
    )
      .then((res) => {
        let filteredList = res.data[0]
        this.setState({
          projectDetails: filteredList,
        });
      })
      .catch((error) => {
        console.log(error, "error in season get method");
      });
  };

  getProjectAssignmentDetails = (projectId) => {
    this.setState({ isProjectDetailsLoader: true })
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterTvJwpProjectAssignmentDetails?projectId=${projectId}`,
      this.props?.userContext?.user_profile?.tenant_id, 1
    )
      .then((res) => {
        let filteredList = res.data[0].user_list
        this.setState({
          renderList: filteredList,
        });
      })
      .catch((error) => {
        console.log(error, "error in season get method");
      });
  };

  handleSubmit = () => {
    let payload = { ...this.state.newProjectData };
    payload.production_entity_id = this.state.newProjectData?.production_entity?.value
    payload.season_no = payload.season_no.toString()
    payload.project_id = null
    let projectData = { ...this.state.newProjectData }
    projectData.season_no = projectData.season_no.toString()
    if (this.validateBeforeSave()) {
      this.setState({ postInitiated: true, isSubmit: true });
      CasterService.postDataParams(
        Constants.CasterServiceBaseUrl + "/casterTvJwpProject",
        this.props.mode === "Edit" ? projectData : payload,
        this.props.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          if (response.data.error) {
            this.props?.fieldChanged(true, "fail");
            this.notificationComponent(true,"fail")
          } else if (this.props?.isProjectAssignment) {
            if( this.notificationComponent(true,"success")){
              this.props?.toggleRefresh();
              this.props?.onClose()
              this.props?.fieldChanged(true, "success");
            }
            this.props?.toggleRefresh();
          } else {
            // if( this.notificationComponent(true,"success")){
              this.notificationComponent(true,"success")
              this.getSeasonData("season");
              this.getProjectTypeData("lu_project_type", "");
              this.getOutletData("lu_outlet", "");
              this.getIntendedMarketData("lu_intended_market", "");
              this.getProductionEntityData("lu_production_entity", "");
              this.getUnionData("lu_union", "");
              if(this.props?.mode === "Edit"){
                // this.getProjectDetails(response.data[0].project_id)
                if(this.props?.fromProject){
                  this.props.getProjectDetails(response.data[0].project_id)
                }else{
                  this.getProjectAssignmentDetails(response.data[0].project_id)
                  this.props?.getLandingPageTableData()
                  this.props?.handleEditModalClose()
                }
              } else if (this.props?.mode === "Copy") {
                if(this.props?.fromProject){
                this.props.getProjectDetails(response.data[0].project_id)
                }else{
                  this.getProjectAssignmentDetails(response.data[0].project_id)
                this.props?.getLandingPageTableData()
                this.props?.handleCopyModalClose()
                }
                
              } else {
                this.props?.getLandingPageTableData()
                this.props?.onClose()
              }
            // }
           
           
          }
          this.setState({ isSubmit: false, postInitiated: false });
        },
        (err) => {
          this.setState({ isSubmit: false, postInitiated: false });
          // this.notificationComponent(true, "fail");
        }
      );
    }
  };

  handleResetNotify = () => {
    this.setState({
      showFail: false,
      showSuccess: false,
      checkRelationalFailure: false,
    });
    if (this.state.saveFlag) {
      this.closPopup();
    }
  };

  render() { 
    let severity = this.state.showFail
    ? "error"
    : this.state.showSuccess
    ? "success"
    : this.state.checkRelationalFailure
    ? "error"
    : "";
  let messageNotification = this.state.showSuccess
    ? "The changes are saved successfully!"
    : this.state.showFail
    ? "Saving changes failed!"
    : this.state.checkRelationalFailure
    ? "You can not delete this Record because it is being used"
    : "";
    const project_name = this.state.newProjectData?.project_name ? this.state.newProjectData?.project_name:null;
    const season_no = this.state.newProjectData?.season_no? this.state.newProjectData?.season_no:null;
    const season_year = this.state.newProjectData?.season_year? this.state.newProjectData?.season_year:null;
    return (
      <>
        {this.state.isLoader ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress size={34} />
          </div>
        ) : (
          <div>
             <NotificationComponent
          open={
            this.state.showSuccess ||
            this.state.showFail ||
            this.state.checkRelationalFailure
          }
          message={messageNotification}
          severity={severity}
          handleResetNotify={this.handleResetNotify.bind(this)}
        />
            <MDBContainer className="NewProjectModalContainer" id="header">
              {this.props?.mode === "Copy" && <span className="font-header selected-project">
                Selected Project:{project_name} (Season {season_no}) {season_year}
              </span>}
              <MDBRow>
                <MDBRow>
                  <MDBCol md={6}>
                    <BasicTextField
                      className="text"
                      label={"Project Name"}
                      isMandatory={true}
                      showMandatory={this.state.postInitiated}
                      mandatoryWarning={messages.mandatoryWarning}
                      value={this.state.newProjectData?.project_name}
                      onChange={(e) =>
                        this.handleOnchange("project_name", e.target.value)
                      }
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md={6}>
                    <SelectField
                      label={"Project Type"}
                      isMandatory={true}
                      showMandatory={this.state.postInitiated}
                      mandatoryWarning={messages.mandatoryWarning}
                      value={this.state.newProjectData?.project_type_id}
                      options={this.state.projectTypeOptions || []}
                      onChange={(e) => {
                        this.handleOnchange("project_type_id", e.target.value);
                      }}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md={3}>
                    <BasicTextField
                      className="text"
                      label={"Season No."}
                      isMandatory={true}
                      showMandatory={this.state.postInitiated}
                      mandatoryWarning={messages.mandatoryWarning}
                      value={(this.state.newProjectData?.season_no) && this.state.newProjectData?.season_no !== null ? this.state.newProjectData?.season_no?.toString() : ''}
                      onChange={(e) =>
                        this.handleOnchange("season_no", e.target.value)
                      }
                    />
                    {/* {(this.state.newProjectData.season_no?.length > 0 &&
                      (this.state.newProjectData.season_no?.length < 3 || this.state.newProjectData.season_no?.length > 3)) ? (
                      <span className={"subText-font"} style={{ color: "red" }}>please enter three digit only</span>
                    ) : null} */}
                  </MDBCol>
                  <MDBCol md={4} className="Years">
                    <BasicLabel isMandatory={true} text={"Year"} />
                    <MDBRow className="g-0 seasonstartenddate">
                      <MDBCol md={6}>
                        <SelectField
                          className={"fromYear"}
                          isMandatory={true}
                          mandatoryWarning={messages.mandatoryWarning}
                          showMandatory={this.state.postInitiated}
                          options={this.state.fromYearOptions || []}
                          value={this.state.newProjectData?.season_id}
                          onChange={(e) =>
                            this.handleOnchange("season_id", e.target.value)
                          }
                        />
                      </MDBCol>
                      <MDBCol md={6}>
                        <SelectField
                          className={"toYear"}
                          isMandatory={true}
                          mandatoryWarning={messages.mandatoryWarning}
                          showMandatory={this.state.postInitiated}
                          options={this.state.toYearOptions || []}
                          value={this.state.newProjectData?.season_id}
                        />
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol md={3}>
                    <BasicTextField
                      className="text"
                      label={"SAP/GL Code"}
                      isMandatory={true}
                      mandatoryWarning={messages.mandatoryWarning}
                      value={this.state.newProjectData?.gl_code ? this.state.newProjectData?.gl_code : ""}
                      onChange={(e) =>
                        this.handleOnchange("gl_code", e.target.value)
                      }
                    />
                    {!validateSapGlCode(this.state.newProjectData?.gl_code) && (
                      <span className={"subText-font"} style={{ color: "red" }}>Please enter the format as ex:(T12.34567) </span>
                    )}
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md={4}>
                    <SelectField
                      label={"Outlet"}
                      value={this.state.newProjectData?.outlet_id}
                      options={this.state.outletOptions || []}
                      onChange={(e) =>
                        this.handleOnchange("outlet_id", e.target.value)
                      }
                    />
                  </MDBCol>
                  <MDBCol md={4} >
                    <div className={`${this.props?.isProjectAssignment ? 'assignment-project' : 'add-user'}`}>
                      <SearchSelectField
                        id={"Production-Entity"}
                        label={"Production Entity"}
                        value={this.state.newProjectData?.production_entity}
                        options={this.state.productionOptions || []}
                        width={300}
                        onChange={(e, value) => {
                          this.handleOnchange("production_entity", value);
                        }}
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
                {/* <MDBRow>
                  <MDBCol md={5}>
                    <BasicTextField
                      className="text"
                      label={"Signatory"}
                      value={this.state.newProjectData.signator}
                      onChange={(e) =>
                        this.handleOnchange("signator", e.target.value)
                      }
                    />
                  </MDBCol>
                </MDBRow> */}
                <MDBRow>
                  <MDBCol md={4}>
                    <SelectField
                      label={"Intended Market"}
                      value={this.state.newProjectData?.intended_market_id}
                      options={this.state.intendedMarketOptions || []}
                      onChange={(e) =>
                        this.handleOnchange("intended_market_id", e.target.value)
                      }
                    />
                  </MDBCol>
                  <MDBCol md={4}>
                    <SelectField
                      label={"Union"}
                      isMandatory={true}
                      mandatoryWarning={messages.mandatoryWarning}
                      showMandatory={this.state.postInitiated}
                      value={this.state.newProjectData?.union_id !== "" ? this.state.newProjectData?.union_id : "SAG-Aftra 2018"}
                      options={this.state.unionOptions || []}
                      onChange={(e) => this.handleOnchange("union_id", e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol colgroup={3}>
                    <BasicTextArea
                      label={"Project Notes"}
                      value={this.state.newProjectData?.project_notes}
                      onChange={(e) =>
                        this.handleOnchange("project_notes", e.target.value)
                      }
                    />
                  </MDBCol>
                </MDBRow>
              </MDBRow>
              <MDBRow className="p-2 margin">
                <MDBCol md={6} lg={6}></MDBCol>
                <MDBCol md={3} lg={3}>
                  <BasicButton
                    className="btnwidth"
                    variant="contained"
                    text={this.props?.mode === "Edit" ?
                      this.state.isSubmit ? (
                        <CircularProgress color="inherit" size={18} />
                      ) : (
                        "Save"
                      ) : this.state.isSubmit ? (
                        <CircularProgress color="inherit" size={18} />
                      ) : (
                        "Create"
                      )
                    }
                    icon={"Create"}
                    disabled={!this.validateBeforeSave()}
                    onClick={this.handleSubmit}
                  />
                </MDBCol>
                <MDBCol md={3}>
                  <BasicButton
                    type="inline"
                    variant="outlined"
                    text={"Cancel"}
                    onClick={() => {
                      this.props?.onClose();
                    }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        )
        }
      </>
    );
  }
}
export default withUserContext(NewProject);
// export const getFormattedDate = (date, dateField = false) => {
//     if (date) {
//         let formattedDate = new Date(date?.replaceAll('-','/'));
//         let year = formattedDate.getFullYear();
//         let month = (1 + formattedDate.getMonth()).toString().padStart(2, '0');
//         let day = formattedDate.getDate().toString().padStart(2, '0');
//         if (dateField)
//             return year + '-' + month + '-' + day;
//         else
//             return month + '/' + day + '/' + year;
//     }
// }
import moment from 'moment';
export const getFormattedDate = (date, dateField = false) => {
    if (date) {
        // let formattedDate = new Date(date);
        date = new Date(date).toISOString().slice(0, 10);
        let formattedDate = new Date(date+'T00:00:00')
        let year = formattedDate.getFullYear();
        let month = (1 + formattedDate.getMonth()).toString().padStart(2, '0');
        let day = formattedDate.getDate().toString().padStart(2, '0');
        if (dateField)
            return year + '-' + month + '-' + day;
        else
            return month + '/' + day + '/' + year;
    }
}
export const convertDateFormat=(dateString)=>{
    const dateParts = dateString.split('/');
  const year = dateParts[2];
  const month = Number(dateParts[0]).toString().padStart(2, '0');
  const day = Number(dateParts[1]).toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
  }
  
export const getAge = (currentDate) => {
    if (currentDate) {
        var today = new Date();
        var birthDate = new Date(currentDate);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
}

export const getAddress = (field, item, value, addressConfig) => {
    item[field] = value;
    let a = value?.split('\n') || [];
    item[addressConfig.address1] = !(a[0]) ? null : a[0];
    item[addressConfig.address2] = !(a[1]) ? null : a[1];
    let temp = '';
    for (var i = 2; i < a.length; i++) {
        temp += a[i];
    }
    item[addressConfig.address3] = !temp ? null : temp;
    return item;
}

export const getFormattedTime = (date) => {
    let formattedDate = new Date(date);
    let hours = formattedDate.getHours();
    var ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12 || 12;
    hours = hours < 10 ? '0' + hours : hours;
    let mins = formattedDate.getMinutes();
    mins = mins < 10 ? '0' + mins : mins;
    return hours + ':' + mins + " " + ampm;
}

export const getDateAndTime = (dateEntry) => {
    let date = getFormattedDate(dateEntry.split('T')[0]);
    let time = getFormattedTime(dateEntry);
    return date + ", " + time;
}

export const validateEmail = (email) => {
    var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //var emailRegex = /^[A-Za-z0-9]+([\.-_]?[A-Za-z0-9]+)*@[A-Za-z0-9]+([\.-]?[A-Za-z0-9]+)*(\.[A-Za-z]{2,3})+$/;
    return emailRegex.test(email);
}

export const maskSsn = (ssn) => {
    return ssn ? `xxx-xxx-${ssn?.substr(ssn?.length - 4)}` : ssn;
}

export const downloadUrl = (url, reportName) => {
    if(url){
        // reportName = reportName.replaceAll('.','')
        fetch(url).then(response=>response.blob()).then(blob=>{
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', reportName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        })           
    }
}

export const extractFilenameFromUrl=(url)=> {
    const path = new URL(url).pathname;
    const filename = path.substring(path.lastIndexOf('/') + 1);
    return filename;
  }

// export const validatePhone = (phone) => {
//     let phoneRegex = /^[0-9\-\(\)+]+$/;
//     return phoneRegex.test(phone);
// }

export const validatePlusCharacter = (text) =>{
    let charRegex = /^[+]/;
    return charRegex.test(text);
}

export const validateUSNumber = (text) =>{
    let charRegex = /\(\d{3}\) \d{3}-\d{4}$/;
    return charRegex.test(text);
}

export const validatePhoneNumber = (text) =>{
    let charRegex = /^\d{10}$/;
    return charRegex.test(text);
}

export const validateIntlPhoneNumber = (text) =>{
    let charRegex = /^(?:\+\d{1,3}\s\(\d{3}\)\s\d{3}-\d{4}|\+\d{11,18})$/;
    return charRegex.test(text);
}

export const validateIntlNumber = (text) => {
    let charRegex = /^\+\d{1,3}\s?\d{10}$/;
    return charRegex.test(text);
}

export const validateCharctersOnly = (text) => {
    let regex = /^[a-zA-Z!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
    return regex.test(text);
}

export const validateCharctersSpaceOnly = (text) => {
    let regex = /^[a-zA-Z!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
    return regex.test(text);
}

export const validateNumbersOnly = (text) => {
    let regex = /^[\d]*$/;
    return regex.test(text);
}

export const validateNumbersAndHyphenOnly = (text) => {
    let regex = /^[0-9-]{0,10}$/;
    return regex.test(text);
}

export const validateThreeNumbersOnly = (text) => {
    if (typeof text !== 'string'||text === null||text.length<3) {
      return true;
    }
    
    let trimmedText = text.trim();
    let regex = /^\d{1,3}$/;
    return regex.test(trimmedText) && trimmedText.length <= 3;
  };

export const validateRoleNum = (value) => {
    if ((value.toString().indexOf(".") == -1 && value.length == 8)) {
        return value + '.'; //integer >8
    }
    else if ((value.toString().indexOf(".") == -1 && value.length > 9)) {
        return Number(value).toFixed(2)  //integer >8
    }
    else if (value.toString().indexOf(".") == -1 && value.length <= 8) {
        return value; //integer less 8
    } else {
        //decimal
        let decimalPart = value.split('.');
        if (decimalPart[1].toString().length > 2) {
            return (value.split('.').join("") / 100).toFixed(2);
        } else {
            return value
        }
    }
}

export const validateNumbersAndCustomLengthOnly = (text, customLength) => {
    let regexNumberValidation = /^\d*\.?\d{0,2}$/;
    if(text.toString().indexOf(".") == -1){
        return regexNumberValidation.test(text) && text.length <= (customLength-1);        
    }else{
        return regexNumberValidation.test(text) && text.length <= (customLength);
    }
};

  export const validateSapGlCode = (text) => {
    let regex = /^[a-zA-Z]\d{2}\.\d{5}$/;
    return regex.test(text);
  };
  
export const validateCharNumOnly = (text) => {
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
    return regex.test(text);
}

export const validateCharNumHyphenSlashOnly = (text) => {
    let regex = /^[a-zA-Z0-9-/!@#$&()\\-`.+,/\ ]*$/i;
    return regex.test(text);
}

export const validateCharNumWithHyphenSlash = (text) => {
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
    return regex.test(text);
}

export const validateCharNumWithHyphen = (text) => {
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
    return regex.test(text);
}

export const validateCharNumWithHyphenSpace = (text) => {
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
    return text ? regex.test(text) : true;
}

export const validateCharNumCommaDotWithSpace = (text) => {
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
    return regex.test(text);
}

export const validateCharNumOnlyWithSpace = (text) => {
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
    return text ? regex.test(text) : true;
}

export const validateAlpVarCharNumOnlyWithSpace = (text) => {
    let regex = /^[a-zA-Z0-9ÀÁĄ́ÂÃÄB́B̀ĆÉÈÍÌǴḰḾŃǸÓÒṔŔŚS̀ÚẂÝŹ!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
    return text ? regex.test(text) : true;
}

export const validateAlpVarCharOnlyWithSpace = (text) => {    
    let regex = /^[a-zA-ZÀÁĄ́ÂÃÄB́B̀ĆÉÈÍÌǴḰḾŃǸÓÒṔŔŚS̀ÚẂÝŹ!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
    return text ? regex.test(text) : true;
}

export const validateNumPlusOnly = (text) => {
    let regex = /^[0-9+]*$/;
    return regex.test(text);
}

export const validateNumDotOnly = (text) => {
    let regex = /^[0-9.]*$/;
    return regex.test(text);
}

export const validateNumDotUptoTwoPlacesOnly = (text) => {
    let regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    return regex.test(text);
}

export const validateCharNumHashOnly = (text) => {
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
    return regex.test(text);
}

export const validateCharNumRateOnly = (text) => {
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
    return regex.test(text);
}

export const validateCharNumHashSpaceOnly = (text) => {
    // let regex = /^[a-zA-Z0-9#]+\s?[a-zA-Z0-9#]*$/i;
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]/i;
    return regex.test(text);
}

export const validateNumHyphenOnly = (text) => {
    let regex = /^[0-9-]*$/;
    return regex.test(text);
}

export const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

export const genReportName = (obj) => {
    let { reportName, production, season, episode, performer } = obj;
    let name = `${reportName ? reportName + "_" : ""}${production ? production + "_" : ""}${season ? season + "_" : ""}${episode ? episode + "_" : ""}${performer ? performer : ""}`
    name = name.trim().replaceAll(" ", "_");
    return name.charAt(name.length - 1) === "_" ? name.slice(0, -1) : name;
}

export const checkValidEditorText = (value) => {
    if(value.replace(/<(.|\n)*?>/g, '').trim().length === 0 ) {
        return false
    } else {
        return true
    }
}

export const formatPhoneNumbers = (phone) => {
    let containSpace = phone.trim().includes(' ');
    if (phone.length >  10) {
      phone = phone.replace(/[^\d+]/g, '');
    }
    if (phone.length === 12 && validateIntlNumber(phone)){
        if(!containSpace){
            const formattedPhone = phone.substr(0, 2) + ' ' + '(' + phone.substr(2,  3) + ')' + ' ' + phone.substr(5,  3) + '-' + phone.substr(8,  4);
            if (formattedPhone.length >  17) {
                return phone;
            }
            return formattedPhone;   
        }
    }
    if (phone.length ===  10 && validatePhoneNumber(phone)) {
        const formattedPhone = '(' + phone.substr(0,  3) + ')' + ' ' + phone.substr(3,  3) + '-' + phone.substr(6,  4);
        if (formattedPhone.length >  14) {
            return phone;
        }
        return formattedPhone;
    } else {
        return phone;
    }
}

    export const ssnFormat = (text) => {
        if (!text) {
          return '';
        }
      
        let formattedText = text.replace(/[^\d]/g, ''); // Remove non-numeric characters
        if (formattedText.length > 9) {
          formattedText = formattedText.slice(0, 9); // Limit to 9 digits
        }
        let formattedSSN = '';
        if (formattedText.length > 3) {
          formattedSSN = formattedText.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3'); // Format as XXX-XX-XXXX
        } else {
          formattedSSN = formattedText;
        }
      
        return formattedSSN;
      };
      

export const validateAllPhone = (phone) => {
    let intlLength = 12;
    let localLenghth = 10;
    let validatePhone = validatePlusCharacter(phone) ?
    (validateIntlNumber(phone) || validateIntlPhoneNumber(phone)) && phone.length >= intlLength :
      (validateUSNumber(phone) || validatePhoneNumber(phone)) || phone.length >= localLenghth
    return validatePhone;
}

export const isWithinLast18Years = (date) => {
    var currentDate = new Date();
    var minDate = new Date();
    minDate.setFullYear(currentDate.getFullYear() - 18);
    var givenDate = new Date(date); 
    if (givenDate >= minDate) {
        console.log("Date is within the last 18 years.");
        return true;
    } else {
        console.log("Date is more than 18 years ago.");
        return false;
    }
};

  export const validateDate = (selectedDate) => {
    const currentDate = moment().format('MM/DD/YYYY');
  
    if (moment(selectedDate).isSameOrAfter(currentDate)) {
      return true; 
    } else {
      return false; 
    }
  };
  
  
import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import InlineSelectField from '../../components/SharedComponents/CommonInlineSelectField/InlineSelectField';
import ActionButtons from '../../components/SharedComponents/CommonActionButtons/BasicActionButtons';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import CasterService from '../../../services/service';
import { validateEmail } from '../Helper';
import * as Constants from '../../../constants/constants';
import './InputSelectRadio.scss';
import { withUserContext } from '../../../contexts/UserContext';
class InputSelectRadio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMandatory:false,  
            inValidInput:false,      
            mandatoryWarning: "Please complete this mandatory field",
            invalidWarning:"Invalid input!!"
        }
    }

    componentDidMount() {
        // this.getEmailTypes();
    }

    // getEmailTypes = () => {
    //     if (this.props?.typeEndpoint) {
    //         CasterService.getData(Constants.CasterServiceBaseUrl + `/${this.props?.typeEndpoint}`, this.props?.userContext?.active_tenant?.tenant_id)
    //             .then(response => {
    //                 let formattedList = response.data?.map(item => ({  value: item?.lookup_data_id,
    //                     label: item?.lookup_value }));
    //                 this.setState({ emailTypes: formattedList });
    //             },
    //                 (err) => {
    //                     console.log("Error in fetching Email Types:", err)
    //                 })
    //     }
    // }

    onAdd(objectItem) {
        this.setState({ showMandatory: false, inValidInput: false })
        if (!objectItem?.object_type || !objectItem?.value) {
            this.setState({ showMandatory: true });
        }
        else if (this.props?.inputValidateMethod && !this.props?.inputValidateMethod(objectItem?.value)) {
            this.setState({ inValidInput: true });
        }
        else {
            this.props?.addCallback(objectItem);          
        }
    }

    onChangeCallback = (value) => {
        if (value.length > 0) {
            if (!this.props?.inputValidateMethod(value)) {
                this.setState({ inValidInput: true })                
            } else {               
                this.setState({ showMandatory: false,inValidInput: false })
            }
        } else {
            this.setState({ showMandatory: false,inValidInput: false })
        }
    }


    render() {
        const { objectItem } = this.props;
        let hideActions = this.props?.hideActions ? true : false;
        let error = (this.props?.showMandatory && ( objectItem?.value == null ? true : false ))|| this.state?.showMandatory || this.state?.inValidInput || this.props?.fieldValid || false;
        return (
            <div className="InputSelectRadioContainer">
                <MDBRow>
                    {this.props?.label && <BasicLabel text={this.props?.label || ""}
                        isMandatory={this.props?.isMandatory || false} />}
                </MDBRow>
                <MDBRow className={this.props?.isTalent && this.props?.showAsLabel ? 'g-0 marginRight' : 'g-0'}>
                    <MDBCol md={this.props?.radioGroupName === 'crewEmailRadio' && !this.props?.isEditing ? 12 : 10} className="EmailField">
                        <InlineSelectField
                            isEditing={this.props?.isEditing}
                            textFieldLength={this.props?.textFieldLength || 7}
                            selectFieldLength={this.props?.selectFieldLength || 5}
                            textFieldposition="end"
                            limit={this.props?.limit}
                            placeHolderText={this.props?.placeHolderText}
                            isPrimary={objectItem?.is_primary == 1 ? true : false}
                            disabled={this.props?.disabled || false}
                            showAsLabel={this.props?.showAsLabel || false}
                            inValidInput={this.props?.inValidInput}
                            placeholder={this.props?.inputPlaceholder}
                            limitWarning={this.props?.limitWarning}
                            onSelectChange={(e) => this.props?.editCallback(objectItem?.object_id, 'object_type', e.target.value)}
                            onFieldChange={(e) => 
                                
                                {
                                    this.props?.editCallback(objectItem?.object_id, 'value', e.target.value)
                                    this.props?.enableSecondaryError && this.onChangeCallback(e.target.value)
                                }
                            }
                            selectValue={objectItem?.object_type}
                            fieldValue={objectItem?.value}
                            options={this.props?.selectOptions || []}
                            index={this.props?.index}
                            labelNew={this.props?.labelNew}
                            showLabel={this.props?.showLabel}
                            isTalent={this.props?.isTalent}
                        />
                    </MDBCol>
                    {
                        this.props?.isEditing &&
                        !hideActions ? (
                            <MDBCol md={2} className={this.props?.className === "posRelative" ? "posRelative" : null}>
                                <ActionButtons
                                    index={this.props?.index}
                                    keyName={this.props?.keyName}
                                    primaryOnChange={(e) => this.props?.editCallback(objectItem?.object_id, 'is_primary', e.target.checked)}
                                    isPrimary={objectItem?.is_primary == 1 ? true : false}
                                    addCallback={()=>this.onAdd(objectItem)}
                                    removeCallback={()=>this.props?.removeCallback(objectItem?.object_id,'is_delete',1)}
                                    showAdd={this.props?.showAdd}
                                    showRemove={this.props?.showRemove}
                                    showRadio={this.props?.showRadio}
                                    radioGroupName={this.props?.radioGroupName}
                                    isEditing={this.props?.isEditing}
                                    addButton={this.props?.addButton}
                                />
                            </MDBCol>
                        ) : null
                    }
                </MDBRow>
                {
                    error && this.props?.isEditing && <div className="error">{(this.state?.showMandatory && !this.props?.value) ? this.state?.mandatoryWarning : this.state?.inValidInput ? this.state?.invalidWarning :
                        (this.props?.fieldValid ? this.props?.inValidInput : (this.props?.disableSignatory ? this.state?.selectUnion : ""))}</div>
                }
                {
                    this.props?.checkedMultiPrimary ? (
                        <MDBRow>
                            <div className="error">{"Only one email address can be a primary. Multiple selection is not allowed"}</div>
                        </MDBRow>
                    ) : null
                }
            </div>
        );
    }
}

export default withUserContext(InputSelectRadio);
import React, { Component } from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import SelectField from "../SharedComponents/SelectField/SelectField";
import SearchSelectField from "../SharedComponents/SearchSelectField";
import * as Constants from "../../../constants/constants";
import CasterService from "../../../services/service";
import { CircularProgress } from "@material-ui/core";
import { getFormattedDate, formatRate } from "../../Common/Helper";
import RichTextEditor from "../SharedComponents/RichTextEditor/RichTextEditor";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox';
import dompurify from "dompurify";
import {
  standardLanguageCompensationOptions,
  standardLanguageExpenseOptions,
  PerkNewConfig,
  blueResidentPerkText,
  greenLocalPerkText,
} from "./config";
import "./Perks.scss";

// Define an object that maps numbers to their word form
const numbersToWords = {
  0: "zero",
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
  10: "ten",
  11: "eleven",
  12: "twelve",
  13: "thirteen",
  14: "fourteen",
  15: "fifteen",
  16: "sixteen",
  17: "seventeen",
  18: "eighteen",
  19: "nineteen",
  20: "twenty",
  30: "thirty",
  40: "forty",
  50: "fifty",
  60: "sixty",
  70: "seventy",
  80: "eighty",
  90: "ninety",
};

// Define the convertNumberToWords function
function convertNumberToWords(number) {
  // if number present in object no need to go further
  if (number in numbersToWords) return numbersToWords[number];

  // Initialize the words variable to an empty string
  let words = "";

  // If the number is greater than or equal to 100, handle the hundreds place (ie, get the number of hundres)
  if (number >= 100) {
    // Add the word form of the number of hundreds to the words string
    words += convertNumberToWords(Math.floor(number / 100)) + " hundred";

    // Remove the hundreds place from the number
    number %= 100;
  }

  // If the number is greater than zero, handle the remaining digits
  if (number > 0) {
    // If the words string is not empty, add "and"
    if (words !== "") words += " and ";

    // If the number is less than 20, look up the word form in the numbersToWords object
    if (number < 20) words += numbersToWords[number];
    else {
      // Otherwise, add the word form of the tens place to the words string
      //if number = 37, Math.floor(number /10) will give you 3 and 3 * 10 will give you 30
      words += numbersToWords[Math.floor(number / 10) * 10];

      // If the ones place is not zero, add the word form of the ones place
      if (number % 10 > 0) {
        words += "-" + numbersToWords[number % 10];
      }
    }
  }

  // Return the word form of the number
  return words;
}
export default class Perks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      constractStartOptions: [],
      memoOptions: [],
      stdLanguageOptions: [],
      perksList: [],
      contract_date: null,
      memo_type: null,
      isNewPerk: true,
      selectedPerkData: null,
      editorMode: false,
      pageLoader: false,
      contractDetails: null,
      isDelete:null,
      isPA:false,
    };
  }
  componentDidMount() {
    this.getAllPerksData();
    this.getSearchEntity("PERFORMER_DEAL_CONTRACT");
    this.getLookupValues("MEMO_TYPE");
  }
  getSearchEntity(type) {
    CasterService.getData(
      Constants?.CasterServiceBaseUrl +
        `/casterFeatureSearch?objectType=${type}&searchString=null&performer_deal_id=${this.props?.performer_deal_id}`
    ).then(
      (response) => {
        if (response?.data?.error) {
          console.log("error from search", response?.data?.error);
        } else {
          if (type == "PERFORMER_DEAL_CONTRACT") {
            let formattedList = response?.data?.reduce((acc, i) => {
              // if (i?.can_have_wa == 1) {
              acc = [
                ...acc,
                {
                  value: i,
                  text: i?.contract_date ? `${getFormattedDate(i?.contract_date)} ${i?.void === 1 ? 'VOIDED' : ''}` : null,
                  obj: i,
                },
              ];
              // }
              return acc;
            }, []);
            formattedList.push({value: {contract_date:null, deal_date:null, void:null, performer_deal_contract_id:null,}, text : 'All', obj:null})
            this.setState({ constractStartOptions: formattedList?.filter(item => item?.text) });
          }
        }
      },
      (err) => {
        console.log("Error from workactivity search", err);
      }
    );
  }
  getContractDetails(contractId, isOnChangeCall, value) {
    //
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
        `/casterFeaturePerformerDealContract?performer_deal_id=${this.props?.performer_deal_id}&performer_deal_contract_id=${contractId}`,
      this.props.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (response?.data?.error) {
          console.log("error from search", repsonse?.data?.error);
        } else {
          this.setState({ contractDetails: response?.data?.[0] }, () => {
            if (isOnChangeCall) {
              this.onChange("contract_date", value)
            }
          });
        }
      },
      (err) => {
        this.setState({ contractDetails: null });

        console.log("add deal reprsentative: " + err);
      }
    );
  }
  getLookupValues(type) {
    CasterService.getData(
      Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`
    ).then(
      (response) => {
        if (!response || response?.data?.error) {
          console.log("error", response?.data?.error);
        } else {
          let formattedList = response?.data?.map((item) => ({
            value: item?.lookup_value,
            text: item?.lookup_value,
          }));
          this.setState({ memoOptions: formattedList });
        }
      },
      (err) => {
        console.log("error from lookup");
      }
    );
  }
  getAllPerksData() {
    this.setState({ pageLoader: true });
    // casterFeaturePerformerDealPerks
    CasterService.getData(
      Constants?.CasterServiceBaseUrl +
        `/casterFeaturePerformerDealPerks?performer_deal_id=${this.props?.performer_deal_id}&performer_deal_perks_id=null`
    ).then(
      (response) => {
        this.setState({ pageLoader: false });
        if (response?.data?.error) {
          console.log("error from search", repsonse?.data?.error);
        } else {
          this.setState({ perksList: response?.data });
        }
      },
      (err) => {
        this.setState({ pageLoader: false });
        console.log("Error from workactivity search", err);
      }
    );
  }
  onChange(key, value) {
    let stdOptions = [];
    if (key == "memo_type" && value?.value == "Compensation") {
      stdOptions = JSON.parse(
        JSON.stringify(standardLanguageCompensationOptions)
      );
    } else if (
      key == "memo_type" &&
      value?.value == "Expenses/Transportation"
    ) {
      stdOptions = JSON.parse(JSON.stringify(standardLanguageExpenseOptions));
    } else {
      stdOptions = [];
    }

    this.setState(
      (prevState) => ({
        ...prevState,
        [key]: value,
        ...(key == "memo_type" && { stdLanguageOptions: stdOptions }),
      }),
      () => {
        if ((this.state?.contract_date?.text === 'All' ||!this.state?.contract_date)|| (
          this.state?.contract_date?.value?.contract_date && this.state?.memo_type?.value)
        ) {
          let alreadyAdded = this.state?.perksList?.filter(
            (i) =>
              i?.contract_date ==
                this.state?.contract_date?.value?.contract_date &&
              i?.memo_type == this.state?.memo_type?.value
          );
          let isPaCredit= this.state?.perksList.filter((item)=>item?.isPA)
          if (alreadyAdded?.length > 0) {
            this.setState({
              isNewPerk: false,
              selectedPerkData: alreadyAdded?.[0] || null,
              editorMode: true,
              isPA:isPaCredit,
            });
          } else if (alreadyAdded?.length == 0) {
            let newPerk = JSON.parse(JSON.stringify(PerkNewConfig));
            newPerk.memo_type = this.state?.memo_type?.value;
            newPerk.contract_date =
              this.state?.contract_date?.value?.contract_date || null;
            newPerk.performer_deal_contract_id =
              this.state?.contract_date?.value?.performer_deal_contract_id ||
              null;
            if (this.state?.memo_type?.value == "Compensation") {
              let textToAdd = this.generateCompensationLang();
              newPerk.memo_text = textToAdd;
            }

            let perksList =
              JSON.parse(JSON.stringify(this.state?.perksList)) || [];
            perksList = [newPerk, ...perksList];
            this.setState({
              selectedPerkData: newPerk,
              perksList: perksList,
              editorMode: true,
              isNewPerk:true
            });
          }
        }
        if (
          this.state?.contract_date?.value?.contract_date &&
          this.state?.memo_type?.value == "Compensation"
        ) {
          this.getContractDetails(
            this.state?.contract_date?.value?.performer_deal_contract_id
          );
        }
        if (key == "stdLanguageSelected") {
          let selectedPerkData = { ...this.state?.selectedPerkData };
          if (
            this.state?.memo_type?.value == "Compensation" &&
            value?.value == "Add Compensation Language"
          ) {
            let compensationText = this.generateCompensationLang();
            selectedPerkData.memo_text = selectedPerkData?.memo_text
              ? selectedPerkData?.memo_text + "\n" + compensationText
              : compensationText;
            this.setState({ selectedPerkData: selectedPerkData });
          } else if (
            this.state?.memo_type?.value == "Expenses/Transportation"
          ) {
            if (value?.value == "Add Resident/Overseas Trans. Language") {
              selectedPerkData.memo_text = selectedPerkData?.memo_text
                ? selectedPerkData?.memo_text + "\n" + blueResidentPerkText
                : blueResidentPerkText;
              this.setState({ selectedPerkData: selectedPerkData });
            } else if (value?.value == "Add Local Trans. Language") {
              selectedPerkData.memo_text = selectedPerkData?.memo_text
                ? selectedPerkData?.memo_text + "\n" + greenLocalPerkText
                : greenLocalPerkText;
              this.setState({ selectedPerkData: selectedPerkData });
            }
          }
        }
      }
    );
  }
  toggleEditorMode() {
    this.setState({ editorMode: !this.state?.editorMode });
  }
  onCancelClick() {
    let perksList = this.state?.perksList?.filter(
      (i) => i?.performer_deal_perks_id
    );
    this.setState({
      contract_date: null,
      memo_type: null,
      isNewPerk: true,
      selectedPerkData: null,
      editorMode: false,
      perksList: perksList,
      stdLanguageSelected: null,
      isPA:false,
    });
  }
  onBtnClick() {
    if (this.state?.editorMode) {
      this.submitPerk();
    } else {
      if (this.state?.isNewPerk) {
        // add new object

        let newPerk = JSON.parse(JSON.stringify(PerkNewConfig));
        newPerk.memo_type = this.state?.memo_type?.value;
        newPerk.paid_ads=(this.state?.isPA) ? 1 : 0 ;
        newPerk.contract_date = this.state?.contract_date?.value?.contract_date;
        newPerk.performer_deal_contract_id =
          this.state?.contract_date?.value?.performer_deal_contract_id || null;
        if (this.state?.memo_type?.value == "Compensation") {
          let textToAdd = this.generateCompensationLang();
          newPerk.memo_text = textToAdd;
        }
        // else if (this.state?.memo_type == "Expenses/Transportation") {
        //   if (this.props?.union == "EQUITY") {
        //     newPerk.memo_text = blueResidentPerkText;
        //   } else {
        //     newPerk.memo_text = greenLocalPerkText;
        //   }
        // }
        let perksList = JSON.parse(JSON.stringify(this.state?.perksList)) || [];
        perksList = [newPerk, ...perksList];
        this.setState({
          selectedPerkData: newPerk,
          perksList: perksList,
          editorMode: true,
        });
      } else {
        // this.toggleEditorMode();
      }
    }
  }
  generateCompensationLang() {
    let guaranteePeriodType = this.state?.contractDetails?.guarentee_period == 1 ? this.removeLastChar(this.state?.contractDetails?.guarentee_period_type) : this.state?.contractDetails?.guarentee_period_type
    if (this.state?.contractDetails) {
      return `<div>
         ${this.state?.contractDetails?.currency_code || ""}${
        formatRate(this.state?.contractDetails?.rate) || ""
      }/${
        this.state?.contractDetails?.show_as_value == "Weekly"
          ? "week"
          : this.state?.contractDetails?.show_as_value == "Daily"
          ? "day"
          : ""
      } - ${
        this.state?.contractDetails?.guarentee_period
          ? convertNumberToWords(
              this.state?.contractDetails?.guarentee_period
            ) +
            " (" +
            this.state?.contractDetails?.guarentee_period +
            ") " +
              guaranteePeriodType.toLowerCase()
          : ""
      } guarantee.        
         ${
           this.props?.union == "EQUITY" &&
           this.state?.contractDetails?.performer_type != "Stunts"
             ? `Total Guaranteed Payment ${this.state?.contractDetails?.currency_code}${this.state?.contractDetails?.rate}.`
             : ""
         } 
         </div>`;
    } else {
      return "";
    }
  }
  removeLastChar(str) {
    return str && str?.charAt(str?.length - 1) == "s"
      ? str.slice(0, -1)?.toLowerCase()
      : str?.toLowerCase();
  }
  submitPerk() {
    let postJson = JSON.parse(JSON.stringify(this.state?.selectedPerkData));
    postJson.performer_deal_id = this.props?.performer_deal_id;
    (this.state?.isPA) ?postJson.paid_ads=1 :postJson.paid_ads=0;
    // postJson = this.formatPostJson(postJson);
    this.setState({ isPosting: true });
    // if(!this.validateWorkActivity(postJson)){
    //   return;
    // }
    this.setState({ showAddLoader: postJson.is_delete !== 1 ? true : false });
    CasterService.postDataParams(
      Constants.CasterServiceBaseUrl + `/casterFeaturePerformerDealPerks`,
      postJson
    ).then(
      (response) => {
        this.setState({ isPosting: false, showAddLoader: false , isDelete:null,isPA:false});
        if (response?.data?.error) {
          console.log("error from search", response?.data?.error);
          this.props?.notificationComponent(true, "fail");
        } else {
          this.getAllPerksData();
          this.props?.getTalentPerformerDetails(this.props?.performer_deal_id);
          this.onCancelClick();
          this.props?.notificationComponent(true, "success");
        }
      },
      (err) => {
        this.setState({ isPosting: false, showAddLoader: false, isDelete:null,isPA:false });
        this.props?.notificationComponent(true, "fail");
        console.log("add deal reprsentative: " + err);
      }
    );
  }

  render() {
    return (
      <div className="PerksCOntainer">
        {!this.props?.summary ? (
          <>
            <MDBRow className="noPaddingChildren marginBottom">
              <MDBCol md="3" className="noPadding leftBorderRadius">
                <SearchSelectField
                  id="slectMemo"
                  width="100%"
                  options={this.state?.memoOptions}
                  value={this.state?.memo_type}
                  label="Memo to Add/Edit"
                  disabled={this.state?.isNewPerk ? false : this.state?.editorMode}
                  onChange={(e, value) => this.onChange("memo_type", value)}
                />
              </MDBCol>
              <MDBCol md="2" lg="2" className="noPadding rightBorderRadius mr-1">
                <SearchSelectField
                  id="contractStartSelect"
                  disabledPlaceHolder={false}
                  //defaultMenuText="All"
                  disabled={this.state?.isNewPerk ? false : this.state?.editorMode}
                  options={this.state?.constractStartOptions}
                  value={this.state?.contract_date}
                  label="Contract Start"
                  onChange={(e, value) => value?.text !== "All" && this.state?.memo_type === "Compensation" ? this.getContractDetails(value?.obj?.performer_deal_contract_id, true, value) : this.onChange("contract_date", value) }
                />
              </MDBCol>
              <MDBCol md="1" xl="1"  className="endAlign"> 
                {this.state?.memo_type?.value==="Paid Ads" && 
                (!this.state?.contract_date ||this.state?.contract_date?.text==="All") ?
                <BasicCheckbox
                label={"Paid Ads"}
                checked={this.state.isPA}
                onChange={(e)=>this.setState({isPA:e.target.checked})}
                align="top"
                id={'performerPerksPACheckbox'}/>
                :<></>}

              </MDBCol>
              <MDBCol md="2" xl="1" className="endAlign w-15">
                {this.state?.showAddLoader ? (
                  <CircularProgress size={15} />
                ) : (
                  <BasicButton
                    icon="save"
                    text={
                     (this.state?.editorMode && this.state?.isNewPerk)|| (this.state.isPA &&!this.state?.editorMode)
                        ? "add memo"
                        : "save memo"
                    }
                    onClick={() => this.onBtnClick()}
                    disabled={this.state?.isPA ? false : !this.state?.editorMode}
                  />
                )}
              </MDBCol>

              <MDBCol md="1" xl="2" className="endAlign">
                <BasicButton
                  text="cancel"
                  variant="outlined"
                  onClick={() => this.onCancelClick()}
                  disabled={this.state?.isPA ? false : !this.state?.editorMode}
                />
              </MDBCol>

              <MDBCol md="3">
                <SearchSelectField
                  id="stdLangSelect"
                  options={this.state?.stdLanguageOptions}
                  value={this.state?.stdLanguageSelected}
                  label="Standard Language"
                  disabled={
                    this.state?.editorMode &&
                    (this.state?.memo_type?.value == "Compensation" ||
                      this.state?.memo_type?.value == "Expenses/Transportation")
                      ? false
                      : true
                  }
                  onChange={(e, value) =>
                    this.onChange("stdLanguageSelected", value)
                  }
                />
              </MDBCol>
            </MDBRow>
          </>
        ) : null}
        {this.state?.pageLoader ? (
          <CircularProgress />
        ) : (
          <>
            <MDBRow>
              {this.state?.editorMode ? (
                <RichTextEditor
                  id={"perksRichEditor"}
                  onChange={(
                    content = null,
                    delta = null,
                    source = null,
                    editor,
                    variantToAdd = null
                  ) => {
                    let selectedPerkData = { ...this.state.selectedPerkData };
                    selectedPerkData.memo_text = editor.getHTML();
                    this.setState({ selectedPerkData });
                  }}
                  value={this.state.selectedPerkData?.memo_text}
                />
              ) : null}

              {this.props?.summary && this.state?.perksList?.length == 0 ? (
                <div>No Perks Entered!</div>
              ) : (
                <>
                  {this.state?.perksList?.map((item) => (
                    <MDBCol md="12" className="marginBottom memoContainer">
                      {item?.performer_deal_perks_id ? (
                        <>
                          <MDBRow>
                            <MDBCol md={11}>
                              <h6>
                                {item?.memo_type}
                                {item?.contract_date
                                  ? ` ${getFormattedDate(item?.contract_date)}`
                                  : ""}
                              </h6>
                            </MDBCol>
                            <MDBCol md={1} className='flex'>
                                  <MDBIcon icon={'pen'} className='mr-1' onClick={() => {
                                    let stdOptions = []
                                    if (item?.memo_type == "Compensation") {
                                      stdOptions = JSON.parse(
                                        JSON.stringify(standardLanguageCompensationOptions)
                                      );
                                    } else if (item?.memo_type == "Expenses/Transportation") {
                                      stdOptions = JSON.parse(JSON.stringify(standardLanguageExpenseOptions));
                                    } else {
                                      stdOptions = [];
                                    }
                                    this.setState({
                                      memo_type : {text:item?.memo_type, value: item?.memo_type},
                                      isNewPerk:false,
                                      editorMode:true,
                                      stdLanguageOptions: stdOptions,
                                      selectedPerkData:item,
                                      contract_date:this.state.constractStartOptions?.find(ele => ele?.value?.performer_deal_contract_id === item?.performer_deal_contract_id),
                                      isPA:item.paid_ads
                                    })
                                  }}>
                                  </MDBIcon>
                                  {/* </MDBCol>
                                    <MDBCol md={1}> */}
                                  {this.state.isDelete === item?.performer_deal_perks_id ? <CircularProgress color="inherit" size={20}/> : <MDBIcon icon={'times'} onClick={() => {
                                       let postJson = {...item}
                                       postJson.is_delete = 1
                                       this.setState({selectedPerkData:postJson, isDelete: item?.performer_deal_perks_id}, this.submitPerk)

                                  }}></MDBIcon>}
                            </MDBCol>
                          </MDBRow>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: dompurify.sanitize(item?.memo_text),
                            }}
                          />
                        </>
                      ) : null}
                    </MDBCol>
                  ))}
                </>
              )}
            </MDBRow>
          </>
        )}
      </div>
    );
  }
}

import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import EditInfoAndText from '../../../components/Performers/Compensation/EditInfoAndText';
import AddInfoAndText from '../../../components/Performers/Compensation/AddInfoAndText';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        position: 'absolute',
        width: '40%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid grey',
        borderRadius: '8px',
        boxShadow: theme.shadows[5]
    },
    label: {
        fontSize: '0.75rem'
    },
    title: {
        backgroundColor: '#164f9f',
        height: '2rem',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px'
    },
    titleLabel: {
        color: 'white',
        paddingLeft: '20px',
        paddingTop: '7px'
    },
    MessageContent: {
        height: '150px',
        backgroundColor: '#e6e6e6',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        padding: '16px'
    },
    TextBoxMessageContent: {
        height: '200px',
        backgroundColor: '#e6e6e6',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        padding: '16px'
    },
    ButtonDiv: {
        backgroundColor: '#e6e6e6',
        display: 'inline-flex',
        padding: '16px 0 0 16px',
        float: 'right'
    },
    redButton: {
        backgroundColor: '#FF0000',
        color: 'white'
    },
    FloatRight: {
        paddingLeft: '8px',
        marginRight: '5px'
    },
    ModalHeader: {
        backgroundColor: '#164f9f',
        margin: '0',
        padding: '0 10px'
    },
    HeaderText: {
        color: '#ffffff',
        fontSize: '1rem',
        textAlign: 'start',
        lineHeight: '17px',
        textTransform: 'capitalize'
    },
    closeIcon: {
        padding: '0'
    }
}));

export default function ModalWithBackdrop(props) {
    const classes = useStyles();
    const handleClose = () => {
        props?.handleClose();

    }
    let hideCancel = props?.hideCancel || false;
    let hideSubmit = (props?.primaryButtonText != "" ? true : false)


    return (
        <div className="BasicModalContainerForMessage">
            <Modal class="modal" id="backdropModal" backdrop={true}
                open={props?.open ? props?.open : false}
                onClose={handleClose}
                className={classes.modal} >
                <div className={classes?.paper}>
                    <MDBRow className={classes?.ModalHeader} id={"modal_header"}>
                        <MDBCol md={11} className={classes?.HeaderText} >{props?.title}</MDBCol>
                        <MDBCol md={1} className={classes.closeIcon} onClick={handleClose} ><CancelIcon style={{ fill: "white" }} /></MDBCol>
                    </MDBRow>
                    {/* <div className={classes?.title}>
                        <label className={classes?.titleLabel}>{props?.title}</label>
                    </div> */}
                    <MDBRow className="ModalContent">
                        <MDBCol>
                            <MDBRow className="ModalBody">
                                <MDBCol md={12} className="Content"
                                // disabled={!handlePermissions()}
                                >
                                    {props?.modalName === "Edit Info and text" && <EditInfoAndText
                                        {...props}
                                    />}
                                    {props?.modalName === "Add Info and text" && <AddInfoAndText
                                        {...props}
                                    />}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="footer">

                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </div>
            </Modal>
        </div>
    );
}
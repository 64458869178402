// import { MDBContainer } from 'mdb-react-ui-kit';
import React from 'react';
import { withUserContext } from "../../../contexts/UserContext";
// import Modal from '@material-ui/core/Modal';
// import { withStyles } from '@material-ui/core/styles';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import CancelIcon from '@material-ui/icons/Cancel';
import { newProjectDetails, aliasList, companiesList, checkDropDown } from './config';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import DateField from '../SharedComponents/DateField/DateField';
import BasicTextArea from '../SharedComponents/BasicTextArea/BasicTextArea';
import SelectField from '../SharedComponents/SelectField/SelectField';
import ProdCompany from './ProdCompanyy';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { getFormattedDate } from '../../Common/Helper';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import TextField from '@material-ui/core/TextField';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from "@material-ui/core/Radio";
// import { validateCharNumOnlyWithSpace, validateCharNumWithHyphenSlash } from '../../Common/Helper';
import messages from '../../Common/Messages.json';
// import sizeLimits from '../../Common/SizeLimits.json';
// import MessageModal from '../SharedComponents/MessageModal';
// import { withUserContext } from '../../../contexts/UserContext';
import './NewProduction.scss';
import { Tooltip } from '@material-ui/core';
import RichTextEditor from '../SharedComponents/RichTextEditor/RichTextEditor';

class NewProduction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // newProductionList: [],
            // featureType: [],
            // distribution: [],
            // intendedMarket: [],
            // postInitiated: false,
            // visibility: [
            //     { value: 'Active', label: 'Active' },
            //     { value: 'Inactive', label: 'Inactive' }
            // ],
            akaIndex: 2,
            productionIndex: 2,
            // crewNames: [],
            isProductionDetails: false,
            // isFetchingFeatureTypes: false,
            // isFetchingDistributionTypes: false,
            // isFetchingIntendedMarket: false,
            // formEdited: false,
            isSubmit: false,
            akaRadio: 0,
            title_alias: {},
            projectStatusOptions: []
        }

    }

    componentDidMount = () => {
        if (this.props.mode === "New") {
            this.setState({
                newProductionList: this.formatNewProductionDetailsResponse(
                    JSON.parse(JSON.stringify({ ...newProjectDetails }))
                )
            });
        } else {
            this.getProjectDetails();
        }
        this.getLookupValues('PROJECT_STUDIO');
        this.getLookupValues('PROJECT_DISTRIBUTOR');
        this.getLookupValues('CONTRACT_TYPE');
        this.getLookupValues('PROJECT_TYPE');
        this.getLookupValues("PROJECT_STATUS");
        this.getUserDetails('ASSIGNED_TO', '');
    }

    getLookupValues = (type) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {

                let formattedList = response.data?.map(item => ({ value: item?.lookup_value, label: item?.lookup_value, id: item?.lookup_id }));
                if (type == "PROJECT_STUDIO") {
                    this.setState({ studioOptions: formattedList });
                } else if (type == "PROJECT_DISTRIBUTOR") {
                    this.setState({ distributorOptions: formattedList });
                } else if (type == "CONTRACT_TYPE") {
                    this.setState({ contractTypeOptions: formattedList });
                } else if (type == "PROJECT_TYPE") {
                    this.setState({ projectTypeOptions: formattedList });
                } else if (type == "PROJECT_STATUS") {
                    this.setState({ projectStatusOptions: formattedList }, () => {
                        if (this.props.mode === "New") {
                            let defaultStatus = formattedList.filter(item => {
                                return item.label === "Active"
                            })
                            this.setState((prevState) => ({
                                newProductionList: {
                                    ...prevState?.newProductionList,
                                    ["status"]: defaultStatus[0]?.value
                                },
                                countryList: formattedList
                            }))
                        }
                    });
                }
            },
                (err) => {
                    console.log("Error in fetching lookup:", err)
                })
    }

    getUserDetails = (type, searchString) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${type}&searchString=${searchString}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item?.user_id, label: item?.full_name }));
                this.setState({ assigned_to_options: formattedList })
            },
                (err) => {
                    console.log("Error in fetching lookup:", err)
                })
    }

    formatNewProductionDetailsResponse = (response) => {
        if (response?.aliases?.length === 0) {
            response?.aliases.push(JSON.parse(JSON.stringify({ ...aliasList })));
        }
        if (response?.production_companies?.length === 0) {
            let prodComp = JSON.parse(JSON.stringify({ ...companiesList }))
            prodComp.is_primary = 1
            response?.production_companies.push(JSON.parse(JSON.stringify({ ...prodComp })));
        }
        return response;
    }

    getProjectDetails = (origin = null) => {
        if (origin === "fromEditProdCompany") {
            this.setState({ isProductionDetails: false });
        } else {
            this.setState({ isProductionDetails: true });
        }

        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureProject?project_id=${this.props?.id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                // response.data.map((item) => {
                //     let crew_selected = { 'value': item.crew_id, 'label': item.assigned_to }
                //     item['crew_selected'] = crew_selected;
                //     item.production_companies_list.map((details) => {
                //         let production_data = { 'production_id': details.production_company_id, 'name': details.production_company_name }
                //         details['production_data'] = production_data;
                //     })
                // })
                let newResponse = response.data.map(obj => {
                    obj.start_date = obj?.start_date ? getFormattedDate(obj?.start_date, true) : null;
                    obj.wrap_date = obj.wrap_date ? getFormattedDate(obj?.wrap_date, true) : null;
                    obj.release_date = obj.release_date ? getFormattedDate(obj?.release_date, true) : null;
                    obj.studio_name = obj.studio_name ? obj.studio_name : null, //"Studio 1",
                        obj.distributor_name = obj.distributor_name ? obj.distributor_name : null, // "DISTRIBUTOR 1",
                        obj.contract_type = obj.contract_type ? obj.contract_type : null, //"Contract Type 1",
                        obj.project_type = obj.project_type ? obj.project_type : null, //"Project type 1",
                        obj.status = obj.status ? obj.status : null // "check1"
                    return obj;
                });
                if (newResponse[0].production_companies?.length > 0) {
                    let formattedObj = newResponse[0]?.production_companies?.map(i => {
                        return {
                            project_production_company_id: i?.project_production_company_id,
                            production_company: { value: i?.production_company, text: i?.production_company },
                            production_company_id: i?.production_company_id,
                            union: i?.union,
                            signator: i?.signator,
                            notes: i?.notes,
                            is_delete: i?.is_delete,
                            signator_email: i?.signator_email,
                            address: i?.address,
                            city: i?.city,
                            state: (i?.country === 'United States' || i?.country === 'USA' || i?.country === 'US') ? { value: i?.state, text: i?.state } : i?.state,
                            country: { value: i?.country, text: i?.country },
                            zip: i?.zip,
                            is_primary: i?.is_primary
                        }
                    })
                    if (!formattedObj?.some(item => item?.is_primary === 1)) {
                        formattedObj[0].is_primary = 1
                    }
                    newResponse[0].production_companies = formattedObj;
                }
                // if (newResponse[0].aliases?.length > 0) {
                //     // let actualAliases = newResponse[0]?.aliases?.filter(item => item.is_aka === 1)
                //     let titleAlias = newResponse[0]?.aliases?.filter(item => item.is_aka === 0)
                //     if (titleAlias[0]?.is_primary === 1) {
                //         newResponse[0].akaRadio = 1
                //         // if(titleAlias[0]?.project_alias_id !== null)
                //         // {
                //         //     this.setState({ title_alias: titleAlias})
                //         // }
                //     }
                //     // newResponse[0].aliases = actualAliases;
                // }
                if (newResponse[0].aliases?.length > 0) {
                    let actualAliases = newResponse[0]?.aliases?.filter(item => item.is_aka === 1)
                    let titleAlias = newResponse[0]?.aliases?.filter(item => item.is_aka === 0)
                    if (titleAlias[0]) {
                        if (titleAlias[0]?.is_primary === 1) {
                            newResponse[0].akaRadio = 1
                        }
                        if (titleAlias[0]?.project_alias_id !== null) {
                            this.setState({ title_alias: titleAlias })
                        }
                    }
                    newResponse[0].aliases = actualAliases;
                }
                this.setState({
                    newProductionList: this.formatNewProductionDetailsResponse(newResponse[0]),
                    isProductionDetails: false
                }, () => {
                    console.log("res", this.state.newProductionList)
                });

            },
                (err) => {
                    console.log("Error in fetching Production Details:", err)
                })
    }
    handleOnChange = (value) => {
        this.handleGeneralEdit("notes", value);
    }
    handleGeneralEdit = (field, value) => {
        this.setState(prevState => ({
            newProductionList: {
                ...prevState.newProductionList,
                [field]: value
            },
            // postInitiated: false,
            // formEdited: true

        }), () => {
            let newProductionList = this.state.newProductionList;
            if (field === 'akaRadio') {
                newProductionList['aliases']?.map((item, aIndex) => {
                    item.is_primary = 0
                })
            }
            this.setState({ newProductionList: newProductionList })
        })
    }

    handleListEdit = (list, field, value, index) => {
        let newProductionList = { ...this.state.newProductionList };
        let akaRadio = this.state.akaRadio
        switch (list) {
            case 'aliases':
                let akalist = newProductionList[list][index];
                if (field === "swap") {
                    let projectName = newProductionList.project_name
                    newProductionList[list]?.map((item, aIndex) => {
                        if (aIndex != index) {
                            // newProductionList[list][aIndex].is_primary = 0,
                            newProductionList['akaRadio'] = 0
                            newProductionList[list][aIndex].is_aka = 1
                        }
                        else {
                            // newProductionList[list][aIndex].is_primary = value === 1 ? 1 : 0
                            newProductionList['akaRadio'] = 0
                            newProductionList[list][aIndex].is_aka = 1
                            newProductionList.project_name = newProductionList[list][aIndex].alias_name
                            newProductionList[list][aIndex].alias_name = projectName
                        }
                    })
                    // let aliasLength = newProductionList[list].length
                    // newProductionList[list].splice(aliasLength, 0, JSON.parse(JSON.stringify({ ...aliasList })));
                    // newProductionList[list][aliasLength]['alias_name'] = projectName
                    // newProductionList[list][aliasLength]['is_aka'] = 1
                    // newProductionList[list][aliasLength]['is_delete'] = 0
                    // newProductionList[list][aliasLength]['is_primary'] = 0
                    // newProductionList[list][aliasLength]['project_alias_id'] = null
                    // newProductionList[list][aliasLength]['index'] = aliasLength
                } else {
                    let aka_item = {
                        ...akalist,
                        [field]: value
                    };
                    newProductionList[list][index] = aka_item;
                }
                break;
            case 'production_companies':
                let prodList = JSON.parse(JSON.stringify({ ...newProductionList[list][index] }));
                if (field === "is_primary") {
                    newProductionList[list]?.map((item, aIndex) => {
                        if (aIndex != index) {
                            newProductionList[list][aIndex].is_primary = 0,
                                newProductionList['akaRadio'] = 0
                        }
                        else {
                            newProductionList[list][aIndex].is_primary = value === 1 ? 1 : 0
                            newProductionList['akaRadio'] = 0
                        }
                    })
                } else {
                    prodList[field] = value;
                    newProductionList[list][index] = prodList;
                    this.setState((prevState) => ({
                        newProductionList: {
                            ...prevState?.newProductionList,
                            ["production_companies"]: JSON.parse(JSON.stringify({ ...newProductionList }))
                        }
                    }))

                }
                break;
        }

        this.setState({ newProductionList: newProductionList, postInitiated: false, formEdited: true, akaRadio });
        // this.props.fieldChanged(this.state.formEdited, "change");
    }

    addAdditionalField = (listName, index) => {
        this.setState({ formEdited: true })
        // this.props.fieldChanged(this.state.formEdited, "change");
        let newProductionList = JSON.parse(JSON.stringify({ ...this.state.newProductionList }));
        switch (listName) {
            case 'aliases':
                if (newProductionList.aliases[index].alias_name) {
                    let akaArray = JSON.parse(JSON.stringify({ ...aliasList }));
                    let aka_list = [...newProductionList.aliases];
                    akaArray.index = this.state.akaIndex;
                    aka_list.push(akaArray);
                    newProductionList.aliases = aka_list;
                    this.setState({ akaIndex: this.state.akaIndex + 1, newProductionList: newProductionList, postInitiated: false });
                }
                break;
            case 'production_companies':
                if (newProductionList.production_companies[index].production_company) {
                    let initialProductionArray = JSON.parse(JSON.stringify({ ...companiesList }));
                    let production_list = [...newProductionList.production_companies];
                    initialProductionArray.index = this.state.productionIndex;
                    production_list.push(initialProductionArray);
                    newProductionList.production_companies = production_list;
                    this.setState((prevState) => ({
                        newProductionList: {
                            ...prevState?.newProductionList,
                            ["production_companies"]: production_list
                        },
                        productionIndex: this.state.productionIndex + 1,
                        postInitiated: false
                    }))
                }
                this.setState({ showFields: true });
                break;
        }
    }
    removeField = (listName, callbackItem) => {
        this.setState({
            formEdited: true,
            listName: listName, callbackItem: callbackItem, postInitiated: false
        })
        // this.props.fieldChanged(this.state.formEdited, "change");
        switch (listName) {
            case 'aliases':
                if (callbackItem.project_alias_id) {
                    this.showMessage(listName, callbackItem);
                } else {
                    this.deleteRow(listName, callbackItem);
                }
                break;

            case 'production_companies':
                if (callbackItem.project_production_company_id) {
                    this.deleteRow(listName, callbackItem);
                } else {
                    this.deleteRow(listName, callbackItem);
                }
                break;
        }
    }

    // showMessage = () => {
    //     this.setState({ isOpen: true });
    // }

    deleteRow = (listName, callbackItem) => {
        let newProductionList = this.state.newProductionList;
        switch (listName) {
            case 'aliases':
                let aka_list = newProductionList[listName].filter(item =>
                    (item.project_alias_id !== callbackItem.project_alias_id) || (item.index !== callbackItem.index)
                );
                if (aka_list.length === 0) {
                    newProductionList[listName] = [{ ...aliasList }];
                } else {
                    newProductionList[listName] = aka_list;
                }
                this.setState({
                    newProductionList: newProductionList
                })
                break;
            case 'production_companies':
                let tempList = JSON.parse(JSON.stringify([...this.state.newProductionList[listName]]));
                let list = tempList.filter(item =>
                    (item.project_production_company_id !== callbackItem.project_production_company_id) || (item.index !== callbackItem.index)
                );
                let updatedProdCompanies;
                if (list.length === 0) {
                    updatedProdCompanies = [{ ...companiesList }];
                } else {
                    updatedProdCompanies = list;
                }
                this.setState((prevState) => ({
                    newProductionList: {
                        ...prevState?.newProductionList,
                        ["production_companies"]: updatedProdCompanies
                    }
                }))
                break;
        }
        this.setState({
            listName: null, callbackItem: null, postInitiated: false, isOpen: false
        });
    }

    // setProductionDetailsUniqueEntries = (postJson) => {
    //     let checkPostJson = {...postJson}
    //     checkPostJson.aka = postJson.aka.filter((tag, index, array) =>
    //         (array.findIndex(t => t.aka == tag.aka) == index) && tag.aka);


    //         checkPostJson.production_companies_list = postJson.production_companies_list.filter((tag, index, array) =>
    //         (array.findIndex(t => t.production_company_id == tag.production_company_id
    //             && t.union_id == tag.union_id && t.production_company_signators_id == tag.production_company_signators_id) == index) &&
    //         tag.union_id && tag.production_company_id && tag.production_company_signators_id);
    //     return checkPostJson
    // }



    handleSubmit = (postData) => {
        let postJson = JSON.parse(JSON.stringify({ ...postData }))
        this.setState({ postInitiated: true }, () => {
            postJson.aliases = postJson?.aliases?.filter(item => item.alias_name !== "");
            postJson.production_companies = postJson?.production_companies?.filter(item => item.production_company !== null)
            postJson.production_companies = postJson?.production_companies?.map(item => {
                return {
                    project_production_company_id: item?.type === "new" ? null : item?.project_production_company_id,
                    production_company_id: item?.production_company?.id ? item?.production_company?.id : item?.production_company_id,
                    production_company: item?.production_company?.value,
                    union: item.union,
                    signator: item.signator,
                    notes: item.notes,
                    is_delete: item.is_delete,
                    signator_email: item.signator_email,
                    address: item.address,
                    city: item.city,
                    state: typeof (item.state) === 'object' ? item?.state?.value : item?.state,
                    country: typeof (item.country) === 'object' ? item?.country?.value : item?.country,
                    zip: item.zip,
                    is_primary: item.is_primary
                }
            })
            if (this.props?.mode === "Edit" && this.state.title_alias[0]) {
                let modified = this.state.title_alias[0]
                modified['is_primary'] = postJson?.akaRadio === 1 ? 1 : 0
                postJson?.aliases?.push(modified)
            } else {
                let newAka = {
                    "project_alias_id": null,
                    "alias_name": postJson?.project_name,
                    "is_primary": postJson?.akaRadio === 1 ? 1 : 0,
                    "is_delete": 0,
                    "index": 1,
                    "is_aka": 0
                }
                postJson.aliases.push(newAka)
            }


            // let titleCheck = postJson?.aliases.find(item => item.is_aka === 0)
            // if (titleCheck) {
            //     postJson.aliases = postJson?.aliases?.map(item => {
            //         if (item.is_aka === 0 && item.project_alias_id) {
            //             item.is_primary = postJson?.akaRadio === 1 ? 1 : 0
            //         }
            //         return item;
            //     })
            // } else {
            //     let newAka = {
            //         "project_alias_id": null,
            //         "alias_name": postJson?.project_name,
            //         "is_primary": postJson?.akaRadio === 1 ? 1 : 0,
            //         "is_delete": 0,
            //         "index": 1,
            //         "is_aka": 0
            //     }
            //     postJson.aliases.push(newAka)
            // }
            // let uniquePostJson = this.setProductionDetailsUniqueEntries(postJson);
            if (this.validateFields(postJson)) {
                this.postProjectDetails((postJson));
            } else {
                console.log('validation failed')
            }
        })
    }

    postProjectDetails = (postJson) => {
        if (postJson?.box_url === "") {
            postJson.box_url = null
        }
        this.setState({ isSubmit: true })
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureProject', postJson, this.props.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                if (response.data.error) {
                    // this.props.fieldChanged(true, "fail");
                    // this.props?.handleSubmit('fail');
                    this.setState({
                        postInitiated: false, formEdited: false, isSubmit: false
                    });
                    this.props?.notificationComponent(true, "fail");
                } else {
                    this.setState({
                        // newProductionList: this.formatNewProductionDetailsResponse(newProjectDetails),
                        postInitiated: false, formEdited: false, isSubmit: false
                    }, () => {
                        if (this.props?.mode == 'New' && !this.props?.fromAdvancesearch) {
                            this.props?.history?.push(`/caster-feature/project/${response.data[0]?.project_id}`)
                        }
                        if (this.props?.mode == 'New' && this.props?.fromAdvancesearch) {
                            //this.props?.toggleRefreshPage('ProjectDetails');
                            this.props?.notificationComponent(true, "success");
                        }
                        //this.props?.toggleRefreshPage('ProjectDetails');
                        //this.props?.notificationComponent(true, "success");
                        // this.props?.onClose()
                    });
                    if (this.props?.mode == 'Edit') {
                        this.props?.toggleRefreshPage('ProjectDetails');
                        this.props?.notificationComponent(true, "success");
                    }
                    // this.props.fieldChanged(true, "success");
                    // this.props?.handleSubmit('success');
                }
            },
                (err) => {
                    // this.setState({ newProductionList: this.formatNewProductionDetailsResponse(newProjectDetails), postInitiated: false, });
                    this.props?.notificationComponent(true, "fail");
                    console.log("Post representative details error: " + err);
                    // this.props?.handleSubmit('fail');
                    // this.props.fieldChanged(true, "fail");
                });
    }

    validateFields = (postJson) => {
        let submit = (
            (postJson.project_name && postJson.studio_name))
        // (postJson.production_companies.length !== 0
        //     ? postJson.production_companies?.every((item) =>
        //         item.address && item.country ? true : false
        //     )
        //     : true)
        //         && postJson.sag_id &&
        //         postJson.name && postJson.distribution_id && postJson.start_date && postJson.wrap_date) &&
        //     (postJson.gl_code.length <= sizeLimits.nameCharacterLimit) &&
        //     (postJson.sag_id.length <= sizeLimits.nameCharacterLimit) &&
        //     (postJson.name.length <= sizeLimits.addressCharacterLimit) &&
        //     (postJson.aka.length === 0 ? true : postJson.aka.every(aka =>
        //         (aka.aka.length <= sizeLimits.nameCharacterLimit))) &&
        //     (new Date(postJson.start_date) < new Date(postJson.wrap_date))
        // );
        return submit;
    }

    render() {
        let aliases = this.state.newProductionList?.aliases?.filter(item => item.is_aka === 1);
        // const { classes } = this.props;
        // let isLoading = this.state.isProductionDetails || this.state.isFetchingFeatureTypes || this.state.isFetchingDistributionTypes
        //     || this.state.isFetchingIntendedMarket
        return (
            // <div>
            //     <span>new production</span>
            // </div>
            <div >
                {this.state.isProductionDetails ?
                    <CircularProgress /> :
                    <MDBContainer className="newProjectContainer">
                        <MDBRow>
                            <MDBCol md={3} className="title">
                                <MDBCol md={12}>
                                    <BasicTextField
                                        label="Project Title"
                                        isMandatory={true}
                                        showMandatory={this.state.postInitiated}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        value={this.state.newProductionList?.project_name || ""}
                                        onChange={(e) => {
                                            this.handleGeneralEdit('project_name', e.target.value)
                                        }}
                                    />
                                </MDBCol>
                            </MDBCol>
                            <MDBCol md={1}></MDBCol>
                            <MDBCol md={3} className={"studioEdit"}>
                                <SelectField
                                    //                                         // className={"gender"}
                                    value={this.state.newProductionList?.studio_name || ""}
                                    label={"Studio"}
                                    options={this.state.studioOptions}
                                    isMandatory={true}
                                    showMandatory={this.state.postInitiated}
                                    mandatoryWarning={messages.mandatoryWarning}
                                    onChange={(e) => {
                                        this.handleGeneralEdit('studio_name', e.target.value)
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="akaDistributorRow">
                            <MDBCol md={4}>
                                {
                                    this.state.newProductionList?.aliases?.map((aka, index) => (
                                        <MDBRow>
                                            <MDBCol md={1} className={index !== 0 ? "radioRow" : "firstRadioRow"}>
                                                <Tooltip title="Click to select Contract Title">
                                                    <SwapVertIcon
                                                        onClick={(e) => this.handleListEdit('aliases', 'swap', true, index)}
                                                    />
                                                </Tooltip>
                                            </MDBCol>
                                            <MDBCol md={8} className={"AKASEdit"}>
                                                <BasicTextField
                                                    label={index === 0 ? "AKA(s)" : null}
                                                    value={aka.alias_name || ""}
                                                    onChange={(e) => this.handleListEdit('aliases', 'alias_name', e.target.value.length > 0 ? e.target.value : null, index)}
                                                />
                                            </MDBCol>
                                            <MDBCol md={2} className={"actionButtonsRowEdit"}>
                                                <MDBRow className={index === 0 ? "actionButons" : ""}>
                                                    <MDBCol md={1} className="actions alignField">
                                                        {
                                                            (this.state.newProductionList?.aliases?.length !== 1 && !aka.project_alias_id) ?
                                                                <RemoveCircleIcon fontSize="small"
                                                                    onClick={() =>
                                                                        this.removeField('aliases', aka)}
                                                                /> : null
                                                        }
                                                    </MDBCol>
                                                    <MDBCol md={1} className="actions alignField">
                                                        {
                                                            (this.state.newProductionList?.aliases?.length - 1 === index && aka.alias_name?.length > 0) ?
                                                                <AddCircleIcon fontSize="small"
                                                                    onClick={() => this.addAdditionalField('aliases', index)}
                                                                /> : null
                                                        }
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                    ))}
                                {/* <BasicTextField
                                    label={"Aliases"}
                                // value={aka.aka || ""}
                                // showAsLabel={aka.production_aka_id ? true : false}
                                // limit={sizeLimits.nameCharacterLimit}
                                // limitWarning={messages.exceed50CharacterWarning || ""}
                                // onChange={(e) => this.handleListEdit('aka', e.target.value.length > 0 ? e.target.value : null, index)} 
                                /> */}
                            </MDBCol>
                            <MDBCol md={3} className={"distributor"}>
                                <SelectField
                                    //                                         // className={"gender"}
                                    value={this.state.newProductionList?.distributor_name || ""}
                                    label={"Distributor"}
                                    options={this.state.distributorOptions}
                                    // isMandatory={true}
                                    //                                         showMandatory={this.state.postInitiated}
                                    //                                         mandatoryWarning={messages.mandatoryWarning}
                                    onChange={(e) => {
                                        this.handleGeneralEdit('distributor_name', e.target.value)
                                    }}
                                />
                                <SelectField
                                    //                                         // className={"gender"}
                                    value={this.state.newProductionList?.status || ""}
                                    label={"Status"}
                                    options={this.state.projectStatusOptions}
                                    // isMandatory={true}
                                    //                                         showMandatory={this.state.postInitiated}
                                    //                                         mandatoryWarning={messages.mandatoryWarning}
                                    onChange={(e) => {
                                        this.handleGeneralEdit('status', e.target.value)
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className={"marginTop"}>
                            <MDBCol md={3}>
                                <DateField
                                    label={"Start Date"}
                                    // className="date"
                                    // isMandatory={true}
                                    // showMandatory={this.state.postInitiated}
                                    // mandatoryWarning={messages.mandatoryWarning}
                                    value={this.state.newProductionList?.start_date || ""}
                                    onChange={(e) => this.handleGeneralEdit('start_date', e.target.value ? e.target.value : null)}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <DateField
                                    label={"Wrap Date"}
                                    // className="date"
                                    // isMandatory={true}
                                    // showMandatory={this.state.postInitiated}
                                    // mandatoryWarning={messages.mandatoryWarning}
                                    value={this.state.newProductionList?.wrap_date || ""}
                                    onChange={(e) => this.handleGeneralEdit('wrap_date', e.target.value ? e.target.value : null)}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <DateField
                                    label={"Release Date"}
                                    // className="date"
                                    // isMandatory={true}
                                    // showMandatory={this.state.postInitiated}
                                    // mandatoryWarning={messages.mandatoryWarning}
                                    value={this.state.newProductionList?.release_date || ""}
                                    onChange={(e) => this.handleGeneralEdit('release_date', e.target.value ? e.target.value : null)}
                                />
                            </MDBCol>
                            <MDBCol md={3} className={"assignedToEdit"}>
                                <SelectField
                                    //                                         // className={"gender"}
                                    value={this.state.newProductionList?.assigned_to || ""}
                                    label={"Assigned To"}
                                    options={this.state.assigned_to_options}
                                    // isMandatory={true}
                                    //                                         showMandatory={this.state.postInitiated}
                                    //                                         mandatoryWarning={messages.mandatoryWarning}
                                    onChange={(e) => {
                                        this.handleGeneralEdit('assigned_to', e.target.value)
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className={"marginTop"}>
                            <MDBCol md={3}>
                                <BasicTextField
                                    label={"SAP/GL #PROD"}
                                    value={this.state.newProductionList?.gl_no_prod || ""}
                                    // showAsLabel={aka.production_aka_id ? true : false}
                                    // limit={sizeLimits.nameCharacterLimit}
                                    // limitWarning={messages.exceed50CharacterWarning || ""}
                                    onChange={(e) => {
                                        this.handleGeneralEdit('gl_no_prod', e.target.value)
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicTextField
                                    label={"SAP/GL #DEV"}
                                    value={this.state.newProductionList?.gl_no_dev || ""}
                                    // showAsLabel={aka.production_aka_id ? true : false}
                                    // limit={sizeLimits.nameCharacterLimit}
                                    // limitWarning={messages.exceed50CharacterWarning || ""}
                                    onChange={(e) => {
                                        this.handleGeneralEdit('gl_no_dev', e.target.value)
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3} className={"projectTypeEdit"}>
                                <SelectField
                                    //                                         // className={"gender"}
                                    value={this.state.newProductionList?.project_type || ""}
                                    label={"Project Type"}
                                    options={this.state.projectTypeOptions}
                                    // isMandatory={true}
                                    //                                         showMandatory={this.state.postInitiated}
                                    //                                         mandatoryWarning={messages.mandatoryWarning}
                                    onChange={(e) => {
                                        this.handleGeneralEdit('project_type', e.target.value)
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className={"marginTop"}>
                            <MDBCol md={3}>
                                <BasicTextField
                                    label={"SAG ID"}
                                    value={this.state.newProductionList?.sag_id || ""}
                                    // showAsLabel={aka.production_aka_id ? true : false}
                                    // limit={sizeLimits.nameCharacterLimit}
                                    // limitWarning={messages.exceed50CharacterWarning || ""}
                                    onChange={(e) => {
                                        this.handleGeneralEdit('sag_id', e.target.value)
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3} className={"contractTypeEdit"}>
                                <SelectField
                                    //                                         // className={"gender"}
                                    value={this.state.newProductionList?.contract_type || ""}
                                    label={"Contract Type"}
                                    options={this.state.contractTypeOptions}
                                    // isMandatory={true}
                                    //                                         showMandatory={this.state.postInitiated}
                                    //                                         mandatoryWarning={messages.mandatoryWarning}
                                    onChange={(e) => {
                                        this.handleGeneralEdit('contract_type', e.target.value)
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className={"marginTop"}>
                            <MDBCol>
                                <BasicTextField
                                    label={"BOX URL"}
                                    value={this.state.newProductionList?.url || ""}
                                    // showAsLabel={aka.production_aka_id ? true : false}
                                    // limit={sizeLimits.nameCharacterLimit}
                                    // limitWarning={messages.exceed50CharacterWarning || ""}
                                    onChange={(e) => {
                                        this.handleGeneralEdit('url', e.target.value)
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="prodCompRow">
                            <BasicLabel text={"Production Companies"} />
                            {/* <span className="companyFont">Production Companies</span> */}
                            <MDBRow>
                                {
                                    this.state.newProductionList?.production_companies?.map((production_companies, index) => (
                                        <ProdCompany
                                            production_companies={production_companies}
                                            index={index}
                                            addCallback={this.addAdditionalField.bind(this)}
                                            removeCallback={this.removeField.bind(this)}
                                            handleListEdit={this.handleListEdit.bind(this)}
                                            showAsLabel={production_companies?.project_production_company_id ? true : false}
                                            showRemove={(this.state.newProductionList?.production_companies?.length !== 1
                                                // && !production_companies?.project_production_company_id
                                            ) ? true : false}
                                            showAdd={this.state.newProductionList?.production_companies.length - 1 === index
                                                && production_companies?.production_company ? true : false}
                                            mode={this.props?.mode}
                                            postInitiated={this.state.postInitiated}
                                            getProjectDetails={this.getProjectDetails.bind(this)}
                                            project_id={this.props?.id}
                                        />
                                    ))}
                            </MDBRow>
                        </MDBRow>
                        <MDBRow>
                            <BasicLabel text={"Notes"} />
                            <RichTextEditor
                                id={`NotesNewProject`}
                                value={this.state.newProductionList?.notes || ""}
                                onChange={(value) => this.handleOnChange(value)}
                            // onChange={(e) => {
                            //     this.handleGeneralEdit('notes', e.target.value)
                            // }}
                            //onChange={(value) => this.handleOnChange(value)}
                            >
                            </RichTextEditor>
                            {/* <BasicTextArea
                                label={"Notes"}
                                value={this.state.newProductionList?.notes || ""}
                                // showAsLabel={aka.production_aka_id ? true : false}
                                // limit={sizeLimits.nameCharacterLimit}
                                // limitWarning={messages.exceed50CharacterWarning || ""}
                                onChange={(e) => {
                                    this.handleGeneralEdit('notes', e.target.value)
                                }}
                            /> */}
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={10}>
                            </MDBCol>
                            <MDBCol md={1} className="submit">
                                <BasicButton
                                    className="btnwidth"
                                    variant="contained"
                                    type="inline"
                                    text={this.state.isSubmit ? <CircularProgress color="inherit" size={20} /> : this.props?.primaryButtonText}
                                    // text={this.props?.primaryButtonText}
                                    icon={"save"}
                                    onClick={() => this.handleSubmit(this.state.newProductionList)}
                                // disabled={false}
                                />
                            </MDBCol>
                            <MDBCol md={1}>
                                <BasicButton
                                    type="inline"
                                    variant="outlined"
                                    text={this.props?.secondaryButtonText}
                                    onClick={() => this.props.onClose()}
                                    icon="times"
                                // disabled={false}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                }
            </div >
        );
    }
}

export default withUserContext(NewProduction);
export const talentNavList = [
    {
        label: "Personal",
        index: 0
    },
    {
        label: "Representation",
        index: 1
    },
    {
        label: "Acting History",
        index: 2
    },
    {
        label: "Audition History",
        index: 3
    },
    {
        label: "Files",
        index: 4
    }
]

export const talentFCNavList = [
    {
        label: "Personal",
        index: 0
    },
    {
        label: "Representation",
        index: 1
    },
    {
        label: "Acting History",
        index: 2
    },
    {
        label: "Files",
        index: 3
    }
]

export const agentNavList = [
    {
        label: "Details",
        index: 0
    },
    {
        label: "Agent List",
        index: 1
    },
    {
        label: "Covered Talent",
        index: 2
    }
]

export const productionCompanyList = [
    {
        label: "Details",
        index: 0
    },
    {
        label: "Features",
        index: 1
    }
]


export const crewList = [
    {
        label: "Details",
        index: 0
    },
    {
        label: "Production",
        index: 1
    }
]

export const representativeNavList = [
    {
        label: "Details",
        index: 0
    },
    {
        label: "Represented Talent",
        index: 1
    }
]

export const agencyNavList = [
    {
        label: "Details",
        index: 0
    },
    {
        label: "Representatives",
        index: 1
    },
    {
        label: "Talent",
        index: 2
    }
]

export const talentAnimationList = [
    {
        label: "Personal",
        index: 0
    },
    {
        label: "Contacts",
        index: 1
    },
    {
        label: "Representation",
        index: 2
    },
    {
        label: "Acting History",
        index: 3
    },
]

export const representativeAnimationList = [
    {
        label: "Personal",
        index: 0
    },
    {
        label: "Talent",
        index: 1
    },
]

export const companyAnimationList = [
    {
        label: "Details",
        index: 0
    },
    {
        label: "Representatives",
        index: 1
    },
    {
        label: "Talent",
        index: 2
    },
]

export const reportAnimationList = [
    {
        label: "Worksheet Report",
        index: 0
    },
    {
        label: "Station #12 Report",
        index: 1
    },
    {
        label: "SAG Time Report",
        index: 2
    },
    {
        label: "Casting Data Report",
        index: 3
    },
    {
        label: "Residuals package",
        index: 4
    },
    {
        label: "Talent Voucher",
        index: 5
    },
    {
        label: "Contract",
        index: 6
    },
    {
        label: "EP Time Sheet",
        index: 7
    },
    {
        label: "EP Cover Sheet",
        index: 8
    }
]
import React from "react";
import BasicLabel from "../BasicLabel/BasicLabel";
import TextField from '@material-ui/core/TextField';
import "./TimeField.scss";

export default class TimeField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mandatoryWarning: "Please complete this mandatory field",
      timeError: "Format must be in HH:MM",
    };
  }
  render() {
    let error =
      (this.props.showMandatory && !this.props?.value) ||
      this.props.showErrorBorder ||
      false;
    let showAsLabel = this.props.showAsLabel || false;

    return (
      <>
      <div
        className={`TimeFieldContainer ${error ? "error" : ""}`}
        style={{ width: this.props?.width ? this.props?.width : "" }}
      >
        {this.props?.label && (
          <BasicLabel
            text={this.props?.label || ""}
            type="text"
            isMandatory={this.props?.isMandatory || false}
          />
        )}
        {showAsLabel ? (
          <div className="showAsLabel">{this.props.value || ""} </div>
        ) : (
          <>
            <TextField
              type="time"
              placeholder="HH:MM"
              id={this.props?.id}
              variant="outlined"
              disabled={this.props?.disabled ? this.props?.disabled : false}
              value={this.props?.value || ""}
              onChange={this.props?.onChange}
              style={{ marginRight: "10px" }}
            />
            {error && (
              <div className="errorText">
                {this.props.showMandatory && !this.props?.value
                  ? this.state.mandatoryWarning
                  : this.props.showErrorBorder
                  ? this.props?.invalidTimeError
                    ? this.props?.invalidTimeError
                    : this.state.timeError
                  : null}
              </div>
            )}
          </>
        )}
      </div>
      </>
    );
  }
}
import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import moment from "moment";
import Popover from '@material-ui/core/Popover';
import { CircularProgress } from '@material-ui/core';
import TableComponent from '../../components/SharedComponents/Table';
import MainViewModalComponent from "../../Common/MainViewModalLayout/Modal/Modal";
import ModalComponent from "../../Common/DefaultModalLayout/Modal/Modal";
import MessageModal from '../../components/SharedComponents/MessageModal';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent';
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';
import { downloadUrl, extractFilenameFromUrl, getFormattedDate } from "../../Common/Helper";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import BasicLabel from "../../components/SharedComponents/BasicLabel/BasicLabel";
import TabsComponent from '../../components/SharedComponents/Tabs/Tabs';
import { talentConfig, talentTabList, talentContractTabList, talentFilterConfig } from './ShowSeasonConfig';
import ContractDetails from './ContractDetails';
import WorkActivityDetails from './WorkActivityDetails';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { withUserContext } from "../../../contexts/UserContext";
import { talentAnimationList } from "../../../constants/NavList";
import './ShowSeason.scss';

class AllTalent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            config: talentConfig,
            selectOptions: [],
            contractList: [],
            talentTabValue: 1,
            contractTabValue: 1,
            talentFilters: JSON.parse(JSON.stringify({ ...talentFilterConfig })),
            isSortByAsc: true,
            sortCount: 0,
            sortBy: "Talent Name",
            contractAnchorEl: null,
            isPosting: false,
            is_delete: 0,
            severity: null,
            showNotification: false,
            notificationMessage: '',
            saveFlag: false,
            is_delete_flag: false,
            is_circular: false,
            isCancellingContract:false,
        }
    }

    componentDidMount() {
        this.getFilterOptions();
        if (this.props?.episodeDetails?.length > 0) {
            this.getAllPerformers();
        }
        if (this.props?.isFromEpisode) {
            this.setState({
                selectedTalent: this.props?.selectedEpisodeTalent,
                showTalentDetails: true
            }, () => {
                this.props?.handleHideTabList();
                this.getTalentContracts();
            })
        }
        if (this.props.talentRef) {
            this.props.talentRef.current = {
                getAllPerformers: this.getAllPerformers.bind(this),
                navigationCallback: this.navigationCallback.bind(this)
            }
        }
    }

    getFilterOptions = () => {
        talentConfig?.headings?.map(data => {
            if (data.inputType === "dropdown") {
                CasterService.getData(Constants.CasterServiceBaseUrl +
                    `/animationGetLookupData?entityType=${data.entityType}`,
                    this.props.userContext.active_tenant.tenant_id)
                    .then(response => {
                        let formattedList = response.data?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value, is_active: item.is_active }));

                        this.setState({ [data.selectOptions]: formattedList });
                    },
                        (err) => {
                            console.log("Error in fetching entities:", err)
                        })
            }
        })
    }

    getFormattedReps = (representatives) => {
        let formattedReps = [];
        representatives?.map(item => {
            formattedReps?.push(item?.representative_name);
        })

        return formattedReps?.join('\n ');
    }

    getFormattedAgencies = (representatives) => {
        let formattedAgencies = [];
        representatives?.map(item => {
            formattedAgencies?.push(item?.agency_name);
        })

        return formattedAgencies?.join('\n ');
    }

    getAllPerformers = () => {
        if (this.props?.showSeasonId) {
            this.setState({ isFetchingPerformers: true })
            let payload = this.state.talentFilters;
            payload.show_season_id = this.props?.showSeasonId;
            payload['order_by_clause'] = this.state.sortBy === 'Agency' ? 'Company Name' :
                this.state.sortBy === 'Representative(s)' ? 'Representative Name' : this.state.sortBy;
            payload['order_by'] = this.state.isSortByAsc === true ? 'ASC' : 'DESC';
            CasterService.getData(Constants.CasterServiceBaseUrl +
                `/animationGetPerformers?input=${btoa(JSON.stringify(payload))}`,
                this.props.userContext.active_tenant.tenant_id, 1)
                .then(response => {
                    let performers = response.data;
                    performers?.map(item => {
                        if (item?.akas?.length > 0) {
                            item.talent_name = item.talent_name + ' (' + this.getFormattedAkas(item.akas) + ')'
                            item['representative_name'] = this.getFormattedReps(item.representatives);
                            item['company_name'] = this.getFormattedAgencies(item.representatives);
                        }
                        return item
                    })
                    let performer_contract=performers.filter(item=>item.performer_id===this.state.selectedTalent?.performer_id && item.talent_id===this.state.selectedTalent?.talent_id );
                   if (this.props.talentRef)
                   this.setState({contractList : performer_contract[0]?.contracts});
                   
                   this.setState({
                        list: performers,
                        isFetchingPerformers: false
                    })
                },
                    (err) => {
                        console.log("Error in fetching performers:", err)
                        this.setState({ isFetchingPerformers: false })
                    })
        }
    }

    navigationCallback = (dataItem, node) => {
        // console.log("all talent navigation func called", dataItem, node)
        this.setState({
            showTalentDetails: true,
            selectedTalent: dataItem,
            performerContractId: null
        }, () => {
            this.props?.handleHideTabList();
            this.getTalentContracts();
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.contractList !== this.state.contractList) {
            const oldContractIds = prevState.contractList?.map(contract => contract?.contract_id);
            const newContractIds = this.state.contractList?.map(contract => contract?.contract_id);
            const addedContractIds = newContractIds?.filter(id => !oldContractIds?.includes(id));
            if (addedContractIds?.length > 0) {
                const newContractId = addedContractIds[0];
                this.setState({ selectedContract: newContractId }, () => {
                    this.handleGetContractDetails(newContractId);
                });
            }
        }
      }
      
      getTalentContracts = (is_delete = null) => {
        this.props.selectedPerformer(this.state.selectedTalent?.performer_id);
        this.setState({ isFetchingContractList: true,
            contractList:  this.state.selectedTalent?.contracts
        });
        if(!this.props?.episodeId){
            this.setState({
                contractList:  this.state.selectedTalent?.contracts,
                isFetchingContractList: false
            });
        }
        if(this.props?.episodeId){
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetEntitySearch?entity=EPISODE_PERFORMER_CONTRACTS&performerId=${this.state.selectedTalent?.performer_id}&episodeId=${this.state.selectedContract?.episode_id || this.props?.episodeId}&searchString=null`,
            this.props?.userContext?.active_tenant?.tenant_id
        ).then(
            (response) => {
                this.setState(
                    {
                        contractList: response.data,
                        isFetchingContractList: false,
                    },
                    () => {
                        if (is_delete = 1) {
                            const contract_new = response?.data[0]?.contract_id;
                            if (contract_new){
                                this.handleGetContractDetails(contract_new);}
                            this.setState({is_delete: 0});
                        }
                        else if (!this.props?.selectedEpisodeTalent?.last_accessed_contract_id) {
                            const contract_id = this.state.selectedContract?.contract_id;
                            this.setState({ selectedContract: null },()=>{
                                this.handleGetContractDetails(contract_id);
                            });
                        }
                    }
                );
            },
            (err) => {
                console.log("Error is fetching details" + err);
                this.setState({ isFetchingContractList: false });
            }
        );
        }
    };

    /* getTalentContracts = () => {
        this.setState({ isFetchingContractList: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetEntitySearch?entity=CONTRACTS&performerId=${this.state.selectedTalent?.performer_id}&searchString=null`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({
                    contractList: response.data,
                    isFetchingContractList: false
                }, () => {
                    if (!this.props?.selectedEpisodeTalent?.last_accessed_contract_id) {
                        this.setState({ selectedContract: this.state.contractList[0] })
                        let contractId = this.props?.selectedContract ? this.props?.selectedContract?.contract_id :
                            this.state.performerContractId ? this.state.performerContractId : this.state.contractList[0]?.contract_id;
                        this.handleGetContractDetails(contractId)
                    } else {
                        this.setState({ selectedContract: this.state.contractList?.find(item => item.contract_id === this.props?.selectedEpisodeTalent?.last_accessed_contract_id) })
                        this.handleGetContractDetails(this.props?.selectedEpisodeTalent?.last_accessed_contract_id)
                    }
                })
            },
                (err) => {
                    console.log('Error is fetching details' + err);
                    this.setState({ isFetchingContractList: false })
                });
    } */

    handleGetContractDetails = (contractId) => {
        this.setState({ isFetchingContractDetails: true });
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetContract?contractId=${contractId}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({ selectedContract: response?.data[0], isFetchingContractDetails: false },()=>{
                    this.props.selectedEpisode(this.state.selectedContract?.episode_id); 
                });
               
            },
                (err) => {
                    console.log('Error is fetching contract details' + err);
                });
    }

    handleProfile = () => {
        this.setState({ openTalentProfileModal: true })
    }

    handleRecord = () => {
        this.setState({ openEditTalentRecordModal: true })
    }

    getFormattedAkas = (akas) => {
        let formattedAkas = [];
        akas?.map(item => {
            formattedAkas?.push(item?.aka);
        })

        return formattedAkas?.join(', ');
    }

    applyTalentFilter = (filterValues, filtersNone, dropDownValues) => {
        let config = this.state.config;
        let filtersCopy = JSON.parse(JSON.stringify({ ...talentFilterConfig }))
        if (!filtersNone) {
            filterValues.map((filter) => {
                var keyValue = filter.split(":");
                var name = keyValue[0].trim();
                var value = keyValue[1].trim().toLowerCase();
                var id = dropDownValues?.length !== 0 ? dropDownValues?.filter((item) => item.label.trim().toLowerCase() === value)[0]?.label : null;
                var index = -1;
                var searchKey = "";
                var type = ""
                Object.keys(config?.headings).map((item) => {
                    if (config?.headings[item].headingLabel == name) {
                        index = item;
                        searchKey = config?.headings[item].searchJson;
                        type = config?.headings[item].inputType;
                        return;
                    }
                });
                if (type === "dropdown") {
                    filtersCopy[searchKey] = id + "";
                } else {
                    filtersCopy[searchKey] = value;
                }
                this.setState({
                    talentFilters: filtersCopy, isSortByAsc: true, sortCount: 0, sortBy: "Talent Name"
                }, () => {
                    this.handleTableColumnClick('Talent Name')
                })
            })
        }
        else {
            let filtersJson = JSON.parse(JSON.stringify({ ...talentFilterConfig }))
            this.setState({
                talentFilters: filtersJson, isSortByAsc: true, sortCount: 0, sortBy: "Talent Name"
            }, () => {
                this.handleTableColumnClick('Talent Name')
            })
        }
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
            this.setState({
                sortBy: colName,
                config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName),
                list: tableSortList(colName, this.getSortNode(colName), this.state.list, this.state.isSortByAsc)
            }, () => {
                this.getAllPerformers();
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Talent Name": return "talent_name";
            case "Agency": return "agency_name";
            case "Representative(s)": return "representative_name";
            case "Role Type": return "role_type";
        }
    }

    handleOpenContractPopOver = (id, dataItem, event) => {
        this.setState({
            contractAnchorEl: event.currentTarget,
            selectedPerformer: dataItem
        })
    }

    handleContractRedirection = (contract) => {
        this.setState({
            contractAnchorEl: null,
            showTalentDetails: true,
            selectedTalent: this.state.selectedPerformer,
            performerContractId: contract?.contract_id
        }, () => {
            this.props?.handleHideTabList();
            this.getTalentContracts();
            this.props.selectedPerformer(this.state.selectedPerformer)
        })
    }

    checkIfMinor = (dateOfBirth) => {
        let birthday = new Date(dateOfBirth);

        let ageDifMs = Date.now() - birthday.getTime();
        let ageDate = new Date(ageDifMs);
        let age = Math.abs(ageDate.getUTCFullYear() - 1970);
        if (age < 18) {
            return 'Minor';
        } else {
            return '';
        }
    }

    handlePerformerDetails = (performerId) => {
        CasterService.getData(Constants.CasterServiceBaseUrl +
            `/animationGetPerformerDetails?performerId=${performerId}`,
            this.props.userContext.active_tenant.tenant_id, 1)
            .then(response => {
                let performerDetails = response?.data[0];
                this.setState({ selectedTalent: performerDetails }, () => {
                    this.getTalentContracts();
                })
            },
                (err) => {
                    console.log("Error in fetching performer details:", err)
                })
    }

    handleGetFiles = (type = null) => {
        let payload = {
            "moduleType": "esign",
            "show_type": this.props?.showType,
            "show_id": this.props?.showId,
            "show_name": this.props?.showName,
            "show_season_id": this.props?.showSeasonId,
            "season_name": this.props?.seasonName,
            "episode_id": this.state.selectedContract?.episode_id,
            "episode_name": this.state.selectedContract?.episode_name,
            // "filename": this.state.selectedContract?.esign_contract_type,
            // "filename": this.state.selectedContract?.esign_contract_type === 'Contract Packet'
            //     ? `${this.state.selectedContract?.file_show_name} ${this.state.selectedContract?.file_gl} Contract Packet ${this.state.selectedContract?.file_last_name}`
            //     : `${this.state.selectedContract?.file_show_name} ${this.state.selectedContract?.file_gl} Contract ${this.state.selectedContract?.file_last_name}`,
            "filename": this.state.selectedContract?.file_name,
            "contract_id": this.state.selectedContract?.contract_id,
            "contract_adobe_status": this.state.selectedContract?.contract_adobe_status
        }
        if (type === 'contract') {
            this.setState({ isDownloadingContractPdf: true })
        } else {
            this.setState({ isDownloadingContractPacketPdf: true })
        }
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetFiles?input=${btoa(JSON.stringify(payload))}`,
            this.props?.userContext?.active_tenant?.tenant_id, 1)
            .then(response => {
                if (response.status == 200) {
                    let url = response?.data?.replace(/[" \\]/g, '');
                    const filename = extractFilenameFromUrl(url);
                    let decodedFile = decodeURIComponent(filename)
                    downloadUrl(url, decodedFile);
                    this.setState({ isDownloadingContractPdf: false, isDownloadingContractPacketPdf: false })
                } else {
                    this.setState({ isDownloadingContractPdf: false, isDownloadingContractPacketPdf: false })
                }

            },
                (err) => {
                    this.setState({ isDownloadingContractPdf: false, isDownloadingContractPacketPdf: false })
                    console.log('Error is fetching files' + err);
                });
    }

    handleGenerateReport = (report, checkExisting = false, fileType = null) => {
        let searchPayload = null;
        let load = null;
        this.setState({ selectedReport: report })
        if (report?.toLowerCase() === 'cancel-contract') {
            this.setState({ isCancellingContract:true })
           searchPayload = {
               agreement_id: this.state.selectedContract?.agreement_id,
               cancel:1
           };
           load = {
               payload: "animationPostTemplateToAdobe",
               reportName: this.state.selectedContract?.esign_contract_type === 'Contract Packet' ? 'rptContractPacket' : 'rptContract',
               fileName: this.state.selectedContract?.file_show_name,
               encryptedString: btoa(JSON.stringify(searchPayload)),
               encryptiontype: 1
           };
       } else if (report?.toLowerCase() === 'contract') {
            // this.setState({ isDownloadingContractPdf: true })
            let contractReportName = null;
            if(this.state.selectedContract?.contract_type === "Day Player"){
                contractReportName = "rptContract";
            }else if(this.state.selectedContract?.contract_type === "Sag Daily"){
                contractReportName = "rptSagDailyContract";
            }
            searchPayload = {
                show_season_id: this.props?.showSeasonId,
                episode_id: this.state.selectedContract?.episode_id,
                performer_id: this.state.selectedTalent?.performer_id,
                contract_id: this.state.selectedContract?.contract_id
            };
            load = {
                payload: "casterAnimationGenerateReport",
                reportName: contractReportName,
                fileName: "rptContract",
                searchJson: btoa(JSON.stringify(searchPayload)),
                showHtml: 0,
                encryptionType: 1
            };
            if (checkExisting) {
                load['checkExisting'] = 1;
            } else {
                load['checkExisting'] = 0;
            }
        } else if (report?.toLowerCase() === 'contract-packet') {
            // this.setState({ isDownloadingContractPacketPdf: true })
            let contractPacketReportName = null;
            if(this.state.selectedContract?.contract_type === "Day Player"){
                contractPacketReportName = "rptContractPacket";
            }else if(this.state.selectedContract?.contract_type === "Sag Daily"){
                contractPacketReportName = "rptSagDailyContractPacket";
            }
            searchPayload = {
                show_id: this.props?.showId,
                show_season_id: this.props?.showSeasonId,
                episode_id: this.state.selectedContract?.episode_id,
                performer_id: this.state.selectedTalent?.performer_id,
                contract_date: this.state.selectedContract?.contract_date || null,
                start_date: this.state.selectedContract?.start_date || null,
                account_details: null,
                contract_text: this.state.selectedContract?.notes || null,
                contract_id: this.state.selectedContract?.contract_id
            }
            load = {
                payload: "casterAnimationGenerateReport",
                reportName: contractPacketReportName,
                fileName: "rptContractPacket",
                searchJson: btoa(JSON.stringify(searchPayload)),
                showHtml: 0,
                encryptionType: 1,
            }
            if (checkExisting) {
                load['checkExisting'] = 1;
            } else {
                load['checkExisting'] = 0;
            }
            // this.setState({ isDownloadingContractPacketPdf: true })
        } else {
            this.setState({ isDownloadingTalentVoucherPdf: true })
            searchPayload = {
                show_id: this.props?.showId,
                show_season_id: this.props?.showSeasonId,
                episode_id: this.state.selectedContract?.episode_id,
                performer_id: this.state.selectedTalent?.performer_id,
                start_date: this.state.selectedContract?.start_date,
                account_details: null,
                contract_text: this.state.selectedContract?.notes,
                contract_id: this.state.selectedContract?.contract_id
            };
            load = {
                payload: "casterAnimationGenerateReport",
                reportName: "rptTalentVoucher",
                fileName: "rptTalentVoucher",
                searchJson: btoa(JSON.stringify(searchPayload)),
                showHtml: 0,
                encryptionType: 1
            };
        }
        CasterService.getDataWS(load).then(
            (webSocketConn) =>
                (webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data);
                    if (response?.statusCode == 200) {
                        if (report === 'contract' || report === 'contract-packet') {
                            let result = null;
                            if (!fileType) {
                                result = JSON.parse(response.body);
                                if (result?.count === 0) {
                                    if (report === 'contract') {
                                        this.setState({ isDownloadingContractPdf: true })
                                        this.handleGenerateReport('contract', false, 'existing-file')
                                    } else {
                                        this.setState({ isDownloadingContractPacketPdf: true })
                                        this.handleGenerateReport('contract-packet', false, 'existing-file')
                                    }
                                } else {
                                    this.setState({
                                        openExistingFileModal: true,
                                        existingFileUrl: result?.data?.[0]?.url,
                                        isDownloadingContractPdf: false,
                                        isDownloadingContractPacketPdf: false
                                    })
                                }
                            } else {
                                const filename = extractFilenameFromUrl(response?.body);
                                let decodedFile = decodeURIComponent(filename)
                                downloadUrl(response?.body, decodedFile);
                                this.setState({
                                    isDownloadingContractPdf: false,
                                    isDownloadingContractPacketPdf: false,
                                    isDownloadingTalentVoucherPdf: false,
                                    isPosting: false,
                                    openExistingFileModal: false
                                })
                            }
                        } else if (report === 'cancel-contract') {
                            setTimeout(()=>{
                                this.setState({
                                    isCancellingContract:false,
                                    isDownloadingContractPdf: false,
                                    isDownloadingContractPacketPdf: false,
                                    isDownloadingTalentVoucherPdf: false,
                                    showNotification: true, notificationMessage: "Contract Cancelled Successfully", severity: 'success',
                                    //isDownloadingEPTimesheetPdf : false
                                },this.handleGetContractDetails(this.state.selectedContract?.contract_id)) 
                            },3000);
                        }else {
                            const filename = extractFilenameFromUrl(response?.body);
                            let decodedFile = decodeURIComponent(filename)
                            downloadUrl(response?.body, decodedFile);
                            this.setState({
                                isDownloadingContractPdf: false,
                                isDownloadingContractPacketPdf: false,
                                isDownloadingTalentVoucherPdf: false,
                                isCancellingContract:false,
                            })
                        }
                    }
                })
        );
    }

    handleFileConfirm = (fileType) => {
        if (fileType === 'Open Existing') {
            this.setState({ isPosting: true }, () => {
                const filename = extractFilenameFromUrl(this.state.existingFileUrl);
                let decodedFile = decodeURIComponent(filename)
                downloadUrl(this.state.existingFileUrl, decodedFile);
                this.setState({
                    isDownloadingContractPdf: false,
                    isDownloadingContractPacketPdf: false,
                    isDownloadingTalentVoucherPdf: false,
                    isDownloadingEPTimesheetPdf : false,
                    openExistingFileModal: false,
                    isPosting: false
                })
            })

        } else {
            this.setState({ isPosting: true })
            this.handleGenerateReport(this.state.selectedReport, false, 're-create')
        }
    }

    handleFileCancel = () => {
        this.setState({ openExistingFileModal: false })
    }

    renderLocation() {
        const selectedTalent = this.state.selectedTalent;
        let locationString = '';

        if (selectedTalent) {
            if (selectedTalent.city) {
                locationString += selectedTalent.city;
            }

            if (selectedTalent.state) {
                if (locationString) {
                    locationString += `, ${selectedTalent.state}`;
                } else {
                    locationString += selectedTalent.state;
                }
            }

            if (selectedTalent.zip) {
                if (locationString) {
                    locationString += ` ${selectedTalent.zip}`;
                } else {
                    locationString += selectedTalent.zip;
                }
            }
        }

        return locationString ? <p className="city-line">{locationString}</p> : null;
    }

    handleSubmit = (is_delete) => {
        let payload = { ...this.state.contractDetails };
        payload.is_delete = is_delete;
        payload.performer_id = this.state.selectedTalent?.performer_id;
        payload.contract_id = this.state.selectedContract?.contract_id;
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/animationPostEditContract',
            payload,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ showNotification: true, notificationMessage: "Contract Deletion Failed", severity: 'error' });
                }
                else {
                    this.setState({ is_delete: 1, showNotification: true, notificationMessage: "Contract Deletion Successfully", severity: 'success', is_delete_flag: false, is_circular:false });
                    this.getTalentContracts(is_delete)
                }
            },
                (err) => {
                    console.log('Error while deleting Contract details' + err);
                    this.setState({ showNotification: true, notificationMessage: "Contract Deletion Failed", severity: 'error' });
                });
    }

    displayDeletePopup = () => {
        if (this.state.selectedContract?.contract_adobe_status === null) {
            this.setState({ is_delete: 1, is_delete_flag: true })
        }
        else {
            this.setState({ showNotification: true, notificationMessage: "Contract already initiated for signing", severity: 'error' });
        }
    }

    handleResetNotify = () => {
        this.setState({
            showNotification: false
        });
        
    }

    deletePerformer=(re)=>{
        this.setState({ showNotification: true, notificationMessage: "Performer Deletion Successfully", severity: 'success' });
        setTimeout(()=>{
            if (this.props?.isFromEpisode) {
                this.props?.handleShowEpisodeDetails();
            } else {
                this.setState({ showTalentDetails: false })
            }
            this.props?.handleShowTabList();
        },3000)        
        this.getAllPerformers();
    }

    render() {
        const open = Boolean(this.state.contractAnchorEl);
        return (
            <MDBContainer fluid className="seasonTalentContainer">
                <NotificationComponent
                    open={this.state.showNotification}
                    message={this.state.notificationMessage}
                    severity={this.state.severity}
                    handleResetNotify={this.handleResetNotify.bind(this)}
                />
                <MessageModal
                    open={this.state.is_delete_flag}
                    title={"Delete Contract"}
                    message={this.state.is_delete === 1 ? 'Are you sure you want to delete this Contract?, please click Yes to confirm' : ""}
                    hideCancel={false}
                    primaryButtonText={this.state.is_delete === 1 && 
                                    this.state.is_circular ? (<CircularProgress color="inherit" size={18} />) : ("Yes")}
                    secondaryButtonText={this.state.is_delete === 1 ? "NO" : "OK"}
                    handleClose={() => this.setState({ is_delete_flag: false })}
                    onConfirm={(e) => {
                        this.setState({is_circular: true})
                        this.handleSubmit(this.state.is_delete)
                    }} />
                {!this.state.showTalentDetails ?
                    <MDBCard>
                        <MDBCardBody>
                            <MDBCardText>
                                <MDBRow className="talent-table">
                                    <TableComponent
                                        list={this.state.list || []}
                                        config={this.state.config}
                                        isLoading={this.state.isFetchingPerformers}
                                        options={this.state}
                                        hyperLinkNavigationCallback={this.navigationCallback}
                                        applyFilter={this.applyTalentFilter.bind(this)}
                                        sortCallback={this.handleTableColumnClick}
                                        handleOpenInlineModal={this.handleOpenContractPopOver.bind(this)}
                                    />
                                    <Popover
                                        id={'contract-popover'}
                                        open={open}
                                        anchorEl={this.state.contractAnchorEl}
                                        onClose={() => this.setState({ contractAnchorEl: null })}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        {this.state.selectedPerformer?.contracts?.map(item => (
                                            <MDBRow onClick={() => this.handleContractRedirection(item)}>
                                                <p className="performer-contract">{`${item?.gl_code ? item?.gl_code : ''}${item?.episode_name ? (' - ' + item?.episode_name) : ''}${item?.contract_date ? (' - ' + moment(item?.contract_date).format("MM/DD/YYYY")) : ''}`}</p>
                                            </MDBRow>
                                        ))}
                                    </Popover>
                                </MDBRow>
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard> :
                    <>
                        <MDBRow className="talent-details">
                            <MDBCol md={3}>
                                <MDBRow>
                                    <MDBCol
                                        className="back-to-talent p-0"
                                        onClick={() => {
                                            if (this.props?.isFromEpisode) {
                                                this.props?.handleShowEpisodeDetails();
                                            } else {
                                                this.setState({ showTalentDetails: false })
                                            }
                                            this.props?.handleShowTabList()
                                        }}
                                    >
                                        <span><MDBIcon icon="arrow-left" /></span>
                                        <span>{"All Talent"}</span>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={8} className="p-0">
                                        <div className="talent-name">{this.state.selectedTalent?.talent_name}</div>
                                        <p className="talent-minor">{this.checkIfMinor(moment(this.state.selectedTalent?.dob).format("YYYY-MM-DD"))}</p>
                                    </MDBCol>
                                    <MDBCol className={`${this.props?.isFromEpisode ? 'talent-profile' : ''} profile-btn p-0`}>
                                        <BasicButton
                                            className="profile"
                                            variant="contained"
                                            text={'Profile'}
                                            icon={'pen'}
                                            onClick={() => this.handleProfile()}
                                        />
                                        <BasicButton
                                            variant="outlined"
                                            text={'Record'}
                                            icon={'pen'}
                                            onClick={() => this.handleRecord()}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <div className="aka p-0">
                                        <span className="aka-label"> AKA </span>
                                        <span>{this.getFormattedAkas(this.state.selectedTalent?.akas) || '-'}</span>
                                    </div>
                                    <div className="role-type p-0">
                                        <span className="role-type-label">Role Type </span>
                                        <span>{this.state.selectedTalent?.role_type || '-'}</span>
                                    </div>
                                </MDBRow>
                                <MDBRow className="mt-1">
                                    <MDBCol md={7} className="contract-heading p-0">
                                        <span>{"Contracts"}</span>
                                    </MDBCol>
                                    <MDBCol className="new-contract-btn p-0">
                                        <BasicButton
                                            variant="contained"
                                            text={'New Contract'}
                                            onClick={() => {
                                                if (document.body.style.overflow !== "hidden") {
                                                    document.body.style.overflow = "hidden";
                                                }
                                                this.setState({ openNewContractModal: true })
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="contract-details">
                                    <MDBCard>
                                        <MDBCardBody>
                                            <MDBCardText>
                                                {this.state.isFetchingContractList ? (
                                                    <div className="ContentLoader">
                                                        <CircularProgress />
                                                    </div>
                                                ) : (
                                                        <>
                                                            {this.state.contractList?.length > 0 ?
                                                                <>
                                                                    {this.state.contractList?.map(item => (
                                                                        <MDBRow
                                                                            className={`${item.contract_id === this.state.selectedContract?.contract_id ? 'highlight-contract' : ''} cursor-pointer`}
                                                                            onClick={() => this.handleGetContractDetails(item.contract_id)}
                                                                        >
                                                                            <MDBCol md={8}>{`${item?.gl_code} - ${item?.episode_name}`}</MDBCol>
                                                                            <MDBCol>{item?.recording_date ? getFormattedDate(item?.recording_date, false) : ''}</MDBCol>
                                                                        </MDBRow>
                                                                    ))}
                                                                </> : <p className="no-contracts">No Contracts available</p>
                                                            }
                                                        </>
                                                    )
                                                }
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md={9}>
                                <MDBRow className="talent-details-tab-list">
                                    <TabsComponent
                                        tabList={talentTabList}
                                        value={this.state.talentTabValue}
                                        onChange={(e, v) => this.setState({ talentTabValue: v })}
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        {this.state.talentTabValue === 1 &&
                                            <MDBCard className="talent-info">
                                                <MDBCardBody>
                                                    <MDBCardText>
                                                        <MDBRow>
                                                            {/* <MDBCol md={2}>
                                                                <BasicLabel text={"Recording Date"} />
                                                                <p>
                                                                    {this.state.selectedTalent?.recording_date ?
                                                                        moment(this.state.selectedTalent?.recording_date).format("MM/DD/YYYY") :
                                                                        "-"}
                                                                </p>
                                                            </MDBCol> */}
                                                            <MDBCol md={2}>
                                                                <BasicLabel text={"DOB"} />
                                                                <p>
                                                                    {this.state.selectedTalent?.dob ?
                                                                        moment(this.state.selectedTalent?.dob).format("MM/DD/YYYY") :
                                                                        "-"}
                                                                </p>
                                                            </MDBCol>
                                                            <MDBCol md={5} className="talent-address">
                                                                <BasicLabel text={"Address"} />
                                                                <p className="address-line1">
                                                                    {this.state.selectedTalent?.address_1 ?
                                                                        <span>{`${this.state.selectedTalent?.address_1}`}</span> : ''
                                                                    }
                                                                </p>
                                                                <p className="address-line2">
                                                                    {this.state.selectedTalent?.address_2 ?
                                                                        <span>{`${this.state.selectedTalent?.address_2}`}</span> : ''
                                                                    }
                                                                </p>
                                                                <p className="city-line">
                                                                    {/* {this.state.selectedTalent?.city ?
                                                                        <span>{this.state.selectedTalent?.city}</span> : ''
                                                                    }
                                                                    {this.state.selectedTalent?.state ?
                                                                        <span>{`, ${this.state.selectedTalent?.state}`}</span> : ''
                                                                    }{this.state.selectedTalent?.zip ?
                                                                        <span>{` ${this.state.selectedTalent?.zip}`}</span> : ''
                                                                    } */}

                                                                    {this.renderLocation()}
                                                                </p>
                                                                <p>
                                                                    {(this.state.selectedTalent?.country && this.state.selectedTalent?.country !== 'United States') ?
                                                                        <span>{`${this.state.selectedTalent?.country}`}</span> : ''
                                                                    }
                                                                </p>
                                                            </MDBCol>
                                                            {/* <MDBCol></MDBCol> */}
                                                            <MDBCol style={{ marginLeft: "-25px" }}>
                                                                <BasicLabel text={"Email"} />
                                                                <p>{this.state.selectedTalent?.email || "-"}</p>
                                                            </MDBCol>
                                                            <MDBCol>
                                                                <BasicLabel text={"Cell Number"} />
                                                                <p>{this.state.selectedTalent?.phone || "-"}</p>
                                                            </MDBCol>
                                                        </MDBRow>
                                                        <MDBRow>
                                                            <MDBCol md={2}>
                                                                <BasicLabel text={"Social Security"} />
                                                                <p>{this.state.selectedTalent?.ssn || "-"}</p>
                                                            </MDBCol>
                                                            <MDBCol md={2}>
                                                                <BasicLabel text={"Gender"} />
                                                                <p>{this.state.selectedTalent?.gender || "-"}</p>
                                                            </MDBCol>
                                                            <MDBCol md={3}>
                                                                <BasicLabel text={"Ethnicity"} />
                                                                <p>{this.state.selectedTalent?.ethinicity || "-"}</p>
                                                            </MDBCol>
                                                            <MDBCol className="pl-0 loan-out" md={3}>
                                                                <BasicLabel text={"Loan Out"} />
                                                                <span>{this.state.selectedTalent?.loanout_company}</span>
                                                                <span>{` (No: ${this.state.selectedTalent?.loanout_no || '-'})`}</span>
                                                                <span>{this.state.selectedTalent?.as_of ? ` (As of: ${moment(this.state.selectedTalent?.as_of).format("MM/DD/YYYY")})` : ''}</span>
                                                            </MDBCol>
                                                        </MDBRow>
                                                        <MDBRow>
                                                            <MDBCol md={3}>
                                                                <BasicLabel text={"Primary Guardian Name"} />
                                                                <p>{this.state.selectedTalent?.primary_guardian_name || "-"}</p>
                                                            </MDBCol>
                                                            <MDBCol md={3}>
                                                                <BasicLabel text={"Guardian Email"} />
                                                                <p>{this.state.selectedTalent?.primary_guardian_email || "-"}</p>
                                                            </MDBCol>
                                                            <MDBCol className="pl-0 guardian-phone">
                                                                <BasicLabel text={"Guardian Phone"} />
                                                                <p>{this.state.selectedTalent?.primary_guardian_phone || "-"}</p>
                                                            </MDBCol>
                                                        </MDBRow>
                                                        <MDBRow>
                                                            <MDBCol md={12}>
                                                                <BasicLabel text={"Talent Notes"} />
                                                                <p>{this.state.selectedTalent?.talent_notes || "-"}</p>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCardText>
                                                </MDBCardBody>
                                            </MDBCard>
                                        }
                                        {this.state.talentTabValue === 2 &&
                                            <MDBCard className="rep-info">
                                                <MDBCardBody className="rep-height">
                                                    <MDBCardText>
                                                        {this.state.selectedTalent?.representatives?.map(item => (
                                                            <>
                                                                <MDBRow>
                                                                    <MDBCol md={4} className="rep-name"><p>{item?.representative_name || '-'}</p></MDBCol>
                                                                    <MDBCol md={2}><p>{item?.representative_type || '-'}</p></MDBCol>
                                                                    <MDBCol md={4} className="rep-name"><p>{item?.agency_name || '-'}</p></MDBCol>
                                                                    <MDBCol><p>{item?.company_type || '-'}</p></MDBCol>
                                                                </MDBRow>
                                                                <MDBRow>
                                                                    <MDBCol md={2}>
                                                                        <BasicLabel text={"Phone (Cell)"} />
                                                                        <p>{item?.representative_phone || '-'}</p>
                                                                    </MDBCol>
                                                                    <MDBCol md={4}>
                                                                        <BasicLabel text={"Email (Work)"} />
                                                                        <p>{item?.representative_email || '-'}</p>
                                                                    </MDBCol>
                                                                    <MDBCol className="agency-address">
                                                                        <BasicLabel text={"Address (Office)"} />
                                                                        <p className="address-line1">
                                                                            {item?.address_1 ? <span>{`${item?.address_1}`}</span> : ''}
                                                                        </p>
                                                                        <p className="address-line2">
                                                                            {item?.address_2 ? <span>{`${item?.address_2}`}</span> : ''}
                                                                        </p>
                                                                        <p className="city-line">
                                                                            {item?.city ? <span>{item?.city}</span> : ''}
                                                                            {item?.state ? <span>{`, ${item?.state}`}</span> : ''}
                                                                            {item?.zip ? <span>{` ${item?.zip}`}</span> : ''}
                                                                        </p>
                                                                        <p>
                                                                            {(item?.country && item?.country !== 'United States') ?
                                                                                <span>{`${item?.country}`}</span> : ''
                                                                            }
                                                                        </p>
                                                                    </MDBCol>
                                                                    <MDBCol>
                                                                        <div>
                                                                            <BasicLabel text={"Phone (Office)"} />
                                                                            <p>{item?.compnay_phone || '-'}</p>
                                                                        </div>
                                                                        <div>
                                                                            <BasicLabel text={"Email (Office)"} />
                                                                            <p>{item?.agency_email || '-'}</p>
                                                                        </div>
                                                                    </MDBCol>
                                                                </MDBRow>
                                                            </>
                                                        ))}
                                                    </MDBCardText>
                                                </MDBCardBody>
                                            </MDBCard>
                                        }
                                    </MDBCol>
                                </MDBRow>
                                {this.state.contractList?.length > 0 &&
                                    <>
                                        <MDBRow className="contract-details">
                                            <MDBCol md={3}>
                                                <TabsComponent
                                                    tabList={talentContractTabList}
                                                    value={this.state.contractTabValue}
                                                    onChange={(e, v) => this.setState({ contractTabValue: v })}
                                                />
                                            </MDBCol>
                                            <MDBCol>
                                                <div className="contract-btns">
                                                    {this.state.selectedContract?.contract_adobe_status==='UNSIGNED' && 
                                                        <BasicButton
                                                        variant="contained"
                                                        text={this.state.isCancellingContract ?
                                                            <CircularProgress color="inherit" size={18} /> : "Cancel Contract"
                                                        }
                                                        onClick={()=>this.handleGenerateReport('cancel-contract')}
                                                        />
                                                    }
                                                    <BasicButton
                                                        variant="contained"
                                                        text={this.state.isDownloadingContractPdf ?
                                                            <CircularProgress color="inherit" size={18} /> : "Contract"
                                                        }
                                                        onClick={() => {
                                                            if (this.state.selectedContract?.esign_contract_type === 'Contract' &&
                                                                (this.state.selectedContract?.contract_adobe_status === 'UNSIGNED' ||
                                                                    this.state.selectedContract?.contract_adobe_status === 'SIGNED')) {
                                                                this.handleGetFiles('contract');
                                                            } else {
                                                                this.handleGenerateReport('contract', true)
                                                            }
                                                        }}
                                                    />
                                                    <BasicButton
                                                        variant="contained"
                                                        text={this.state.isDownloadingContractPacketPdf ?
                                                            <CircularProgress color="inherit" size={18} /> : "Contract Packet"
                                                        }
                                                        onClick={() => {
                                                            if (this.state.selectedContract?.esign_contract_type === 'Contract Packet' &&
                                                                (this.state.selectedContract?.contract_adobe_status === 'UNSIGNED' ||
                                                                    this.state.selectedContract?.contract_adobe_status === 'SIGNED')) {
                                                                this.handleGetFiles('contract-packet');
                                                            } else {
                                                                this.handleGenerateReport('contract-packet', true)
                                                            }
                                                        }}
                                                    />
                                                    <BasicButton
                                                        variant="contained"
                                                        text={this.state.isDownloadingTalentVoucherPdf ?
                                                            <CircularProgress color="inherit" size={18} /> : "Talent Voucher"
                                                        }
                                                        onClick={() => this.handleGenerateReport('talent-voucher')}
                                                    />

                                                    <BasicButton
                                                        className="edit-contract-btn"
                                                        variant="contained"
                                                        text={'Delete Contract'}
                                                        onClick={this.displayDeletePopup}
                                                    />

                                                    <BasicButton
                                                        className="edit-contract-btn"
                                                        variant="contained"
                                                        text={'Edit Contract'}
                                                        onClick={() => {
                                                            if (document.body.style.overflow !== "hidden") {
                                                                document.body.style.overflow = "hidden";
                                                            }
                                                            this.setState({ openEditContractModal: true })
                                                        }}
                                                    />
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            {this.state.contractTabValue === 1 &&
                                                <ContractDetails
                                                    isLoading={this.state.isFetchingContractDetails}
                                                    contractDetails={this.state.selectedContract}
                                                    performerDetails={this.state.selectedTalent}
                                                    showId={this.props?.showId}
                                                    showType={this.props?.showType}
                                                    showName={this.props?.showName}
                                                    showSeasonId={this.props?.showSeasonId}
                                                    seasonName={this.props?.seasonName}
                                                    seasonDetails={this.props?.seasonDetails}
                                                    episodeId={this.state.selectedContract?.episode_id || this.props?.episodeId}
                                                    episodeName={this.state.selectedContract?.episode_name || this.props?.episodeName}
                                                    performerId={this.state.selectedContract?.performer_id}
                                                    contractId={this.state.selectedContract?.contract_id}
                                                    toggleRefresh={() => this.handleGetContractDetails(this.state.selectedContract?.contract_id)}
                                                    signerDetails={this.props?.signerDetails}
                                                />
                                            }
                                            {this.state.contractTabValue === 2 &&
                                                <WorkActivityDetails
                                                    episodeId={this.state.selectedContract?.episode_id || this.props?.episodeId}
                                                    contractId={this.state.selectedContract?.contract_id}
                                                    performerId={this.state.selectedTalent?.performer_id}
                                                />
                                            }
                                        </MDBRow>
                                    </>
                                }
                            </MDBCol>
                        </MDBRow>
                    </>
                }
                {this.state.openNewContractModal && (
                    <MainViewModalComponent
                        open={this.state.openNewContractModal}
                        handleClose={() => {
                            document.body.style.overflow = "auto";
                            this.setState({ openNewContractModal: false })
                        }}
                        headerText={"New Contract"}
                        mode={"New"}
                        modalName={"Contract"}
                        showSeasonId={this.props?.showSeasonId}
                        performerId={this.state.selectedTalent?.performer_id}
                        talentDetails={this.state.selectedTalent}
                        toggleRefresh={this.props.talentRef ? this.getAllPerformers : this.getTalentContracts}
                        {...this.props}
                    />
                )}
                {this.state.openEditContractModal && (
                    <MainViewModalComponent
                        open={this.state.openEditContractModal}
                        handleClose={() => {
                            document.body.style.overflow = "auto";
                            this.setState({ openEditContractModal: false },()=>{
                                this.handleGetContractDetails(this.state.selectedContract?.contract_id)
                            })
                        }}
                        headerText={"Edit Contract"}
                        mode={"Edit"}
                        modalName={"Contract"}
                        showSeasonId={this.props?.showSeasonId}
                        contractId={this.state.selectedContract?.contract_id}
                        performerId={this.state.selectedTalent?.performer_id}
                        talentDetails={this.state.selectedTalent}
                        toggleRefresh={this.getTalentContracts}
                        {...this.props}
                    />
                )}
                {
                    this.state.openSignManagerModal && (
                        <MainViewModalComponent
                            open={this.state.openSignManagerModal}
                            handleClose={() => this.setState({ openSignManagerModal: false })}
                            headerText={"E-Sign Manager"}
                            mode={"New"}
                            modalName={"ESignManager"}
                        />
                    )
                }
                {this.state.openTalentProfileModal &&
                    <ModalComponent
                        // navFuns={this.functions}
                        open={this.state.openTalentProfileModal}
                        handleClose={() => this.setState({ openTalentProfileModal: false })}
                        tabList={talentAnimationList}
                        bodyText={"Search for Talent to view their Data, or create a new Talent"}
                        searchPlaceholder={"Search Talent by name or SSN"}
                        headerText={"Talent"}
                        addButtonText={"New Talent"}
                        objectType={'TALENT'}
                        dataUrl='talentDetails'
                        talentId={this.state.selectedTalent?.talent_id}
                        toggleRefresh={() => this.handlePerformerDetails(this.state.selectedTalent?.performer_id)}
                    />
                }
                {this.state.openEditTalentRecordModal && (
                    <MainViewModalComponent
                        open={this.state.openEditTalentRecordModal}
                        handleClose={() => this.setState({ openEditTalentRecordModal: false })}
                        headerText={"Edit Talent Record"}
                        mode={"Edit"}
                        modalName={"Talent Record"}
                        showId={this.props?.showId}
                        showSeasonId={this.props?.showSeasonId}
                        // episodeId={this.state.selectedEpisode?.episode_id}
                        talentDetails={this.state.selectedTalent}
                        performerId={this.state.selectedTalent?.performer_id}
                        toggleRefresh={this.getAllPerformers}
                        updatePerformerDetails={this.handlePerformerDetails}
                        showEpisodeDetails={() => {
                            if (this.props?.isFromEpisode) {
                                this.props?.handleShowEpisodeDetails()
                            } else {
                                this.setState({ showTalentDetails: false })
                            }
                            this.props?.handleShowTabList()
                        }}
                        deletePerformer={this.deletePerformer.bind(this)}
                    />
                )}
                {this.state.openExistingFileModal &&
                    <MainViewModalComponent
                        open={this.state.openExistingFileModal}
                        handleClose={() => this.setState({ openExistingFileModal: false })}
                        headerText={"Existing File"}
                        mode={"New"}
                        modalName={"ExistingFile"}
                        handleFileConfirm={this.handleFileConfirm}
                        handleFileCancel={this.handleFileCancel}
                        isPosting={this.state.isPosting}
                        createdBy={this.state.selectedContract?.created_by}
                        createdDate={this.state.selectedContract?.created_date}
                    />
                }
            </MDBContainer>
        )
    }
}

export default withUserContext(AllTalent);
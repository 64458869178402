import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import './Agent.scss';
import TableComponent from '../SharedComponents/Table';
import { agentDetailsConfig, agencyLocationList, initialAliasConfig, locationConfig } from './Config';
import SelectField from '../SharedComponents/SelectField/SelectField';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import BasicTextArea from '../SharedComponents/BasicTextArea/BasicTextArea';
import { Popover } from '@material-ui/core';
import LocationComponent from '../../Common/LocationComponent/LocationComponent';
import CircularProgress from "@material-ui/core/CircularProgress";
import AliasComponent from '../../Common/AliasComponent/AliasComponent'
import InputSelectRadio from '../../Common/InputSelectRadioInline/InputSelectRadio';
class AgentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPosting: false,
            config: agentDetailsConfig
            // openLocationPopOver: false
        }
    }

    componentDidMount = () => {
        let agentDetailsConfig = this.state.config
        this.props?.isEditing ? agentDetailsConfig.actions = ["pen", "circle", "minus-circle"] : agentDetailsConfig.actions = []
        this.setState({ config: agentDetailsConfig })
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps?.isEditing != this.props?.isEditing) {
            let agentDetailsConfig = this.state.config
            this.props?.isEditing ? agentDetailsConfig.actions = ["pen", "circle", "minus-circle"] : agentDetailsConfig.actions = []
            this.setState({ config: agentDetailsConfig })
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.agencyDetails !== state.agencyDetails) {
            return {
                agencyDetails: props.agencyDetails
            }
        }
        if (props?.isFetchingAgencyDetails != state.isFetchingAgencyDetails) {
            return {
                isFetchingAgencyDetails: props.isFetchingAgencyDetails
            }
        }
        if (props?.selectedLocation != state.selectedLocation) {
            return {
                selectedLocation: props.selectedLocation
            }
        }
        if (props?.openLocationdialogue != state.openLocationdialogue) {
            return {
                openLocationdialogue: props?.openLocationdialogue
            }
        }
        if (props?.agencyLocationList != state.agencyLocationList) {
            return {
                agencyLocationList: props.agencyLocationList
            }
        }
        if (props?.postInitiated != state.postInitiated) {
            return {
                postInitiated: props.postInitiated
            }
        }
        if (props?.locationConfig != state.locationConfig) {
            return {
                locationConfig: props.locationConfig
            }
        }
        return null
    }

    // openLocationPopOver = () => {
    //     this.setState({ openLocationPopOver: true })
    // }

    // closeLocationPopOver = () => {
    //     this.setState({ openLocationPopOver: false })
    // }

    editSelectedLocation = () => {
        this.props.openLocationPopOver()
        this.props?.editLocation()
    }

    render() {
        let list = this.props?.agencyLocationList?.filter(item => item.is_delete != 1)
        return (
            <MDBContainer className="AgentDetailsContainer">
                {!this.props?.isFetchingAgencyDetails ?
                    <>
                        {this.props?.isEditing ?
                            <MDBRow>
                                <MDBCol>
                                    <BasicTextField
                                        id="agencyName"
                                        label="Agency Name"
                                        isAlphabetVar={true}
                                        handleAlphabetVariantSelect={(value) => this.props?.onAgencyDetailChange('agency_name', value)}
                                        value={this.props?.agencyDetails?.agency_name}
                                        onChange={(e) => this.props?.onAgencyDetailChange('agency_name', e.target?.value)}
                                    />
                                </MDBCol>
                            </MDBRow> :
                            <MDBRow>
                                <BasicLabel text={"Name"} />
                                <div className={`displayName`}>{`${this.props?.agencyDetails?.agency_name ? this.props?.agencyDetails?.agency_name : ''}` || '-'}</div>
                            </MDBRow>
                        }
                        {this.props?.isEditing &&
                            <>
                                <MDBRow>
                                    <AliasComponent
                                        aliasDetails={this.props?.agencyDetails?.akas}
                                        onAliasSave={this.props?.onAliasSave.bind(this)}
                                        module_type={"Agency"}
                                        initialAliasConfig={initialAliasConfig}
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={10}></MDBCol>
                                    <MDBCol md={2}>
                                        <BasicButton
                                            className="location-style"
                                            text="Add Location"
                                            variant="contained"
                                            onClick={() => this.props?.openLocationPopOver()}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </>
                        }
                        <MDBRow id="loactionTable">
                            <TableComponent
                                id={"agency_location_id"}
                                config={this.state.config}
                                list={list}
                                // handleOpenPopUp={(event, dataItem) => this.props?.handleShareClick(event, dataItem, this)}
                                handleMarkAsPrimary={(event, dataItem) => this.props?.handleMarkAsPrimary(event, dataItem, this)}
                                editCallback={(event, dataItem) => this.props?.handleShareClick(event, dataItem, this)}
                                handleDelCallBack={(event, dataItem) => this.props?.deleteLocation(event, dataItem,)}
                            />
                        </MDBRow>
                        <br />
                        <MDBRow>
                            <MDBCol md={6}>
                                <MDBRow md={12}>
                                    <MDBCol md={5}>
                                        <div>Occupation</div>
                                        {this.props?.isEditing ?
                                            <SelectField
                                                placeHolderText={"Value"}
                                                options={this.props?.typeOptions}
                                                value={this.props?.agencyDetails?.occupation}
                                                onChange={(e) => { this.props?.onAgencyDetailChange("occupation", e.target.value) }}
                                            />
                                            : <div>{this.props?.agencyDetails?.occupation === null ? " " : this.props?.agencyDetails?.occupation}</div>}
                                    </MDBCol>
                                    <MDBCol md={1}></MDBCol>
                                    {/* <MDBCol md={6}>
                                        <div>Type</div>
                                        <div>{this.props?.agencyDetails?.agency_type === null ? "--" : this.props?.agencyDetails?.agency_type}</div>
                                    </MDBCol> */}
                                </MDBRow>
                                <MDBRow md={12} className="socialField">
                                    {!this.props?.isEditing ? <div>Social Media</div> : null}
                                    {this.props?.agencyDetails?.social_list?.filter((i) => i?.is_delete == 0)?.map((socialType, socialIndex) => (
                                        // this.props?.isEditing ? 
                                        <InputSelectRadio
                                            radioGroupName="agencySocialRadio"
                                            // inputPlaceholder="Enter Social Handle"
                                            showAsLabel={!this.props?.isEditing}
                                            isEditing={this.props?.isEditing}
                                            objectItem={socialType}
                                            addCallback={(obj) => this.props?.addRemoveObject("social_list", obj)}
                                            removeCallback={(id, key, value) => this.props?.onChangeObject("social_list", id, key, value)}
                                            editCallback={(id, key, value) => this.props?.onChangeObject("social_list", id, key, value)}
                                            selectOptions={this.props?.socialOptions}
                                            showAdd={(this.props?.isEditing && socialIndex === 0) ? true : false}
                                            showRemove={socialIndex != 0 && this.props?.isEditing ? true : false}
                                            showRadio={socialIndex != 0 && this.props?.isEditing ? true : false}
                                            isMandatory={true}
                                            fieldValid={socialType?.object_type && socialType?.value ? true : false}
                                            showMandatory={this.props?.postInitiated}
                                            inputValidateMethod={socialType?.object_type != null && socialType?.value != null ? false : true}
                                            showLabel={socialIndex == 0 ? true : false}
                                            labelNew={"Handle"}
                                            addButton={socialIndex == 0 ? true : false}
                                        />
                                        // : 
                                        // <MDBCol md={11} className="social-display">
                                        //     <div className="mr-2">{socialType.object_type}</div>
                                        //     <div className="mr-2">{socialType.value}</div>
                                        //     <div className="mr-2">{socialType.is_primary && socialType.value != null ? <MDBIcon fas icon="star" /> : null }</div>
                                        // </MDBCol>
                                    ))}
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md={6}>
                                {this.props?.isEditing ?
                                    <BasicTextArea
                                        label={"Notes"}
                                        value={this.props?.agencyDetails?.agency_notes}
                                        onChange={(e) => { this.props?.onAgencyDetailChange("agency_notes", e.target.value, null) }}
                                    /> :
                                    <>
                                        <div>Notes</div>
                                        <div>{this.props?.agencyDetails?.agency_notes}</div>
                                    </>
                                }
                            </MDBCol>
                        </MDBRow>
                        {this.props?.openLocationdialogue &&
                            <LocationComponent
                                locationConfig={this.props?.locationConfig}
                                openLocationPopOver={this.props?.openLocationdialogue}
                                popOverClose={() => this.props?.closeLocationPopOver()}
                                onLocationSave={this.props?.onLocationSave}
                                isEditing={this.props?.isEditing}
                                initialLocationConfig={locationConfig}
                            // onChangeObject={this.props.onChangeObject.bind(this)}
                            />
                        }
                    </> :
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>}
            </MDBContainer>

        );

    }
}
export default AgentDetails;
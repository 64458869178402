export const seasonMenuItem = [
    {
        label: '1 - Dysfunctional (2022-2023) (T27.12300)',
        value: 1
    }
]

export const showSeasonTabList = [
    {
        label: "Episode List",
        value: 1,
    },
    {
        label: "All Talent",
        value: 2,
    },
]

export const talentTabList = [
    {
        label: "Talent Information",
        value: 1,
    },
    {
        label: "Representatives",
        value: 2,
    },
]

export const talentContractTabList = [
    {
        label: "Contract",
        value: 1,
    },
    {
        label: "Work Activity",
        value: 2,
    },
]

export const talentConfig = {
    headings: [
        {
            headingLabel: "Talent Name",
            width: "25%",
            inputType: "text",
            searchJson: "talent_name",
            operator: "talent_name_operator",
        },
        {
            headingLabel: "Agency",
            icon: "",
            width: "23%",
            inputType: "text",
            searchJson: "agency_name",
            operator: "agency_name_operator",
        },
        {
            headingLabel: "Representative(s)",
            width: "25%",
            inputType: "text",
            searchJson: "representative_name",
            operator: "representative_name_operator",
        },
        {
            headingLabel: "Role Type",
            width: "15%",
            inputType: "dropdown",
            entityType: "ROLE_TYPE",
            selectOptions: "roleTypeOptions",
            searchJson: "role_type",
            operator: "role_type_operator",
        }, 
        {
            headingLabel: "Contract(s)",
            width: "12%",
            inputType: "",
        },
    ],
    dataNodes: ["talent_name", "company_name", "representative_name", "role_type"],
    actions: ["arrows-alt-v"],
    primaryKey: "talent_id",
    hyperlinks: ["talent_name"],
    filtersLanding: true,
    filters : true,
};

export const deletetalentConfig = {
    headings: [
        {
            headingLabel: "Talent Name",
            width: "25%",
            inputType: "text",
            searchJson: "talent_name",
            operator: "talent_name_operator",
        },
        {
            headingLabel: "Agency",
            icon: "",
            width: "23%",
            inputType: "text",
            searchJson: "agency_name",
            operator: "agency_name_operator",
        },
        {
            headingLabel: "Representative(s)",
            width: "25%",
            inputType: "text",
            searchJson: "representative_name",
            operator: "representative_name_operator",
        },
        {
            headingLabel: "Role Type",
            width: "15%",
            inputType: "dropdown",
            entityType: "ROLE_TYPE",
            selectOptions: "roleTypeOptions",
            searchJson: "role_type",
            operator: "role_type_operator",
        }, 
        {
            headingLabel: "Contract(s)",
            width: "12%",
            inputType: "",
        }, 
    ],
    dataNodes: ["talent_name", "company_name", "representative_name", "role_type"],
    actions: ["arrows-alt-v","trash-alt"],
    actionClassName:"showSeasonAction",
    primaryKey: "talent_id",
    hyperlinks: ["talent_name"],
    filtersLanding: true,
    filters : true,
}

export const WorkActivityConfig = {
    headings: [
        {
            headingLabel: "Date",
            icon: "",
            width: "20%",
            inputType: "datefield",
        },
        {
            headingLabel: "Activity",
            icon: "",
            width: "20%",
            inputType: "select",
            selectOptions: [],
        },
        {
            headingLabel: "Description",
            icon: "",
            width: "20%",
            inputType: "label"
        },
        {
            headingLabel: "On Set",
            icon: "",
            width: "20%",
            inputType: "timefield",
        },
        {
            headingLabel: "Wrap",
            icon: "",
            width: "20%",
            inputType: "timefield",
        },
        {
            headingLabel: "Memo",
            icon: "",
            width: "30%",
            inputType: "text",
        },
        {
            headingLabel: "",
            icon: "",
            width: "10%",
        },
    ],
    dataNodes: ["work_date", "activity_code", "activity_description", "on_set", "wrap", "memo_text"],
    actions: ["minus","pen","trash-alt"],
    primaryKey: "id",
    inlineEdits: [
        {
            dataNode: "work_date",
            width: "13%"
        },
        {
            dataNode: "activity_code",
            width: "12%"
        },
        {
            dataNode: "activity_description",
            width: "18%",
        },
        {
            dataNode: "on_set",
            width: "15%",
            
            
        },
        {
            dataNode: "wrap",
            width: "15%",
        },
        {
            dataNode: "memo_text",
            width: "30%"
        },
    ],
    isClearFields: true,
}

export const workActivityPayload = {
    "contract_work_activity_id": null,
    "contract_id": null,
    "episode_id": null,
    "performer_id": null,
    "work_date": null,
    "activity_code": null,
    "activity_description": null,
    "on_set": null,
    "wrap": null,
    "memo_text": null,
    "is_delete": 0
}

export const talentFilterConfig = {
    "show_season_id": null,
    "episode_id": null,
    "talent_name": null,
    "representative_name": null,
    "agency_name": null,
    "role_type": null
}

import React from "react";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBCol, MDBRow, MDBContainer } from "mdb-react-ui-kit";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import SelectField from "../SharedComponents/SelectField/SelectField";
import messages from "../../Common/Messages.json";
import ReportButton from './ReportButton';
import './Worksheet.scss';
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { downloadUrl, extractFilenameFromUrl } from "../../Common/Helper";
import SearchSelectField from "../SharedComponents/SearchSelectField";
import MessageModal from "../SharedComponents/MessageModal";

class Worksheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportDetails: [],
      showOption: [],
      seasonOption: [],
      episodeOption: [],
      worksheetModal: false,
      pdfRes:null
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.reportDetails !== state.reportDetails) {
      return {
        reportDetails: props.reportDetails,
      };
    }
    return null;
  }

  onClickGenratePdf() {
    this.props?.tabValue === 0 ?
      this.setState({ postInitiatedWorksheet: true }) : this.setState({ postInitiated: true })

    let searchPayload = {
      show_id: this.props?.reportDetails?.show_id?.value,
      show_season_id: this.props?.reportDetails?.show_season_id,
      episode_id: this.props?.reportDetails?.episode_id,
    };

    Object.keys(searchPayload).map((obj) => {
      if (searchPayload[obj] === undefined) {
        searchPayload[obj] = null;
      }
    });
    let encryptTedStr = btoa(JSON.stringify(searchPayload));

    let CastingReportload = {
      payload: "casterAnimationGenerateReport",
      reportName: "rptCastingData",
      fileName: "rptCastingData",
      searchJson: encryptTedStr,
      showHtml: 0,
      encryptionType: 1
    };

    let load = {
      payload: "casterAnimationGenerateReport",
      reportName: "rptWorksheet",
      fileName: "rptWorksheet",
      searchJson: encryptTedStr,
      showHtml: 0,
      encryptionType: 1
    };

    CasterService.getDataWS(this.props?.tabValue === 0 ? load : CastingReportload).then(
      (webSocketConn) =>
      (webSocketConn.onmessage = (e) => {
        let response = JSON.parse(e.data);
        if (response?.statusCode == 200 && response?.body?.message) {
          this.setState({worksheetModal:true,pdfRes:response.body.message})
        }else{
          this.props?.tabValue === 0 ?
          this.setState({ postInitiatedWorksheet: false }) : this.setState({ postInitiated: false })
        const filename = extractFilenameFromUrl(response?.body);
        let decodedFile = decodeURIComponent(filename)
        downloadUrl(response?.body, decodedFile);
        }
      })
    );
  }

  render() {
    const { reportButtonShow } = this.props;
    return (
      <div className="worksheetContainer">
        <MDBContainer className="reportContainer">
          <span className="header">
            The file will generate in your browser as PDF.
          </span>
          <MDBRow>
            <MDBCol md={8}>
              <SearchSelectField
                id={"show"}
                label={"Select Show"}
                defaultMenuText={"-"}
               
                options={this.props?.showOption}
                valueSelected={(e, value) =>
                  this.props.handleGeneralEdit("show_id", value)
                }
                searchSelect={true}
                searchText={(e) => this.props?.getShow("SHOW", e.target.value)}
                detail_selected={this.props?.reportDetails?.show_id}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md={8}>
              <SelectField
                id={"season"}
                value={this.props?.reportDetails?.show_season_id}
                label={"Select season"}
                defaultMenuText={"-"}
                options={this.props?.seasonOption}
                onChange={(e) =>
                  this.props.handleGeneralEdit("show_season_id", e.target.value)
                }
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md={8}>
              <SelectField
                id={"episode"}
                value={this.state?.reportDetails?.episode_id || this.props?.reportDetails?.episode_id}
                label={"Select episode"}
                defaultMenuText={"-"}
                options={this.props?.episodeOption}
                onChange={(e) =>
                  this.props.handleGeneralEdit("episode_id", e.target.value)
                }
              />
            </MDBCol>
          </MDBRow>
          <ReportButton
            onClickGenratePdf={this.onClickGenratePdf.bind(this)}
            handleClose={this.props?.handleClose}
            postInitiated={this.state.postInitiated}
            postInitiatedWorksheet={this.state.postInitiatedWorksheet}
          />
        </MDBContainer>
        {
          this.state.worksheetModal &&
          <MessageModal
            title={"PDF Download Failed"}
            open={this.state.worksheetModal}
            message={
              this.state.pdfRes
            }
            primaryButtonText={""}
            secondaryButtonText={"Cancel"}
            handleClose={(e) => this.setState({ worksheetModal: false })}
          />
        }
      </div>
    );
  }
}

export default withUserContext(Worksheet);

import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { withUserContext } from "../../../../contexts/UserContext";
import SearchFieldWithAddValue from "../../../components/SharedComponents/SearchFieldWithAddValue";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import MessageModal from '../../../components/SharedComponents/MessageModal';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import ModalComponent from '../../../Common/DefaultModalLayout/Modal/Modal';
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import messages from '../../../Common/Messages.json';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { CircularProgress } from '@material-ui/core';
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { talentAnimationList } from '../../../../constants/NavList';
import { talentInitialConfig, repConfig } from '../Config';
import './TalentRecord.scss';
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea";

class NewTalentRecord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            talentDetails: this.formatTalentList(JSON.parse(JSON.stringify({ ...talentInitialConfig }))),
            roleTypeOptions: [],
            isPosting: false,
            inActiveRepresentatives: [],
            talentList: [],
            repIndex: 2,
            showNotification: false,
            notificationMessage: '',
            is_circular: false
        };
    }

    componentDidMount() {
        this.getLookupDate('ROLE_TYPE', 'roleTypeOptions');
        this.getLookupDate('I9_STATUS', 'i9StatusOptions');
        this.getLookupDate('SAG_STATUS', 'sagStatusOptions');
        // if (this.props?.performerId) {
        //     this.getPerformerDetails()
        // }
    }

    getPerformerDetails = () => {
        let performerDetails = { ...this.props?.talentDetails }
        performerDetails.role_type = this.state.roleTypeOptions?.find(item => item.label === performerDetails.role_type)?.value;
        performerDetails.i9_status = this.state.i9StatusOptions?.find(item => item.label === performerDetails.i9_status)?.value;
        performerDetails.sag_status = this.state.sagStatusOptions?.find(item => item.label === performerDetails.sag_status)?.value;
        let representatives = [];
        performerDetails.representatives?.map(item => {
            let representativeDetails = { ...repConfig };
            representativeDetails.performer_representative_id = item.performer_representative_id;
            representativeDetails.representative_id = item.representative_id;
            representativeDetails.representative_name = item.representative_name;
            representativeDetails.company_id = item.agency_id;
            representativeDetails.company_name = item.agency_name;
            representatives?.push(representativeDetails)
        })
        performerDetails.representatives = representatives;
        this.setState({ talentDetails: performerDetails }, () => {
            this.getTalentInfo(this.state.talentDetails?.talent_id);
        })
    }

    formatTalentList = (response) => {
        if (response?.representatives?.length === 0) {
            response?.representatives.push(JSON.parse(JSON.stringify({ ...repConfig })));
        }

        return response;
    }

    getTalentDetails = (searchString = null) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetEntitySearch?entity=TALENT&searchString=${searchString}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log("check the talent details", response)
                let formattedList = response.data?.map(item => ({ value: item.talent_id, text: item.talent_name }));
                this.setState({ talentList: formattedList })
            },
                (err) => {
                    console.log('Error is fetching details' + err);
                });
    }

    getLookupDate = (entityType, optionName) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetLookupData?entityType=${entityType}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value, is_active: item?.is_active }));
                this.setState({ [optionName]: formattedList }, () => {
                    if (this.props?.mode === 'Edit') {
                        this.getPerformerDetails()
                    }
                })
            },
                (err) => {
                    console.log('Error is fetching details' + err);
                });
    }

    functions = {
        handleHideTalentPopup: () => {
            this.setState({ openTalentModal: false });
        },
    }

    handleSelectedValue = (field, value) => {
        this.setState((prevState) => ({
            talentDetails: {
                ...prevState.talentDetails,
                [field]: value,
            },
            postInitiated: false
        }));
    };

    handleNewTalentCreation = (talentId) => {
        if (talentId) {
            this.getTalentInfo(talentId, true);
        }
    }

    handleTalentOnChange = (e, newValue) => {
        if (newValue) {
            console.log("check the new value selected", newValue);
            if (newValue?.text?.indexOf('Create New Talent') != -1) {
                let talentProfileDetails = {};
                talentProfileDetails['first_name'] = this.getTalentProfileName("first_name", newValue?.value);
                talentProfileDetails['middle_name'] = this.getTalentProfileName("middle_name", newValue?.value) || null;
                talentProfileDetails['last_name'] = this.getTalentProfileName("last_name", newValue?.value) || null;
                this.setState({ openTalentModal: true, talentProfileDetails: talentProfileDetails })
            } else {
                this.handleSelectedValue("talent_id", newValue?.value || null);
                this.handleSelectedValue("talent_name", newValue?.text || null)
                this.getTalentInfo(newValue?.value);
            }
        }
        else {
            this.setState({ talentDetails: {} })
        }
    }

    getTalentProfileName = (field, value) => {
        let n = value?.split(" ");
        if (field === "first_name") {
            if (n?.length > 0) {
                return n[0];
            }
        } else if (field === "middle_name") {
            if (n?.length > 2) {
                return n[1];
            }
        } else if (field === "last_name") {
            if (value.split(" ").length > 1) {
                let lastName = [];
                n?.map((item, index) => {
                    if (n?.length <= 2 && index !== 0) {
                        lastName.push(item)
                    } else if (n?.length > 2 && index !== 0 && index !== 1) {
                        lastName.push(item)
                    }
                })
                return lastName.join(" ");
            }
        }
    }

    handleOnchange = (field, value, index = null) => {
        console.log("check the rep valu selected", value)
        const { talentDetails } = this.state;
        const updatedRepresentative = [...talentDetails.representatives];
        if (field === 'representative') {
            updatedRepresentative[index][field + '_id'] = value;
            updatedRepresentative[index][field + '_name'] = this.state.representativeOptions?.find(item => item.value === value)?.label;
            updatedRepresentative[index]['company_id'] = this.state.representativeOptions?.find(item => item.value === value)?.company_id || null;
            updatedRepresentative[index]['company_name'] = this.state.representativeOptions?.find(item => item.value === value)?.company_name || null;
            this.setState({
                talentDetails: {
                    ...talentDetails,
                    representatives: updatedRepresentative,
                },
            });
        } else {
            this.setState((prevState) => ({
                talentDetails: {
                    ...prevState.talentDetails,
                    [field]: value,
                },
                postInitiated: false
            }));

        }
    };

    getTalentInfo = (talentId, newTalent = false) => {
        CasterService.getData(
            Constants.CasterServiceBaseUrl + `/animationGetTalent?searchKey=id&searchString=${talentId}`,
            this.props.userContext?.active_tenant?.tenant_id
        ).then(
            (response) => {
                let talentList = [...this.state.talentList]
                if (newTalent) {
                    let talentDetails = {
                        value: response?.data[0]?.talent_id,
                        text: response?.data[0]?.first_name + (response?.data[0]?.middle_name ? ` ${response?.data[0]?.middle_name}` : '') + (response?.data[0]?.last_name ? ` ${response?.data[0]?.last_name}` : '')
                    }
                    talentList?.push(talentDetails);
                }

                let representiveList = response?.data[0]?.representatives?.map(
                    (item) => ({
                        value: item.representative_id,
                        label: item.representative_name,
                        type: "rep",
                        company_id: item?.company_id,
                        company_name: item?.company_name
                    })
                );
                this.setState({
                    representativeOptions: representiveList,
                    talentList: talentList
                }, () => {
                    if (newTalent) {
                        let talentName = response?.data[0]?.first_name + (response?.data[0]?.middle_name ? ` ${response?.data[0]?.middle_name}` : '') + (response?.data[0]?.last_name ? ` ${response?.data[0]?.last_name}` : '');
                        this.handleSelectedValue("talent_id", response?.data[0]?.talent_id);
                        this.handleSelectedValue("talent_name", talentName)
                    }
                });
            },
            (err) => {
                this.setState({ representativeOptions: [] });
                console.log("Error in fetching Talent Details:", err);
            }
        );
    }

    handleSelectedTalent = (value) => {
        this.setState({
            newTalentRecordSelected: value,
            isNewTalentRecord: false,
        });
    };

    addCallback = (index) => {
        let talentDetails = JSON.parse(JSON.stringify({ ...this.state.talentDetails }));
        let initialRepArray = JSON.parse(JSON.stringify({ ...repConfig }));
        let representatives = [...talentDetails.representatives];
        initialRepArray.index = this.state.repIndex;
        initialRepArray.canRemove = true;
        representatives.push(initialRepArray)
        talentDetails.representatives = representatives;
        this.setState({ repIndex: this.state.repIndex + 1, talentDetails: talentDetails });
    }

    removeCallback = (listName, callbackItem, index) => {
        this.setState({
            listName: listName,
            callbackItem: callbackItem,
            postInitiated: false,
            index: index
        }, () => {
            if (callbackItem.performer_representative_id) {
                this.setState({ isOpen: true })
            } else {
                this.deleteRow(listName, callbackItem, 'new');
            }
        })
    }

    deleteRow = (listName, callbackItem, type, index = null) => {
        let talentDetails = this.state.talentDetails;
        talentDetails[listName][this.state.index].is_delete = 1;
        if (type === 'edit') {
            let temp = [];
            this.state.inActiveRepresentatives?.map((item) => {
                temp.push(item);
            });
            let representativeList = talentDetails[listName].filter(item =>
                (item.is_delete === 1 && item.index === callbackItem.index)
            );
            temp.push(representativeList[0]);
            this.setState({ inActiveRepresentatives: temp })
        }
        let representativeList = talentDetails[listName].filter(item =>
            (item.performer_representative_id !== callbackItem.performer_representative_id) || (item.index !== callbackItem.index)
        );
        if (representativeList.length === 0) {
            talentDetails[listName] = [{ ...repConfig }];
        } else {
            talentDetails[listName] = representativeList;
        }
        this.setState({
            talentDetails: talentDetails,
            listName: null,
            callbackItem: null,
            postInitiated: false,
            isOpen: false,
        });
    }

    getFormattedTalentDetails = (talentDetails) => {
        let representativeTemp = [];
        this.state.inActiveRepresentatives?.map(item => {
            representativeTemp?.push(item);
        });
        talentDetails.representatives?.map(item => {
            representativeTemp?.push(item);
        });
        talentDetails.representatives = representativeTemp;
        return talentDetails;
    }

    getPerformer = (performerId) => {
        CasterService.getData(Constants.CasterServiceBaseUrl +
            `/animationGetPerformerDetails?performerId=${performerId}`,
            this.props.userContext.active_tenant.tenant_id, 1)
            .then(response => {
                console.log("check the performer details response", response);
                let performerDetails = response?.data[0];
                this.setState({ performerDetails: performerDetails }, () => {
                    this.setState({ openNewContractModal: true })
                })
            },
                (err) => {
                    console.log("Error in fetching performer details:", err)
                })
    }

    canSubmit = (talentDetails) => {
        let canSubmit = talentDetails?.talent_id;
        // && (!talentDetails?.representatives?.some(rep => (!(rep.representative_name && rep?.representative_id) || false)));
        return canSubmit;
    }

    handleSubmit = (createContract = false, isDelete = false) => {
        this.setState({ postInitiated: true })
        let payload = { ...this.state.talentDetails };
        payload.show_id = this.props?.showId;
        payload.show_season_id = this.props?.showSeasonId;
        payload.role_type = this.state.roleTypeOptions?.find(item => item.value === payload.role_type)?.label;
        payload.i9_status = this.state.i9StatusOptions?.find(item => item.value === payload.i9_status)?.label;
        payload.sag_status = this.state.sagStatusOptions?.find(item => item.value === payload.sag_status)?.label;
        payload.representatives = payload?.representatives?.filter(item => item.representative_id !== null);
        if (isDelete) {
            payload['is_delete'] = 1;
        }
        payload = this.getFormattedTalentDetails(payload);
        if (this.canSubmit(payload)) {
            if (isDelete) {
                this.setState({ isDeletingRecord: true })
            } else {
                this.setState({ isPosting: true, isSubmiting: true, createContract: createContract })
            }
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/animationPostPerformer`,
                payload,
                this.props?.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    if (response.data.error) {
                        this.setState({ isPosting: false, isSubmiting: false, isDeletingRecord: false })
                        this.props.notificationComponent(true, "fail");
                    }
                    else if(response.data[0].message === "Performer having signed contracts")
                    {
                        this.setState({ is_delete: 0, showNotification: true, notificationMessage: "Performer cannot be deleted due to some contracts sent for signing or fully signed", severity: 'error',
                        isPosting: false,postInitiated: false,isSubmiting: false,isDeletingRecord: false, is_circular: false});
                    } 
                    else if(response.data[0].message === "Performer Deleted Successfully") {
                        this.setState({ is_delete: 0, showNotification: true, notificationMessage: "Performer Deleted Successfully", severity: 'success',
                        isPosting: false,postInitiated: false,isSubmiting: false,isDeletingRecord: false},() => {
                        this.props?.deletePerformer("1");
                        });
                    }
                    else if(response.data[0].message === "Performer With Same Name Already Exists") {
                        this.setState({ is_delete: 0, showNotification: true, notificationMessage: "Performer with same name already exists.", severity: 'error',
                        isPosting: false,postInitiated: false,isSubmiting: false,isDeletingRecord: false});
                    } else {
                        if (createContract) {
                            this.setState({
                                // openNewContractModal: true,
                                performerId: response?.data[0]?.performer_id,
                                isPosting: false,
                                postInitiated: false,
                                isSubmiting: false,
                                isDeletingRecord: false
                            }, () => {
                                this.getPerformer(response?.data[0]?.performer_id)
                            })
                        } else {
                            if (this.props?.updatePerformerDetails) {
                                this.props?.updatePerformerDetails(response?.data[0]?.performer_id)
                            }
                            if (this.props?.showEpisodeDetails && isDelete) {
                                this.props?.showEpisodeDetails();
                            }
                            this.props.notificationComponent(true, "success", "refresh-performers");
                            this.setState({
                                isPosting: false,
                                postInitiated: false,
                                isSubmiting: false,
                                isDeletingRecord: false
                            })
                        }
                    }
                },
                    (err) => {
                        console.log('Error is submitting performer' + err);
                        this.props.notificationComponent(true, "fail");
                        this.setState({ isPosting: false, postInitiated: false, isSubmiting: false })
                    });
        } else {
            console.log("Error in submitting performer")
        }
    }

    handleResetNotify = () => {
        this.setState({
            showNotification: false
        });
    }

    displayDeletePopup = () => {
        this.setState({ is_delete: 1, is_delete_flag: true })
    }

    render() {
        return (
            <div>
                {this.state.isFetchingTalentDetails ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBContainer className="newTalentRecordContainer">
                            <NotificationComponent
                                open={this.state.showNotification}
                                message={this.state.notificationMessage}
                                severity={this.state.severity}
                                handleResetNotify={this.handleResetNotify.bind(this)}
                            />
                            <MessageModal
                                open={this.state.is_delete}
                                title={"Delete record"}
                                message={"Would you like to delete saved record?"}
                                primaryButtonText={this.state.is_circular ? (<CircularProgress color="inherit" size={18} />) : ("Delete")}
                                secondaryButtonText={"Cancel"}
                                onConfirm={() => {this.setState({is_circular: true})
                                                 this.handleSubmit(false, true)}}
                                handleClose={() => this.setState({ is_delete: false })}
                            />
                            <span className="font-header">
                                First, Select or Create a talent profile, and choose their representation.
                        </span>
                            <MDBRow>
                                <MDBCol md={10} className={`${this.props?.mode === 'Edit' ? 'edit-talent' : ''} talent-search`}>
                                    <BasicLabel text={"Select Talent"} isMandatory={true} />
                                    <SearchFieldWithAddValue
                                        id={"talent"}
                                        placeholder={'- Search -'}
                                        options={this.state.talentList || []}
                                        showMandatory={this.state.postInitiated}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        primaryAddText={"Create New Talent"}
                                        detail_selected={this.state.talentDetails?.talent_id ? {
                                            value: this.state.talentDetails?.talent_id,
                                            text: this.state.talentDetails?.talent_name
                                        } : null}
                                        width={'100%'}
                                        valueSelected={this.handleTalentOnChange}
                                        searchText={(e) => this.getTalentDetails(e.target.value)}
                                        multiple={false}
                                        searchSelect={true}
                                        disabled={this.props?.mode === 'Edit'}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={6}>
                                    <SelectField
                                        label={"Role Type"}
                                        value={this.state.talentDetails.role_type}
                                        options={this.state.roleTypeOptions || []}
                                        onChange={(e) => {
                                            this.handleOnchange("role_type", e.target.value);
                                        }}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={6}>
                                    <SelectField
                                        label={"I9 Status"}
                                        value={this.state.talentDetails.i9_status}
                                        options={this.state.i9StatusOptions || []}
                                        onChange={(e) => {
                                            this.handleOnchange("i9_status", e.target.value);
                                        }}
                                    />
                                </MDBCol>
                                <MDBCol md={6}>
                                    <SelectField
                                        label={"SAG Status"}
                                        value={this.state.talentDetails.sag_status}
                                        options={this.state.sagStatusOptions || []}
                                        onChange={(e) => {
                                            this.handleOnchange("sag_status", e.target.value);
                                        }}
                                    />
                                </MDBCol>
                            </MDBRow>
                            {this.state.talentDetails?.representatives?.map((item, index) =>
                                <MDBRow>
                                    <MDBCol md={9} >
                                        <SelectField
                                            label={index === 0 ? 'Representative(s)' : ''}
                                            value={item.representative_id}
                                            options={this.state.representativeOptions || []}
                                            // isMandatory={true}
                                            // showMandatory={this.state.postInitiated}
                                            // mandatoryWarning={messages.mandatoryWarning}
                                            onChange={(e) => {
                                                this.handleOnchange("representative", e.target.value, index);
                                            }}
                                            disabled={!this.state.talentDetails?.talent_id || false}
                                        />
                                    </MDBCol>
                                    <MDBCol md={2} className={`${index === 0 ? 'firstRowIcons' : 'icons'} status-actions`}>
                                        {!(this.state.talentDetails?.representatives?.length === 1) ? (
                                            <RemoveCircleIcon
                                                className="ml-2 align-self-center"
                                                fontSize="small"
                                                onClick={() => this.removeCallback('representatives', item, index)}
                                            />
                                        ) : null}
                                        {(this.state.talentDetails?.representatives?.length - 1 === index &&
                                            this.state.talentDetails?.talent_id) ? (
                                                <AddCircleIcon
                                                    className="ml-2 align-self-center"
                                                    fontSize="small"
                                                    onClick={() => this.addCallback(index)}
                                                />
                                            ) : null}
                                    </MDBCol>
                                </MDBRow>
                            )}
                            <MDBRow>
                                <MDBCol colgroup={3}>
                                    <BasicTextArea
                                        label={"Talent Notes"}
                                        value={this.state.talentDetails.talent_notes}
                                        onChange={(e) =>
                                            this.handleOnchange("talent_notes", e.target.value)
                                        }
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="p-2 margin">
                                <MDBCol md={3} lg={3}></MDBCol>
                                {this.props?.mode === 'New' &&
                                    <MDBCol md={5} lg={5} className="p-0">
                                        <BasicButton
                                            type="inline"
                                            variant="outlined"
                                            text={(this.state.isSubmiting && this.state.createContract) ?
                                                <CircularProgress color="inherit" size={18} /> : "Create -> New Contract"
                                            }
                                            onClick={() => this.handleSubmit(true, false)}
                                        />
                                    </MDBCol>
                                }
                                {this.props?.mode === 'Edit' &&
                                    <>
                                        <MDBCol md={2}></MDBCol>
                                        <MDBCol md={2} className="pl-0 delete-talent-record">
                                            <BasicButton
                                                className="btnwidth"
                                                variant="outlined"
                                                text="Delete"
                                                icon={"Create"}
                                                onClick={() => this.displayDeletePopup()}
                                            />
                                        </MDBCol>
                                    </>
                                }
                                <MDBCol md={this.props?.mode === 'Edit' ? 3 : 2} className={this.props?.mode === 'Edit' ? '' : 'pl-0'}>
                                    <BasicButton
                                        className="btnwidth"
                                        variant="contained"
                                        text={(this.state.isPosting && !this.state.createContract) ?
                                            <CircularProgress color="inherit" size={18} /> :
                                            this.props?.mode === 'Edit' ? "Save" : "Create"
                                        }
                                        icon={"Create"}
                                        onClick={() => this.handleSubmit(false, false)}
                                    />
                                </MDBCol>
                                <MDBCol md={2} className={`${this.props?.mode === 'Edit' ? 'pl-0' : ''}`}>
                                    <BasicButton
                                        type="inline"
                                        variant="outlined"
                                        text={"Cancel"}
                                        onClick={() => { this.props?.onClose() }}
                                    />
                                </MDBCol>
                            </MDBRow>
                            {this.state.openNewContractModal && (
                                <MainViewModalComponent
                                    open={this.state.openNewContractModal}
                                    handleClose={() => this.setState({ openNewContractModal: false })}
                                    headerText={"New Contract"}
                                    mode={"New"}
                                    modalName={"Contract"}
                                    performerId={this.state.performerId}
                                    showSeasonId={this.props?.showSeasonId}
                                    toggleRefresh={this.props?.toggleRefresh}
                                    contractFromTalentRecord={true}
                                    talentDetails={this.state.performerDetails}
                                    navigationCallback={(talentDetails) => {
                                        this.props?.navigationCallback(talentDetails)
                                        this.props?.onClose()
                                    }}
                                />
                            )}
                            {this.state.openTalentModal &&
                                <ModalComponent
                                    navFuns={this.functions}
                                    open={this.state.openTalentModal}
                                    handleClose={this.functions.handleHideTalentPopup}
                                    tabList={talentAnimationList}
                                    headerText={"Talent"}
                                    addButtonText={"New Talent"}
                                    objectType={'TALENT'}
                                    dataUrl='talentDetails'
                                    isFromTalentRecord={true}
                                    talentProfileDetails={this.state.talentProfileDetails}
                                    handleNewTalentCreation={this.handleNewTalentCreation}
                                />
                            }
                        </MDBContainer>
                    )
                }
            </div>
        );
    }
}

export default withUserContext(NewTalentRecord);

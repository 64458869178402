import React from "react";
import "./CastList.scss";
import '../Project/Project.scss';
import { withUserContext } from "../../../contexts/UserContext";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import SelectField from "../../components/SharedComponents/SelectField/SelectField";
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import BasicTextArea from '../../components/SharedComponents/BasicTextArea/BasicTextArea';
import ProfilePictureUpload from "../../Common/DefaultModalLayout/ProfilePicture/ProfilePictureUpload";
import { uploadProfileParams } from '../../Common/DefaultModalLayout/ProfilePicture/Config';
import defaultImg from '../../assets/defaultUserImg.png';
import NotificationComponent from '../../../caster-tv-jwp/Common/NotificationComponent/NotificationComponent';
import MessageModal from '../../../caster-tv-jwp/components/SharedComponents/MessageModal';
import MainViewModalComponent from "../../Common/MainViewModalLayout/Modal/Modal";
import moment from 'moment'

class CastList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCastListEditLoader: false,
      isProfileChanged: false,
      showNotes: false,
      isExisitingDealMemoId: true,
      mode: null,
      showCancelModal: false,
      showSuccess: false,
      showFail: false,
      checkRelationalFailure: false,
      editing: false,
      notes: "",
      castListPdf: false,
      isSubmit: false,
      roleStatusOption: [],
      isUnsaved: false,
      deal_memo_id: null,
      downloadedImages: new Set()
    };
  }

  componentDidMount = () => {
   this.getCastListTableData(this.props?.selectedEpisodeId)
    this.getRoleStatusData("lu_role_status", "")
    this.checkMode()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.renderList !== this.state.renderList) {
      this.setState({ isUnsaved: true });
    }
  }

  getCastListTableData = (episodeId) => {
    this.setState({ isCastListEditLoader: true, isSubmit: true })
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterTvJwpEpisodeCastList?episodeId=${episodeId}`,
      this.props?.userContext?.active_tenant?.tenant_id,
      true
    ).then((res) => {
      if (res.data.length > 0) {
        this.setState({
          renderList: res.data[0].cast_list,
          isCastListEditLoader: false,
          isSubmit: false,
        } ,()=>{this.state.renderList.map((item,index)=>{
          this.downloadAndConvertImage(item)
        })
      })
      }
      else {
        this.setState({ renderList: [], isCastListEditLoader: false })
      }
    }).catch((err) => {
      console.log(err, "error")
    })
  }

  checkMode() {
    if (this.state?.isExisitingDealMemoId) {
      this.setState({ mode: "view" },()=>{ this.getCastListTableData(this.props?.selectedEpisodeId)})
    } else if (this.state.showNotes) {
      this.setState({ mode: "edit" })
    } else if (!this.state?.isExisitingDealMemoId && !this.state.showNotes) {
      this.setState({ mode: "new" })
    }
  }

  handleEditClick = () => {
    this.setState({ showNotes: true, mode: "edit" });
    this.getCastListTableData(this.props?.selectedEpisodeId)
  };

  confirmCancelChanges() {
    if (this.state.mode === "new") {
      this.props?.handleTabChangeFun(1)
    } else if (this.state.mode === "edit") {
      this.setState({ mode: "view" }, () => {
        this.setState({ showCancelModal: false })
      })
    }
  }

  handleCancel = () => {
    if (this.state.mode === "new" || this.state.mode === "edit") {
      if (this.state.isUnsaved) {
        this.setState({ showCancelModal: true })
      } else {
        this.props?.handleTabChangeFun(1)
        this.setState({ showCancelModal: false })
      }
    } else {
      this.setState({ mode: "view" })
    }
  }

  notificationComponent = (value, mode = "fail") => {
    switch (mode) {
      case "success":
        return this.setState({ showSuccess: value });
      case "fail":
        return this.setState({ showFail: value });
    }
  };

  handleSubmit = () => {
    this.setState({ isCastListEditLoader: true });
    let payload = { ...this.state.renderList };
    this.setState({ isSubmit: true });
    let data = {};
    const updatedCastList = this.state.renderList.map(row => {
      let firstObj ={
        "deal_memo_deal_points_id" : row.deal_memo_deal_points_id,
        "deal_memo_id" : row.deal_memo_id,
        "role_status_id" : row.role_status_id,
        "additional_terms" : row.additional_terms,
        "billing" : row.billing,
        "notes" : row.notes
      }
      let secondObj = []
      if(row.second_contract.length !==0){
        secondObj = row.second_contract.map(({ deal_memo_deal_points_id, deal_memo_id, role_status_id, additional_terms, billing, notes}) => ({
          deal_memo_deal_points_id,
          deal_memo_id,
          role_status_id,
          additional_terms,
          billing,
          notes
        }))}
          firstObj["second_contract"]=secondObj
          return {
            firstObj
          }
        })
    data = {
      episode_id: payload.episode_id ? payload.episode_id : this.props.selectedEpisodeId,
      project_id: payload.project_id ? payload.project_id : this.props?.project_id,
      season_id: payload.season_id ? payload.season_id : this.props?.season_id,
      cast_list: updatedCastList.map((item)=>item.firstObj)
     
    };
    CasterService.postDataParams(
      Constants.CasterServiceBaseUrl + "/casterTvJwpEpisodeCastList",
      data,
      this.props.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (response.data.error) {
          this.notificationComponent(true, "fail");
        } else {
          this.notificationComponent(true, "success");
          this.setState({ mode: "view", deal_memo_id: response.data[0].deal_memo_id });
          this.getCastListTableData(this.props?.selectedEpisodeId)
          this.getDealDetails(response.data[0].deal_memo_id);
          // this.uploadProfile(payload.deal_memo_id);
        }
        this.setState({ isSubmit: false, isCastListEditLoader: false });
      },
      (err) => {
        this.setState({ isSubmit: false, postInitiated: false });
        this.notificationComponent(true, "fail");
      }
    );
  }

  handleNotesChange = (deal_memo_id, value) => {
    this.setState(prevState => ({
      renderList: prevState.renderList.map(item => {
        if (item.deal_memo_id === deal_memo_id) {
          return { ...item, notes: value };
        }
        let secondObj = []
        if(item.second_contract.length !==0){
          secondObj = item.second_contract.map(obj=>{
            if(obj.deal_memo_id === deal_memo_id){
  
              return {
                ...obj,
                notes: value
              }
            }
            return obj
        }
          )}
        item.second_contract = secondObj
        return item;
      })
    }));
  };
  

  handleResetNotify = () => {
    this.setState({
      showFail: false,
      showSuccess: false,
      checkRelationalFailure: false,
    });
    if (this.state.saveFlag) {
      this.closPopup();
    }
  };

  handleCastListPdf = () => {
    this.setState({ castListPdf: true })
  }

  handleOnchange = (field, value, index, firstItem, firstIndex) => {
    if(field !== "second_role_status_id"){
      const newArray = [...this.state.renderList];
      const item = newArray[index];
      item[field] = value;
      this.setState({ renderList: newArray });
    }else{
      const newArray = [...this.state.renderList];
      const item = newArray[firstIndex];
      const second_item = item.second_contract[index]
      second_item["role_status_id"] = value;
      let rolename = this.state.roleStatusOption.find((item)=>item.value === value).label
      second_item["role_status_name"] = rolename
      this.setState({ renderList: newArray });
    }
  };

  getRoleStatusData = (objectType, searchString) => {
    this.setState({ isSubmit: true })
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterTvJwpLookupDetails?objectType=${objectType}&searchString=${searchString}`,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let filteredList = res.data?.map((item) => ({
          value: item.id,
          label: item.name,
          is_active: item.is_active
        }));
        this.setState({
          roleStatusOption: filteredList,
          isSubmit: false
        });
      })
      .catch((error) => {
        this.setState({ isSubmit: false })
        console.log(error, "error in getRoleStatusData method");
      });
  };

  hideCastListPdfModal = () => {
    this.setState({ castListPdf: false })
  }

  convertBlobToImg = (blob, objectId) => {
    const reader = new FileReader();
    reader.addEventListener('loadend', (e) => {
      const text = e.target.result;
      const updatedDataRows = this.state.renderList.map(row => {
        if (row.deal_memo_id === objectId) {
          return {
            ...row,
            fileName: text
          };
        
        }
        let secondObj = []
        if(row.second_contract.length !==0){
          secondObj = row.second_contract.map(obj=>{
            if(obj.deal_memo_id === objectId){
  
              return {
                ...obj,
                fileName: text
              }
            }
            return obj
        }
      )}
       row.second_contract = secondObj
        return row;
      });

      this.setState({ renderList: updatedDataRows },()=>{
        this.state.renderList?.map(item => {
          this.setState(prevState => ({
            downloadedImages: prevState.downloadedImages.add(item.fileName)
          }));
        })
      });
    });
   
    reader.readAsText(blob);
  };

 

  downloadAndConvertImage = (item) => {
    const { fileName, deal_memo_id } = item;

    if (fileName === `${"Talent".toLowerCase()}_${deal_memo_id}`) {
      return;
    }

    if (this.state.downloadedImages.has(fileName)) {
      return;
    }

    CasterService.downloadFile(fileName)
      .then(response => {
        console.log("File Download Successful: " + response.data);
        this.convertBlobToImg(response.data, deal_memo_id)
       
      })
      .catch(err => {
        console.log("File Download Failed: " + err);
      });
  };

 

  render() {
    let severity = this.state.showFail
      ? "error"
      : this.state.showSuccess
        ? "success"
        : this.state.checkRelationalFailure
          ? "error"
          : "";
    let messageNotification = this.state.showSuccess
      ? "The changes are saved successfully!"
      : this.state.showFail
        ? "Saving changes failed!"
        : this.state.checkRelationalFailure
          ? "You can not delete this Record because it is being used"
          : "";
    return (
      <div className="CastList_Container">
        <NotificationComponent
          open={
            this.state.showSuccess ||
            this.state.showFail ||
            this.state.checkRelationalFailure
          }
          message={messageNotification}
          severity={severity}
          handleResetNotify={this.handleResetNotify.bind(this)}
        />

        <MDBRow>
          <MDBCol md={7}></MDBCol>
          <MDBCol className="btn-display">
            {this.state.mode === "view" ?
              <div >
                <BasicButton
                  variant="contained"
                  type="inline"
                  icon={"pen"}
                  text="Edit Cast List"
                  onClick={this.handleEditClick}
                />
              </div> : null}
            {this.state.mode === "view" ?
              <div className="mleft2">
                <BasicButton
                  variant="outlined"
                  type="inline"
                  icon={"file"}
                  text="Cast List PDF"
                  onClick={this.handleCastListPdf}
                />
              </div> :
              <>
                {this.state.isFetchingDealMemoDetails ? " " :
                  <>

                    <MDBRow>
                      <MDBCol className="btn-display1">
                        <div >
                          <BasicButton
                            className="mleft"
                            variant="outlined"
                            type="inline"
                            icon={"times"}
                            text="Cancel"
                            onClick={() => this.handleCancel()}
                          />
                        </div>
                        <div className="mleft2">
                          <BasicButton
                            variant="contained"
                            type="inline"
                            icon={"save"}
                            text={
                              this.state.isSubmit ? (
                                <CircularProgress color="inherit" size={18} />
                              ) : (
                                "Save Cast List"
                              )
                            }
                            width="100%"
                            onClick={() => this.handleSubmit()}
                          />
                        </div>
                        <div className="mleft2">
                          <BasicButton
                            variant="outlined"
                            type="inline"
                            icon={"file"}
                            text="Cast List PDF"
                            disabled={true}
                            width="100%"
                          />
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </>
                }
              </>
            }
            {this.state.showCancelModal &&
              <MessageModal
                title={"Unsaved Changes"}
                open={this.state.showCancelModal}
                message={"You have unsaved changes to this Cast List.Do you want to navigate away without saving these changes?"}
                primaryButtonText={"Yes"}
                secondaryButtonText={"No"}
                onConfirm={() => this.confirmCancelChanges()}
                handleClose={(e) => this.setState({ showCancelModal: false })}
              />
            }
          </MDBCol>
        </MDBRow>

        <MDBRow id={"castlist-table"} className="mt">
          {/* <CastListEdit selectedEpisodeId={this.props?.selectedEpisodeId} /> */}
          <div >
            {this.state.isCastListEditLoader ? (
              <div style={{ textAlign: "center" }}>
                <CircularProgress size={34} />
              </div>
            ) :
              <div class="table-container">
                <div class="table-row header">
                  <div class="table-cell"><strong>Role</strong></div>
                  <div class="table-cell actor"><strong>Actor</strong></div>
                  <div class="table-cell representation"><strong>Representation</strong></div>
                  <div class="table-cell"><strong>Deal Points</strong></div>
                  <div class="table-cell"><strong>Notes</strong></div>
                </div>

                {!this.state.renderList || this.state.renderList === [] ?
                  <div class="table-container">
                    <div class="table-row header">
                      <div class="table-cell"><strong>Role</strong></div>
                      <div class="table-cell actor"><strong>Actor</strong></div>
                      <div class="table-cell representation"><strong>Representation</strong></div>
                      <div class="table-cell"><strong>Deal Points</strong></div>
                      <div class="table-cell"><strong>Notes</strong></div>
                    </div>
                    <div className="text-align-center">No Result</div>
                  </div> :
                  this.state.renderList.map((item, index) => (
                    <div className="table-color">
                    <div key={item.deal_memo_id}  className="display-flext-cast_list">

                      <div class="table-cell role">
                        <div class="itemrole"><strong>{item.role}</strong></div><br />
                        <div class="itemrole"><strong>Role#: {item.role_id || "-"}</strong></div><br />
                        <div class="itemrole">
                          {item.fileName === `${"Talent".toLowerCase()}_${item.deal_memo_id}` ? (
                            <div>
                              <img
                                src={defaultImg}
                                alt=""
                                width="100px"
                                height="100px"
                              />
                            </div>
                          ) : (
                            <div>
                              
                              {item.fileName !== null ? (
                                <div>
                                  {this.state.downloadedImages.has(item.fileName) ? (
                                    <>
                                    <img src={item.fileName} alt="" width="100px" height="100px" />
                                    </>
                                  ) : (
                                    <>
                                    {/* <CircularProgress /> */}
                                    </>
                                  )}
                                </div>
                              ) : null}
                            </div>
                          )}
                        </div><br />
                        <div class="itemrole"><strong>Role Status</strong></div><br />
                        {this.state.mode !== "view" ? (
                          <div class="dropdown">
                            <SelectField
                              value={item.role_status_id}
                              options={this.state.roleStatusOption || []}
                              onChange={(e) => {
                                this.handleOnchange("role_status_id", e.target.value, index);
                              }}
                            />
                          </div>
                        ) : (
                          <div class="itemrole">
                            {item.role_status_name}
                          </div>
                        )}
                      </div>

                      <td className="table-cell actor">
                        <div>
                          <p class="talent-description"><strong>{item.talent_name}</strong>&nbsp;&nbsp;<div className="minor minor-spacing">{item.is_minor ? 'Minor' : ''}</div></p>
                          <p>{item?.preferred_pronouns || "-"}</p>
                          <div className="address"> {item?.address && (
                                  <>
                                    {item.address.split("\n").map((line, index) => (
                                      <React.Fragment key={index}>
                                        {line}
                                        <br />
                                      </React.Fragment>
                                    ))}
                                  </>
                                )}</div>
                          <p>{item?.cell_no || "-"}</p>
                          <p>{item?.talent_email || "-"}</p>
                          <p><b>{item.is_minor ? 'Minor Info' : '-'}</b></p>
                          {item.is_minor &&
                            (<><p>DOB&nbsp;:&nbsp;{item.dob === null || item.dob === "0000-00-00" ? moment().format("MM/DD/YYYY"): moment(item.dob).format("MM/DD/YYYY") }</p>
                              <p>Guardian &nbsp;:&nbsp; {item.guardian_name|| "-"}</p>
                              <p>{item.guardian_phone|| "-"}</p>
                              <p>{item.guardian_email|| "-"}</p></>)}
                        </div>
                      </td>
                      <td className="table-cell ">
                        <div >
                          <p><strong>Agent&nbsp; :&nbsp; {item.agent|| "-"}</strong></p>
                          <br />
                          <p>{item.agency|| "-"}</p>
                          <p>O&nbsp;:&nbsp;{item.rep_office_number}</p>
                          <p>C&nbsp;:&nbsp;{item.rep_cell_number}</p>
                          <p>{item.rep_email|| "-"}</p>
                          <p><strong>Manager&nbsp;:&nbsp;{item.manager}</strong></p>
                          <p>{item.management_company|| "-"}</p>
                          <p>O&nbsp;:&nbsp;{item.mc_office_number}</p>
                          <p>C&nbsp;:&nbsp;{item.mc_cell_number}</p>
                          <p>{item.mc_email|| "-"}</p>
                        </div>
                      </td>
                      <td className="table-cell">
                        <div >
                          <p>Start Date&nbsp;:&nbsp;{item.start_date === null || item.start_date === "0000-00-00" ? '' : moment(item.start_date).format("MM/DD/YYYY")}</p>
                            <p>Rate&nbsp;:&nbsp;{
                              <strong>{(item?.rate ? "$" +  item?.rate.toLocaleString('en-US') : "")}{item?.is_10_percentage === 1 ? "(+10%)" : " "}{item?.per && "/"}{item?.per}{" "}{item?.per_period && (item?.per_period === 1 ? "Day(s)" : "Weeks")}{" "}{item?.guarantee ? `,${item?.guarantee}`
                                + `${" Guarantee"}` : " "}</strong>}</p>
                            {item?.deal_id_OLD && <p>Salary&nbsp;:&nbsp;{item.salary}</p>}
                          <p>
                            {item.contract_no === 1 && (
                              <span>DROP/PICKUP on or about &nbsp;{moment(item.firm_date).format('MM/DD/YYYY')}</span>
                            )}
                          </p>

                          {/* <p>{item.guarantee ? `${item.guarantee}${" "}${item.per_period === 1 ? "Days" : "Weeks"}${" "}Guarantee`: " "}</p> */}
                        
                          <p>Billing&nbsp;:&nbsp;<strong>{item.billing}</strong></p>
                          <p>To Be Billed As&nbsp;:&nbsp;<strong>{item.to_be_billed_as}</strong></p>
                          <p>Dressing &nbsp;:&nbsp;<strong>{item.dressing}</strong></p>
                          <p>Travel/Accomodations&nbsp;:&nbsp;<strong>{item.travel_accommodations}</strong></p>
                          <p>Additional Terms&nbsp;:&nbsp;<strong>{item.additional_terms}</strong></p>
                          <p style={{  fontWeight: "bold" ,color: "black"}}>{item.second_contract.length > 0 ? "Pickup Contract" : " "}</p>
                        </div>
                      </td>
                      {this.state.mode !== "view" ? (
                        <div class="table-cell notes">
                          <BasicTextArea
                            type="text"
                            deal_memo_id={item.deal_memo_id}
                            value={item.notes}
                            maxLength={1500}
                            onChange={(e) => this.handleNotesChange(item.deal_memo_id, e.target.value)}
                            onBlur={() => this.setState({ editing: false })}
                          />

                        </div>
                      ) : (
                        <div class="table-cell notes">
                          {item.notes}
                        </div>
                      )}
                    </div>
                    {item.second_contract.map((obj,index2)=>(
                    <div key={obj.deal_memo_id} className="display-flext-cast_list">

                      <div class="table-cell role">
                        {/* <div class="itemrole"><strong>{obj.role}</strong></div><br />
                        <div class="itemrole">
                          {obj.fileName === `${"Talent".toLowerCase()}_${obj.deal_memo_id}` ? (
                            <div>
                              <img
                                src={defaultImg}
                                alt=""
                                width="100px"
                                height="100px"
                              />
                            </div>
                          ) : (
                            <div>
                              
                              {this.downloadAndConvertImage(obj)}
                              {obj.fileName !== null ? (
                                <div>
                                  {this.state.downloadedImages.has(obj.fileName) ? (
                                    <>
                                    <img src={obj.fileName} alt="" width="100px" height="100px" />
                                    </>
                                  ) : (
                                    <CircularProgress />
                                  )}
                                </div>
                              ) : null}
                            </div>
                          )}
                        </div><br />
                        <div class="itemrole"><strong>Role Status</strong></div><br />
                        {this.state.mode !== "view" ? (
                          <div class="dropdown">
                            <SelectField
                              value={obj.role_status_id}
                              options={this.state.roleStatusOption || []}
                              onChange={(e) => {
                                this.handleOnchange("second_role_status_id", e.target.value, index2,item,index);
                              }}
                            />
                          </div>
                        ) : (
                          <div class="itemrole">
                            {obj.role_status_name}
                          </div>
                        )} */}
                      </div>

                      <td className="table-cell actor">
                        {/* <div>
                          <p class="talent-description"><strong>{obj.talent_name}</strong>&nbsp;&nbsp;<div className="minor minor-spacing">{obj.is_minor ? 'Minor' : ''}</div></p>
                          <p>{obj?.preferred_pronouns || "-"}</p>
                          <div className="address"> {obj?.address && (
                                  <>
                                    {obj.address.split("\n").map((line, index) => (
                                      <React.Fragment key={index}>
                                        {line}
                                        <br />
                                      </React.Fragment>
                                    ))}
                                  </>
                                )}</div>
                          <p>{obj?.cell_no || "-"}</p>
                          <p>{obj?.talent_email || "-"}</p>
                          <p><b>{obj.is_minor ? 'Minor Info' : '-'}</b></p>
                          {obj.is_minor &&
                            (<><p>DOB&nbsp;:&nbsp;{obj.dob === null || obj.dob === "0000-00-00" ? moment().format("MM/DD/YYYY"): moment(obj.dob).format("MM/DD/YYYY") }</p>
                              <p>Guardian &nbsp;:&nbsp; {obj.guardian_name|| "-"}</p>
                              <p>{obj.guardian_phone|| "-"}</p>
                              <p>{obj.guardian_email|| "-"}</p></>)}
                        </div> */}
                      </td>
                      <td className="table-cell ">
                        {/* <div >
                          <p><strong>Agent&nbsp; :&nbsp; {obj.agent|| "-"}</strong></p>
                          <br />
                          <p>{obj.agency|| "-"}</p>
                          <p>O&nbsp;:&nbsp;{obj.rep_office_number}</p>
                          <p>C&nbsp;:&nbsp;{obj.rep_cell_number}</p>
                          <p>{obj.rep_email|| "-"}</p>
                          <p><strong>Manager&nbsp;:&nbsp;{obj.manager}</strong></p>
                          <p>{obj.management_company|| "-"}</p>
                          <p>O&nbsp;:&nbsp;{obj.mc_office_number}</p>
                          <p>C&nbsp;:&nbsp;{obj.mc_cell_number}</p>
                          <p>{obj.mc_email|| "-"}</p>
                        </div> */}
                      </td>
                      <td className="col-md-3 table-cell">
                        <div >
                          <p>Start Date&nbsp;:&nbsp;{obj.start_date === null || obj.start_date === "0000-00-00" ? '' : moment(obj.start_date).format("MM/DD/YYYY")}</p>
                          <p>Rate&nbsp;:&nbsp;{ <strong>
                            { (obj?.rate ? "$" + obj?.rate.toLocaleString('en-US') :"")}{obj?.is_10_percentage=== 1? "(+10%)":" "}{obj?.per && "/"}{obj?.per}{" "}
                            {obj?.per_period && (obj?.per_period === 1 ? "Day(s)" : "Weeks")}{" "}{obj?.guarantee ? `,${obj?.guarantee +`${" Guarantee"}`}` : " "}
                            </strong>}</p>
                          {obj?.deal_id_OLD &&<p>Salary&nbsp;:&nbsp;{obj.salary}</p>}
                          <p>
                            {obj.contract_no === 1 && (
                              <span>DROP/PICKUP on or about &nbsp;{moment(obj.firm_date).format('MM/DD/YYYY')}</span>
                            )}
                          </p>

                          {/* <p>{obj.guarantee ? `${obj.guarantee}${" "}${obj.per_period === 1 ? "Days" : "Weeks"}${" "}Guarantee`: " "}</p> */}
                        
                          <p>Billing&nbsp;:&nbsp;<strong>{obj.billing}</strong></p>
                          <p>To Be Billed As&nbsp;:&nbsp;<strong>{obj.to_be_billed_as}</strong></p>
                          <p>Dressing &nbsp;:&nbsp;<strong>{obj.dressing}</strong></p>
                          <p>Travel/Accomodations&nbsp;:&nbsp;<strong>{obj.travel_accommodations}</strong></p>
                          <p>Additional Terms&nbsp;:&nbsp;<strong>{obj.additional_terms}</strong></p>
                          <p style={{ fontWeight: "bold" ,color: "black"}}>This is pickup contract {obj.contract_no} of {item.drop_pickup_total}</p>
                        </div>
                      </td>
                      {this.state.mode !== "view" ? (
                        <div class="table-cell notes">
                          <BasicTextArea
                            type="text"
                            deal_memo_id={obj.deal_memo_id}
                            value={obj.notes}
                            maxLength={1500}
                            onChange={(e) => this.handleNotesChange(obj.deal_memo_id, e.target.value)}
                            onBlur={() => this.setState({ editing: false })}
                          />

                        </div>
                      ) : (
                        <div class="table-cell notes">
                          {obj.notes}
                        </div>
                      )}
                    </div>))}
                   </div>
                  ))}
              </div>
            }
          </div>
        </MDBRow>
        {this.state.castListPdf && (
          <MainViewModalComponent
            open={this.state.castListPdf}
            handleClose={this.hideCastListPdfModal}
            headerText={"CastList PDF Options"}
            mode={" "}
            modalName={"CastList PDF"}
            renderList={this.state?.renderList}
            selectedEpisodeId={this.props?.selectedEpisodeId}
          />
        )}
      </div>
    );
  }
}
export default withUserContext(CastList);
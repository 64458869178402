import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import ProductionSeasonEpisode from './ProductionSeasonEpisode';
import DateField from "../../../components/SharedComponents/DateField/DateField";
import { genReportName } from "../../../Common/Helper"
import ReportButton from './ReportButton';
import { withUserContext } from '../../../../contexts/UserContext';

class EPCoversheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            work_date: null,
            selectProduction: null,
            selectSeason: null,
            selectEpisode: null,
            postInitiated: false,
        }
    }
  
    onChangeCallBack = (field, val) => {
        this.setState({
            [field]: val,
            postInitiated : false
        });
    }

    onClickGenratePdf() {
        this.setState({ postInitiated: true});       
        let { work_date, selectSeason, selectEpisode ,selectProduction } = this.state;
        let production_id = selectProduction?.value;
        let ps_id = selectSeason?.value;
        let episode_id = selectEpisode?.value;
        if (!work_date || !production_id || !ps_id || (this.props.userContext?.active_tenant?.tenant_name?.toLowerCase() === 'tv animation casting' ? !episode_id : false)) {
            return;
        }
        let payload = {
            contract_start_date: work_date,
            contract_end_date:null,
            work_date,
            production_id,
            ps_id,
            episode_id
        }
        // let reportName = this.props?.reportName === "rptAcPrintShow" ? this.props?.reportsToDownload : this.props?.reportName;
        // let allDownLoadReports = []
        // if(this.props?.reportName === "rptAcPrintShow"){
        //     let reportsName = this.props?.reportsToDownload.split(",");
        //     let reportsToDownLoadName =  this.props?.reportDownloadName.split(",");
        //         reportsName.map((key,i)=>{
        //             let obj={
        //                     reportName:reportsToDownLoadName[i],
        //                     production:selectProduction?.label,
        //                     season:selectSeason?.label,
        //                     episode:selectEpisode?.label,
        //                     performer:"",
        //                 }
        //                 let reportNameFinal = genReportName(obj)
        //                 allDownLoadReports.push(reportNameFinal)
        //         })
        // }
        // else{
        //     let obj={
        //         reportName:this.props?.reportDownloadName,
        //         production:this.state?.selectProduction?.label,
        //         season:selectSeason?.label,
        //         episode:selectEpisode?.label,
        //         performer:"",
        //     }
        //     allDownLoadReports = genReportName(obj)
        // }
        let obj={
                reportName:this.props?.reportsToDownLoadName,
                production:this.state?.selectProduction?.label,
                season:selectSeason?.label,
                episode:selectEpisode?.label,
                performer:"",
        }
        let reportNameFinal = genReportName(obj)
        this.props.generateReport(this.props?.reportName, payload, reportNameFinal)
        this.setState({ postInitiated: false })
    }
    render() {
        return (
            <div>
                <MDBContainer className="reportContainer Worksheet InterviewList1">
                    <MDBRow>
                        <BasicLabel text={"Options"} />
                    </MDBRow>
                    <MDBRow>
                        <ProductionSeasonEpisode
                            productionReq={true}
                            seasonReq={true}
                            episodeReq={this.props.userContext?.active_tenant?.tenant_name?.toLowerCase() === 'tv animation casting'}
                            postInitiated={this.state.postInitiated}
                            onChangeCallBack={this.onChangeCallBack} />
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={6}>
                            <DateField
                                id="selectWorkDate"
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                                value={this.state.work_date || ""}
                                label={"Select Work Date"}
                                onChange={(e) => this.onChangeCallBack('work_date', e.target.value ? e.target.value : null)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <ReportButton
                        onClickGenratePdf={this.onClickGenratePdf.bind(this)}
                        handleClose={this.props?.handleClose}
                    />
                </MDBContainer>
            </div>
        )
    }
}

export default withUserContext(EPCoversheet)

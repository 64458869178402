// export const getFormattedDate = (date, dateField = false) => {
//     if (date) {
//         let formattedDate = new Date(date?.replaceAll('-','/'));
//         let year = formattedDate.getFullYear();
//         let month = (1 + formattedDate.getMonth()).toString().padStart(2, '0');
//         let day = formattedDate.getDate().toString().padStart(2, '0');
//         if (dateField)
//             return year + '-' + month + '-' + day;
//         else
//             return month + '/' + day + '/' + year;
//     }
// }
export const getFormattedDateTableFilter = (date, dateField = false) => {
    if (date) {
        let formattedDate = new Date(date?.replaceAll('-','/'));
        let year = formattedDate.getFullYear();
        let month = (1 + formattedDate.getMonth()).toString().padStart(2, '0');
        let day = formattedDate.getDate().toString().padStart(2, '0');
        if (dateField)
            return year + '-' + month + '-' + day;
        else
            return month + '/' + day + '/' + year;
    }
}
export const getFormattedDate = (date, dateField = false) => {
    if (date) {
        // let formattedDate = new Date(date);
        // date = new Date(date).toISOString().slice(0, 10);      
        date = date?.toString().includes('-') ? (date?.replaceAll('-', '/')) : date;
        //date = date?.replaceAll('-', '/');
        let formattedDate = new Date(date)
        let year = formattedDate.getFullYear();
        let month = (1 + formattedDate.getMonth()).toString().padStart(2, '0');
        let day = formattedDate.getDate().toString().padStart(2, '0');     
        if (dateField)
            return year + '-' + month + '-' + day;
        else
            return month + '/' + day + '/' + year;
    }
}
export const getAge = (currentDate) => {
    if (currentDate) {
        var today = new Date();
        var birthDate = new Date(currentDate);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
}

export const getAddress = (field, item, value, addressConfig) => {
    item[field] = value;
    let a = value?.split('\n') || [];
    item[addressConfig.address1] = !(a[0]) ? null : a[0];
    item[addressConfig.address2] = !(a[1]) ? null : a[1];
    let temp = '';
    for (var i = 2; i < a.length; i++) {
        temp += a[i];
    }
    item[addressConfig.address3] = !temp ? null : temp;
    return item;
}

export const getFormattedTime = (date) => {
    let formattedDate = new Date(date);
    let hours = formattedDate.getHours();
    var ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12 || 12;
    hours = hours < 10 ? '0' + hours : hours;
    let mins = formattedDate.getMinutes();
    mins = mins < 10 ? '0' + mins : mins;
    return hours + ':' + mins + " " + ampm;
}

export const getDateAndTime = (dateEntry) => {
    let date = getFormattedDate(dateEntry.split('T')[0]);
    let time = getFormattedTime(dateEntry);
    return date + ", " + time;
}

export const validateEmail = (email) => {
    var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //var emailRegex = /^[A-Za-z0-9]+([\.-_]?[A-Za-z0-9]+)*@[A-Za-z0-9]+([\.-]?[A-Za-z0-9]+)*(\.[A-Za-z]{2,3})+$/;
    return emailRegex.test(email);
}

export const maskSsn = (ssn) => {
    return ssn ? `xxx-xxx-${ssn?.substr(ssn?.length - 4)}` : ssn;
}

export const downloadUrl = (url, reportName) => {
    if (url) {
        // reportName = reportName.replaceAll('.', '')
        fetch(url).then(response => response.blob()).then(blob => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', reportName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
    }
}

// export const validatePhone = (phone) => {
//     let phoneRegex = /^[0-9\-\(\)+]+$/;
//     return phoneRegex.test(phone);
// }

export const validatePlusCharacter = (text) => {
    let charRegex = /^[+]/;
    return charRegex.test(text);
}

export const validateUSNumber = (text) => {
    let charRegex = /\(\d{3}\) \d{3}-\d{4,10}$/;
    return charRegex.test(text);
}

export const validatePhoneNumber = (text) => {
    let charRegex = /^\d{1,20}$/;
    return charRegex.test(text);
}


export const validateIntlNumber = (text) => {
    let charRegex = /\+\d{1,20}$/;
    return charRegex.test(text);
}

export const validateAllPhone = (phone) => {
    let intlLength = 20;
    // let localLenghth = 14;
    let validatePhone = validatePlusCharacter(phone) ?
        validateIntlNumber(phone) && phone.length <= intlLength :
        (validateUSNumber(phone) || validatePhoneNumber(phone)) && phone.length <= intlLength
    return validatePhone;
}

export const formatPhoneNumbers = (phone) => {
    if (phone.length >= 10 && validatePhoneNumber(phone)) {
        return '(' + phone.substr(0, 3) + ')' + ' ' + phone.substr(3, 3) + '-' + phone.substr(6, 10);
    } else {
        return phone
    }
}

export const validateSSNNum = (text) => {
    let charRegex = /^\d{1,11}$/;
    console.log('validateSSNNum', charRegex.test(text))
    return charRegex.test(text);
}

export const formatSSNNum = (text) => {
    if (text.length >= 9 && validateSSNNum(text)) {
        return text.substr(0, 3) + '-' + text.substr(3, 2) + '-' + text.substr(5, 4);
    } else {
        return text
    }
};

export const checkSSNNum = (text) => {
    let charRegex = /^\d{3}-\d{2}-\d{4}$/;
    console.log('checkSSN', charRegex.test(text))
    return charRegex.test(text);
}

export const validateLoanoutEIDNumber = (text) => {
    let charRegex = /^\d{1,11}$/;
    console.log('validateloanouteidnum', charRegex.test(text))
    return charRegex.test(text);
}

export const formatLoanoutEIDNum = (text) => {
    if (text.length >= 10 && validateLoanoutEIDNumber(text)) {
        return text.substr(0, 2) + '-' + text.substr(2, 8);
    } else {
        return text
    }
};

export const checkLoanoutEIDNum = (text) => {
    let charRegex = /^\d{2}-\d{8}$/;
    console.log('check', charRegex.test(text))
    return charRegex.test(text);
}

export const validateFederalNumber = (text) => {
    let charRegex = /^\d{1,10}$/;
    return charRegex.test(text);
}

export const formatEIDNum = (text) => {
    if (text.length >= 9 && validateFederalNumber(text)) {
        return text.substr(0, 2) + '-' + text.substr(2, 7);
    } else {
        return text
    }
};

export const checkFederalIDNum = (text) => {
    let charRegex = /^\d{2}-\d{7}$/;
    console.log('check', charRegex.test(text))
    return charRegex.test(text);
}

export const validateZipCode = (text) => {
    let charRegex = /^\d{1,10}$/;
    console.log('validateeidnum', charRegex.test(text))
    return charRegex.test(text);
}

export const formatZipCode = (zip) => {
    if (zip.length >= 9 && validateZipCode(zip)) {
        console.log("zip", zip.substr(0, 5) + '-' + zip.substr(5, 10))
        return zip.substr(0, 5) + '-' + zip.substr(5, 4);
    } else {
        return zip
    }
}

export const checkZipCode = (text) => {
    let charRegex = /^\d{5}-\d{4}$/;
    console.log('check', charRegex.test(text))
    return charRegex.test(text);
}

export const validateCharctersOnly = (text) => {
    let regex = /^[a-zA-Z!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
    return regex.test(text);
}

export const validateCharctersSpaceOnly = (text) => {
    let regex = /^[a-zA-Z!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
    return regex.test(text);
}

export const validateNumbersOnly = (text) => {
    let regex = /^[0-9]*$/;
    return regex.test(text);
}

export const validatePlusBracketHypenNumbersOnly = (text) => {
    let regex = /^[+()-\d\s]+$/;
    return regex.test(text);
}

export const validateCharNumOnly = (text) => {
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
    return regex.test(text);
}

export const validateCharNumHyphenSlashOnly = (text) => {
    let regex = /^[a-zA-Z0-9-/!@#$&()\\-`.+,/\ ]*$/i;
    return regex.test(text);
}

export const validateCharNumWithHyphenSlash = (text) => {
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
    return regex.test(text);
}

export const validateCharNumWithHyphen = (text) => {
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
    return regex.test(text);
}

export const validateCharNumWithHyphenSpace = (text) => {
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
    return text ? regex.test(text) : true;
}

export const validateCharNumCommaDotWithSpace = (text) => {
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
    return regex.test(text);
}

export const validateCharNumOnlyWithSpace = (text) => {
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
    return text ? regex.test(text) : true;
}

export const validateAlpVarCharNumOnlyWithSpace = (text) => {
    let regex = /^[a-zA-Z0-9ÀÁĄ́ÂÃÄB́B̀ĆÉÈÍÌǴḰḾŃǸÓÒṔŔŚS̀ÚẂÝŹ!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
    return text ? regex.test(text) : true;
}

export const validateAlpVarCharOnlyWithSpace = (text) => {
    let regex = /^[a-zA-ZÀÁĄ́ÂÃÄB́B̀ĆÉÈÍÌǴḰḾŃǸÓÒṔŔŚS̀ÚẂÝŹ!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
    return text ? regex.test(text) : true;
}

export const validateNumPlusOnly = (text) => {
    let regex = /^[0-9+]*$/;
    return regex.test(text);
}

export const validateNumDotOnly = (text) => {
    let regex = /^[0-9.]*$/;
    return regex.test(text);
}

export const validateNumDotUptoTwoPlacesOnly = (text) => {
    let regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    return regex.test(text);
}

export const validateCharNumHashOnly = (text) => {
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
    return regex.test(text);
}

export const validateCharNumRateOnly = (text) => {
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
    return regex.test(text);
}

export const validateCharNumHashSpaceOnly = (text) => {
    // let regex = /^[a-zA-Z0-9#]+\s?[a-zA-Z0-9#]*$/i;
    let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]/i;
    return regex.test(text);
}

export const validateNumHyphenOnly = (text) => {
    let regex = /^[0-9-]*$/;
    return regex.test(text);
}

export const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

export const genReportName = (obj) => {
    let { reportName, production, season, episode, performer } = obj;
    let name = `${reportName ? reportName + "_" : ""}${production ? production + "_" : ""}${season ? season + "_" : ""}${episode ? episode + "_" : ""}${performer ? performer : ""}`
    name = name.trim().replaceAll(" ", "_");
    return name.charAt(name.length - 1) === "_" ? name.slice(0, -1) : name;
}

export const checkValidEditorText = (value) => {
    if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
        return false
    } else {
        return true
    }
}


export const ssnFormat = (text) => {
    let charRegex = /\d{3}-\d{2}-\d{4}$/;
    return charRegex.test(text);
}

export const formatRate = (value) => {
    value = value?.toFixed(2);
    let rate = value?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    return rate
}

export const extractFilenameFromUrl = (url) => {
    const path = new URL(url).pathname;
    const filename = path.substring(path.lastIndexOf('/') + 1);
    return filename;
}

export const numberToWords = (number) => {
    const numbersToWords = {
        0: "zero",
        1: "one",
        2: "two",
        3: "three",
        4: "four",
        5: "five",
        6: "six",
        7: "seven",
        8: "eight",
        9: "nine",
        10: "ten",
        11: "eleven",
        12: "twelve",
        13: "thirteen",
        14: "fourteen",
        15: "fifteen",
        16: "sixteen",
        17: "seventeen",
        18: "eighteen",
        19: "nineteen",
        20: "twenty",
        30: "thirty",
        40: "forty",
        50: "fifty",
        60: "sixty",
        70: "seventy",
        80: "eighty",
        90: "ninety",
      };
    
        let words = "";
        if (number > 0) {
            if (number < 20) words += numbersToWords[number];
            else {
            words += numbersToWords[Math.floor(number / 10) * 10];
            if (number % 10 > 0) {
              words += "-" + numbersToWords[number % 10];
            }}
        }
    return words;  
};

import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../SharedComponents/SelectField/SelectField';
import ActionButtons from '../SharedComponents/ActionButtons/BasicActionButtons';
import BasicCheckBox from '../SharedComponents/BasicCheckbox/BasicCheckbox';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
// import './LocationComponent.scss';
import { validateCharNumHashSpaceOnly, validateCharNumCommaDotWithSpace, validateCharctersOnly, validateAllPhone, validateNumbersOnly, validateCharctersSpaceOnly, formatPhoneNumbers, formatZipCode, checkZipCode } from '../../Common/Helper';
import BasicTextArea from '../SharedComponents/BasicTextArea/BasicTextArea';
import messages from '../../Common/Messages.json';
import sizeLimits from '../../Common/SizeLimits.json';
import { withUserContext } from '../../../contexts/UserContext';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import { Popover } from '@material-ui/core';
// import messages from '../../Common/Messages.json';
import AddCircleIcon from '@material-ui/icons/AddCircle';
// import PhoneComponent from '../DefaultPhoneComponent/PhoneComponent';
import './ProdCompany.scss';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import CircularProgress from '@material-ui/core/CircularProgress';

class ProdCompanyAddress extends Component {

    constructor(props) {
        super(props);
        this.state = {
            production_companies: props?.production_companies,
            addressTypes: [],
            isIntlChecked: false,
            selectedCountryValue: 0,
            selectedStateValue: '',
            intlCountryStatus: false,
            intlStateStatus: false,
            address: props?.address,
            locationConfig: this.props?.locationConfig,
            phoneTypes: [],
            initialObjectConfig: props?.initialObjectConfig,
            postInitiated: false,
            isSubmit: false,
            selectedProdCompToEdit: null,
            loading: false
        }
    }

    // componentWillReceiveProps(nextProps) {
    //     this.setState({
    //         production_companies: nextProps.production_companies
    //     });
    // }   

    componentDidMount() {
        this.getaddressTypes();
        this.getCountryList();
        this.getStateList();
        this.getPhoneTypes();
        this.formatDetails();
        //this.props?.getProjectDetails("fromEditProdCompany");
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.isSubmit !== this.props?.isSubmit) {
            this.setState({ isSubmit: this.props.isSubmit })
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.production_companies !== state.production_companies) {
            return {
                production_companies: props.production_companies
            }
        }
        return null
    }

    getaddressTypes = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=Address`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value }));
                this.setState({ addressTypes: formattedList },
                    () => {
                        // if (this.props.list[this.props.addressConfig?.addressTypeId] === null) {
                        //     this.props.editCallback('address_type_id', this.state.addressTypes[0].value, this.props.list, 'address_list', 'Check');
                        //     this.props.editCallback('address_type', this.state.addressTypes[0].label, this.props.list, 'address_list', 'Check');
                        // }
                    });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }

    getPhoneTypes = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=Phone`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.lookup_value, label: item.lookup_value }));
                this.setState({ phoneTypes: formattedList },
                    () => {
                        // if (this.props.list[this.props.addressConfig?.addressTypeId] === null) {
                        //     this.props.editCallback('address_type_id', this.state.addressTypes[0].value, this.props.list, 'address_list', 'Check');
                        //     this.props.editCallback('address_type', this.state.addressTypes[0].label, this.props.list, 'address_list', 'Check');
                        // }
                    });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }

    getCountryList = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=Country`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.lookup_value, text: item.lookup_value }));
                // let defaultCountry = formattedList.filter(item => {
                //     return item.label === "United States" || item.label === "US" || item.label === "USA"
                // })

                this.setState({
                    countryList: formattedList,
                    //  selectedCountryValue: defaultCountry[0]?.value
                });
                // this.setState((prevState) => ({
                //     initialObjectConfig: {
                //         ...prevState?.initialObjectConfig,
                //         ["country"]: this.state.initialObjectConfig?.country
                //         //  ? this.state.initialObjectConfig?.country : defaultCountry[0]?.value
                //     },
                //     countryList: formattedList
                // }))

            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }
    getStateList = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=State`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.lookup_value, text: item.lookup_value }));
                this.setState({ stateList: formattedList });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }

    saveLocation = () => {
        this.setState({ postInitiated: true, isSubmit: true })

        let initialLocationConfig = JSON.parse(JSON.stringify({ ...this.state.selectedProdCompToEdit }));
        initialLocationConfig = { ...initialLocationConfig, country: initialLocationConfig?.country?.value };
        initialLocationConfig?.country === 'United States' || initialLocationConfig?.country === 'USA' || initialLocationConfig?.country === 'US' ? typeof (initialLocationConfig?.state) === 'object' ? initialLocationConfig = { ...initialLocationConfig, state: initialLocationConfig?.state?.value } : null : null
        if ((initialLocationConfig?.zip?.length > 0 ? (initialLocationConfig?.country === 'United States' || initialLocationConfig?.country === 'USA' || initialLocationConfig?.country === 'US' ? (initialLocationConfig?.zip.length <= sizeLimits?.intlZipCharacterLimit && (initialLocationConfig?.zip?.length == sizeLimits?.intlZipCharacterLimit ? checkZipCode(initialLocationConfig?.zip) : true)) : true) : true)) {
            this.props?.onLocationSave(initialLocationConfig);
            this.setState({ locationConfig: this.state.locationConfig, postInitiated: false }, () => {
                // this.props?.popOverClose()
            })
        }
    }

    handleGeneralEdit = (field, value) => {

        let newValue = value;
        if (typeof value === "boolean") {
            newValue = value ? 1 : 0
        }
        this.setState(prevState => ({
            selectedProdCompToEdit: {
                ...prevState.selectedProdCompToEdit,
                [field]: newValue,
                ...(field == 'country') && { 'state': null }
            },
            postInitiated: false
        }), () => {
            if (field == 'state' && (this.state.selectedProdCompToEdit?.state?.value)) {
                let defaultCountry = this.state.countryList.find(item => {
                    return (item.text === "United States" || item.text === "USA" || item.text === "US")
                })
                this.setState((prevState) => ({
                    selectedProdCompToEdit: {
                        ...prevState?.selectedProdCompToEdit,
                        ['country']: defaultCountry,
                        // ...(field == 'country') && { 'state': null }
                    }
                }))
            }
        })
    }

    // addRemoveObject(list, value, field, index) {
    //     let objectList = [...this.state?.locationConfig?.[list]];
    //     let containsPrimary = objectList?.find(item => item.is_primary === 1 && item.is_delete === 0)
    //     if (!containsPrimary) {
    //         objectList[index].is_primary = 1
    //     }
    //     if (field === "is_delete") {
    //         objectList[index].is_delete = 1
    //     }
    //     if (field != "is_delete" && validateAllPhone(value.value)) {
    //         objectList = objectList?.filter((i, index) => index != 0);
    //         let initObjectConfig = JSON.parse(JSON.stringify(this.state.initialObjectConfig));
    //         value.type = 'new'
    //         value.object_id = null;
    //         objectList = [initObjectConfig, ...objectList, value]
    //     }
    //     this.handleGeneralEdit(list, objectList)
    // }
    formatDetails = () => {
        
        let selectedProdCompToEdit
        if (typeof this.state?.production_companies?.production_company === "object" && this.state?.production_companies?.production_company?.address !== undefined) {
            selectedProdCompToEdit = JSON.parse(JSON.stringify({ ...this.state?.production_companies?.production_company }));
        } else {
            selectedProdCompToEdit = JSON.parse(JSON.stringify({ ...this.state?.production_companies }));
            selectedProdCompToEdit["value"]=this.state.production_companies?.production_company?.value;
            selectedProdCompToEdit["text"]=this.state.production_companies?.production_company?.value;

        }

        selectedProdCompToEdit["project_production_company_id"] = this.state?.production_companies?.project_production_company_id;
        selectedProdCompToEdit["union"] = this.state.production_companies?.union;
        selectedProdCompToEdit["signator"] = this.state.production_companies?.signator;
        selectedProdCompToEdit["notes"] = this.state.production_companies?.notes;
        selectedProdCompToEdit["is_delete"] = this.state.production_companies?.is_delete;
        selectedProdCompToEdit["signator_email"] = this.state.production_companies?.signator_email;
        selectedProdCompToEdit["is_primary"] = this.state.production_companies?.is_primary;
        selectedProdCompToEdit["project_id"] = this.props.project_id;
        this.setState({
            selectedProdCompToEdit
        })

    }
    // getDetails = (objectType, searchString) => {
    //     this.setState({ loading: true });
    //     CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`, this.props.userContext?.active_tenant?.tenant_id, true)
    //         .then(response => {
    //             if (objectType == 'PRODUCTION_COMPANIES') {
    //                 let formattedList = response.data?.map(item => ({ value: item?.production_company, text: item?.production_company, id: item?.production_company_id, signator: item?.signator, signator_email: item?.signator_email, address: item?.address, city: item?.city, country: { value: item?.country, text: item?.country }, state: (item?.country === 'United States' || item?.country === 'USA' || item?.country === 'US') ? { value: item?.state, text: item?.state } : item?.state, zip: item?.zip }));

    //                 let tempSelectedProdCompToEdit = [];
    //                 if (typeof this.state?.production_companies?.production_company == "object" && this.state?.production_companies?.production_company?.id) {
    //                     tempSelectedProdCompToEdit = JSON.parse(JSON.stringify([...response?.data]))?.filter(item => item?.production_company_id === this.state?.production_companies?.production_company?.id);
    //                 } else {
    //                     tempSelectedProdCompToEdit = JSON.parse(JSON.stringify([...response?.data]))?.filter(item => item?.production_company_id === this.state?.production_companies?.production_company_id);
    //                 }


    //                 let selectedProdCompToEdit = {};
    //                 if (tempSelectedProdCompToEdit.length > 0) {
    //                     selectedProdCompToEdit = { ...tempSelectedProdCompToEdit[0] };
    //                 }
    //                 selectedProdCompToEdit["project_production_company_id"] = this.state?.production_companies?.project_production_company_id;
    //                 selectedProdCompToEdit["union"] = this.state.production_companies?.union;
    //                 selectedProdCompToEdit["signator"] = this.state.production_companies?.signator;
    //                 selectedProdCompToEdit["notes"] = this.state.production_companies?.notes;
    //                 selectedProdCompToEdit["is_delete"] = this.state.production_companies?.is_delete;
    //                 selectedProdCompToEdit["signator_email"] = this.state.production_companies?.signator_email;
    //                 selectedProdCompToEdit["is_primary"] = this.state.production_companies?.is_primary;
    //                 selectedProdCompToEdit["project_id"] = this.props.project_id;
    //                 selectedProdCompToEdit["country"] = { "value": selectedProdCompToEdit?.country, "text": selectedProdCompToEdit?.country };
    //                 selectedProdCompToEdit["state"] = { "value": selectedProdCompToEdit?.state, "text": selectedProdCompToEdit?.state };
    //                 this.setState({
    //                     companyNames: formattedList,
    //                     loading: false,
    //                     selectedProdCompToEdit
    //                 })
    //             }
    //         },
    //             (err) => {
    //                 console.log("Error in fetching Company Details:", err)
    //             })
    // }
    render() {

        return (
            <>
                <div className="ProdCompanyAddress">
                    <Popover
                        open={this.props?.openPopover}
                        anchorEl={this.props?.setPopOver}
                        onClose={() => { this.props?.popOverClose() }}
                        className={"ProdCompanyAddressPopover"}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        PaperProps={{ style: { width: '45%', height: '45%', backgroundColor: '#F2F2F2' } }}
                    >
                        {this.state.loading ?
                            <CircularProgress /> :
                            <div className="prodCompanyPopover">
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicTextField
                                            id={"prodCompany"}
                                            // placeholder={"Production Company"}
                                            value={this.state.selectedProdCompToEdit?.value}
                                            disabled={true}
                                            label={"Production Company"}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="address">
                                    <MDBCol md={8}>
                                        <BasicTextArea
                                            id={"addressLine"}
                                            rows={4}
                                            value={this.state.selectedProdCompToEdit?.address}
                                            // placeholder={"Address"}
                                            // showMandatory={this.state.postInitiated}
                                            // mandatoryWarning={messages.mandatoryWarning}
                                            onChange={(e) => this.handleGeneralEdit("address", e.target.value.length > 0 ? e.target.value : null)}
                                            // isMandatory={true}
                                            showMandatory={this.state?.postInitiated}
                                            label={"Address"}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="cityState">
                                    <MDBCol md={6} >
                                        <BasicTextField id="city"
                                            value={this.state.selectedProdCompToEdit?.city}
                                            onChange={(e) => this.handleGeneralEdit("city", e.target.value.length > 0 ? e.target.value : null)}
                                            // placeholder="City"
                                            isMandatory={false}
                                            label={"City"}
                                        />
                                    </MDBCol>
                                    <MDBCol md={6} className="state">
                                        {
                                            this.state.selectedProdCompToEdit?.country?.value === 'United States' || this.state.selectedProdCompToEdit?.country?.value === 'US' || this.state.selectedProdCompToEdit?.country?.value === 'USA' || !this.state.selectedProdCompToEdit?.country?.value ?
                                                <SearchSelectField
                                                    id={"state"}
                                                    // disabled={this.props.disabled || false}

                                                    value={this.state.selectedProdCompToEdit?.state || []}
                                                    options={this.state.stateList || []}
                                                    onChange={(e, value) => this.handleGeneralEdit('state', value)}
                                                    width={'100%'}
                                                    // showAsLabel={this.props.disabled || false}
                                                    label={"State"}
                                                />
                                                // <SelectField
                                                //         id={"state"}
                                                //         placeHolderText={"State/Province"}
                                                //         value={this.state.initialObjectConfig?.state}
                                                //         isMandatory={false}
                                                //         options={this.state.stateList || []}
                                                //         onChange={(e) => this.handleGeneralEdit("state", e.target.value)}
                                                //     />
                                                :
                                                <BasicTextField id="state"
                                                    placeholder={"State/Province"}
                                                    value={typeof this.state.selectedProdCompToEdit?.state == "object" ? this.state.selectedProdCompToEdit?.state?.value : this.state.selectedProdCompToEdit?.state}
                                                    onChange={(e) => this.handleGeneralEdit("state", e.target.value.length > 0 ? e.target.value : null)}
                                                    label={"State"}
                                                />
                                        }
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="zipCountry">
                                    <MDBCol md={4}>
                                        <BasicTextField id="zipCode"
                                            value={this.state.selectedProdCompToEdit?.zip}
                                            onChange={(e) => (this.state?.selectedProdCompToEdit?.country?.value == 'United States' || this.state?.selectedProdCompToEdit?.country?.value == 'USA' || this.state?.selectedProdCompToEdit?.country?.value == 'US') ? this.handleGeneralEdit('zip', formatZipCode(e.target.value)) :
                                                this.handleGeneralEdit('zip', e.target.value)}
                                            // placeholder="Postal Code"
                                            label={"Postal Code"}
                                            inValidInput={messages.invalidInput}
                                            fieldValid={this.state.selectedProdCompToEdit?.zip ? ((this.state?.selectedProdCompToEdit?.country?.value == 'United States' || this.state?.selectedProdCompToEdit?.country?.value == 'USA' || this.state?.selectedProdCompToEdit?.country?.value == 'US') ? (this.state.selectedProdCompToEdit?.zip?.length > 9 ? !checkZipCode(this.state.selectedProdCompToEdit?.zip) ? true : false : false) : false) : false}
                                        />
                                    </MDBCol>
                                    <MDBCol md={6} className="prodCompCountry">
                                        <SearchSelectField
                                            id={"country"}
                                            // disabled={this.props.disabled || false}
                                            value={this.state.selectedProdCompToEdit?.country || []}
                                            options={this.state.countryList || []}
                                            onChange={(e, value) => this.handleGeneralEdit('country', value)}
                                            width={'100%'}
                                            // showMandatory={this.state?.postInitiated}
                                            // isMandatory={true}
                                            showAsLabel={this.props.disabled || false}
                                            label={"Country"}
                                        // mandatoryWarning={messages.mandatoryWarning}
                                        />
                                        {/* <SelectField id={"country"}
                                        placeHolderText={"Country"}
                                        value={this.state.initialObjectConfig?.country}
                                        options={this.state.countryList || []}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        onChange={(e) => this.handleGeneralEdit("country", e.target.value)}
                                        isMandatory={true}
                                        showMandatory={this.state?.postInitiated}
                                    /> */}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="submitRow">
                                    <MDBCol md={8}></MDBCol>
                                    <MDBCol md={2}>
                                        <BasicButton
                                            className={"save-address"}
                                            disabled={this.props.disabled || false}
                                            variant="contained"
                                            type="inline"
                                            text={this.state.isSubmit ? <CircularProgress color="inherit" size={20} /> : " "}
                                            icon={"save"}
                                            onClick={() => this.saveLocation()}
                                        />
                                    </MDBCol>
                                    <MDBCol md={2}>
                                        <BasicButton
                                            variant="outlined"
                                            text=" "
                                            icon={"times"}
                                            onClick={this.props?.popOverClose}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        }
                    </Popover>
                </div>
                {/* </MDBCol>
            </MDBContainer> */}
            </>
        );
    }
}

export default withUserContext(ProdCompanyAddress);
import React from 'react';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { MDBModal, MDBModalBody, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit';

import IconTextField from '../../../components/SharedComponents/IconTextField/IconTextField';
import AutoCompleteSearchField from '../../../components/SharedComponents/AutoCompleteSearch/AutoCompleteSearch';
import CancelIcon from '@material-ui/icons/Cancel';
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import ProfilePictureUpload from '../ProfilePicture/ProfilePictureUpload';
import TalentContainer from '../../../components/Talent/TalentContainer';
import AgentContainer from '../../../components/Agency/AgentContainer';
// import ProdCompContainer from '../../../components/Production Companies/ProdCompContainer';
import CrewContainer from '../../../components/Crew/CrewContainer';
// import RepresentativeContainer from '../../../components/Representative/RepresentativeContainer';
import TabComponent from '../Tab/TabComponent';
import { useStyles, agencyNodes, representativeNodes, crewNodes, talentNodes, productionCompanyNodes } from './Config';
import MessageModal from '../../../components/SharedComponents/MessageModal';
import CircularProgress from "@material-ui/core/CircularProgress";
import './Modal.scss';
import { uploadProfileParams } from '../ProfilePicture/Config'
import * as Constants from '../../../../constants/constants';
import CasterService from '../../../../services/service'
import { withUserContext } from "../../../../contexts/UserContext"
import RepresentativeContainer from '../../../components/Representative/RepresentativeContainer';
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent'
import ReportsContainer from '../../../components/Reports/ReportsContainer';
class ModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: this.props?.mode || "new",
            tabValue: 0,
            navObj: {},
            list: [],
            showError: false,
            showSuccess: false,
            showReportFail: false,
            showReportNotFound: false,
            showFail: false,
            fieldChanged: false,
            isPosting: false,
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            reportFailMessage: "Report Creation failed!",
            reportNotFoundMessage: "Report Data Not Found!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            unsavedDisplay: "You have unsaved changes",
            reportTabList: [],
            imgMode: "download",
            isProfileChanged: false,
            profileImg: null,
            imageType: null,
            hideNew: true,
            showSave: false,
            isEditing: false,
            showNotifSuccess: false,
            showNotifFail: false,
            saveFlag: false,
            showRemoveProfile: false
        }
        this.submitButtonRef = React.createRef();
        this.callMethodRef = React.createRef();
    }

    componentDidMount() {

    }
    setStateByChild = (obj) => {
        this.setState(prevState => ({
            ...prevState,
            ...obj
        }))
    }
    setPostFlag = (value) => {
        this.setState({ isPosting: value });
    }

    handleTabChange = (event, value) => {
        this.setState({ tabValue: value });
    }

    onClose = () => {
        // if (this.state.fieldChanged && this.handlePermissions()) {
        if (this.state.fieldChanged) {
            this.setState({ showError: true });
        } else {
            this.closPopup()
        }
    }

    closPopup = () => {
        // this.setState({ mode: "default" });
        this.setState({ tabValue: 0 });
        this.props?.handleClose(this.state.isEditing)
    }

    setModalList = (list) => {
        this.setState({ list: list });
    }

    handleSubmit = () => {
        // this.setState({ isEditing: false },() => {
        //     this.submitButtonRef.current.click();
        // })
        this.submitButtonRef.current.click();
    }

    fieldChanged = (value, mode = "error") => {
        switch (mode) {
            case "change": return this.setState({ fieldChanged: value });
            case "error": return this.setState({ fieldChanged: value });
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showNotifSuccess: value, saveFlag: true });
            case "successNotClose": return this.setState({ showNotifSuccess: value, saveFlag: false });
            case "fail": return this.setState({ showNotifFail: value });
            case "remove": return this.setState({ showRemoveProfile: value });
        }
    }
    handleResetNotify = () => {
        this.setState({ showNotifFail: false, showNotifSuccess: false });
        if (this.state.saveFlag) {
            this.setState({ saveFlag: false }, this.closPopup)
        }
    }
    handleSearchSelect = (val) => {
        this.setState({ navObj: val, mode: "edit", imgMode: "download", isProfileChanged: false, profileImg: null, hideNew: true });
    }

    // static getDerivedStateFromProps(props, state) {       

    //     return null
    // }

    generateFilePayload = (id) => {
        let objectType = this.props?.headerText === "Representatives" ? "Representative" : this.props?.headerText === "Agency" ? "Agency" : this.props?.headerText;
        let fileName = `${objectType.toLowerCase()}_${id}`
        let tenantName = this.props.userContext?.active_tenant?.tenant_name;
        let uploadPayload = {
            ...uploadProfileParams,
            signedURLType: this.state.imgMode,
            fileCategory: "profileimage",
            objectType: objectType,
            objectId: id,
            contentType: this.state.imageType,
            fileName,
        };
        return uploadPayload;
    }
    convertBlobToImg = (blb) => {
        const reader = new FileReader();
        reader.addEventListener('loadend', (e) => {
            const text = e.srcElement.result;
            this.setState({ profileImg: text, imgMode: "download" });
        });
        reader.readAsText(blb);
    }
    handleImgMode(mode) {
        this.setState({ imgMode: mode })
    }
    handleProfileImg(file) {
        this.setState({ profileImg: file })
    }
    handleProfileChange(val) {
        this.setState({ isProfileChanged: val })
    }
    handleImageType(val) {
        this.setState({ imageType: val })
    }
    uploadProfile = (id) => {
        let uploadPayload = this.generateFilePayload(this.props?.id);
        let file = this.state.profileImg;
        console.log(uploadPayload);
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeaturePreSignedUrlUpload', uploadPayload, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (this.state.imgMode === "upload") {
                    CasterService.uploadFile(response.data, file).then(response => {
                        console.log("File Upload Successful:" + response.data);
                        this.setState({ imgMode: "download", isProfileChanged: false });

                    }, (err) => {
                        this.setState({ imgMode: "download", isProfileChanged: false, profileImg: null });
                        console.log("File Upload Failed:" + err);
                    });
                }
                else {
                    CasterService.downloadFile(response.data).then(response => {
                        console.log("File Download Successful:" + response.data);
                        this.convertBlobToImg(response.data);

                    }, (err) => {
                        console.log("File Download Failed:" + err);
                        this.setState({ profileImg: null, imgMode: 'download' })
                    });
                }

            }, (err) => {
                console.log("Pre Signed Url Failed:" + err);
            });
    }
    getModalContent = () => {
        switch (this.props?.headerText) {
            case 'Talent': return <TalentContainer
                tabValue={this.state.tabValue}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                navObj={this.state.navObj}
                talentId={this.props?.objectId}
                mode={this.state.mode}
                setModalList={this.setModalList}
                navFuns={this.props.navFuns}
                submitButtonRef={this.submitButtonRef}
                callMethodRef={this.callMethodRef}
                isProfileChanged={this.state.isProfileChanged}
                uploadProfile={this.uploadProfile}
                isEditing={this.state.isEditing}
                handleTalentCreation={this.props?.handleTalentCreation}
                notificationComponent={this.notificationComponent.bind(this)}
                modalClose={this.onClose.bind(this)}
                editModal={this.editModal.bind(this)}
                {...this.props}
            />;
            case 'Representative': return <RepresentativeContainer
                tabValue={this.state.tabValue}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                navObj={this.state.navObj}
                representativeId={this.props?.objectId}
                mode={this.state.mode}
                setModalList={this.setModalList}
                navFuns={this.props.navFuns}
                submitButtonRef={this.submitButtonRef}
                isProfileChanged={this.state.isProfileChanged}
                uploadProfile={this.uploadProfile}
                isEditing={this.state.isEditing}
            // handleTalentCreation={this.props?.handleTalentCreation}
            />;
            case 'Agency': return <AgentContainer
                tabValue={this.state.tabValue}
                fieldChanged={this.fieldChanged}
                uploadProfile={this.uploadProfile}
                setPostFlag={this.setPostFlag}
                navObj={this.state.navObj}
                agencyId={this.props.agencyId}
                mode={this.props.mode}
                setModalList={this.setModalList}
                navFuns={this.props.navFuns}
                isEditing={this.state.isEditing}
                modalClose={this.onClose.bind(this)}
                submitButtonRef={this.submitButtonRef}
                isProfileChanged={this.state.isProfileChanged} />;
            case 'Production Companies': return <ProdCompContainer
                tabValue={this.state.tabValue}
                fieldChanged={this.fieldChanged}
                setPostFlag={this.setPostFlag}
                navObj={this.state.navObj}
                mode={this.state.mode}
                setModalList={this.setModalList}
                navFuns={this.props.navFuns}
                submitButtonRef={this.submitButtonRef} />;
            case 'Crew': return <CrewContainer
                tabValue={this.state.tabValue}
                setModalList={this.setModalList}
                fieldChanged={this.fieldChanged}
                setStateByChild={this.setStateByChild.bind(this)}
                setPostFlag={this.setPostFlag}
                submitButtonRef={this.submitButtonRef}
                navFuns={this.props.navFuns}
                navObj={this.state.navObj}
                mode={this.state.mode}
                crewId={this.props?.objectId}
                isProfileChanged={this.state.isProfileChanged}
                uploadProfile={this.uploadProfile}
                isEditing={this.state.isEditing}
                handleNewCrewDetails={this.props.handleNewCrewDetails}
                fetchDetailsCallback={this.props?.fetchDetailsCallback}
                {...this.props}
            />;
            case 'Reports': return <ReportsContainer
                tabValue={this.state.tabValue}
                handleClose={this.props?.handleClose}
            />

        }
    }
    getAkaNames = (list) => {
        let akaNames = "";
        if (typeof list === 'string') {
            akaNames = list;
        }
        else {
            list?.map(aka => aka.aka ? (akaNames = akaNames + (akaNames ? ", " : " ") + aka.aka) : null);
        }
        return akaNames;
    }

    handlePermissions = () => {
        let userPermissions = this.props?.userContext?.active_tenant?.permissions;
        let showSave = false;
        switch (this.props.objectType) {
            case 'TALENT':
                showSave = userPermissions?.isModifyTalent ? userPermissions?.isModifyTalent : false
                break;
            case 'AGENCY':
                showSave = userPermissions?.isModifyAgencies ? userPermissions?.isModifyAgencies : false
                break;
            case 'PRODUCTION_COMPANY':
                showSave = userPermissions?.isModifyProdComp ? userPermissions?.isModifyProdComp : false
                break;
            case 'CREW':
                showSave = userPermissions?.isModifyCrew ? userPermissions?.isModifyCrew : false
                break;
            case 'REPRESENTATIVE':
                showSave = userPermissions?.isModifyRepresentatives ? userPermissions?.isModifyRepresentatives : false
                break;
        }
        return showSave;
        // this.setState({ showSave: showSave });
    }

    getWidthForModal = () => {
        switch (this.props?.headerText) {
            case 'Talent': return 'talentModalWidth'
            case 'Agency': return 'talentModalWidth'
            case 'Representative': return 'talentModalWidth'
            case 'Production Companies': return 'prodcompModalWidth'
            case 'Crew': return 'crewModalWidth'
            case 'Reports': return 'reportModalWidth'
            default: return '';

        }
    }

    getClasses = () => {
        const { classes } = this.props;
        switch (this.props?.headerText) {
            case 'Reports': return classes.reportModalWidth;
            case 'Crew': return classes.CrewContainer;
            default: return '';
        }
    }

    editModal = (check = false) => {
        if (check) {
            this.callMethodRef.current.resetValues()
            this.setState({ showError: false , editCancel: false})
        }
        this.setState((prevState) => ({ ...prevState, isEditing: !prevState.isEditing }))
    }

    onEditCancel = () => {
        if (this.state.fieldChanged) {
            this.setState({ showError: true , editCancel : true});
        } else { 
            this.editModal(true)
        }
    }

    removeProfileImg = () => {
        this.setState({ imgMode: "" }, () => {
            let payload = this.generateFilePayload(this.props?.id);
            let file = this.state.profileImg;
            payload.filename = payload.fileName
            CasterService.deleteDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureDeleteFiles', payload, this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    if (response.data.error) {
                        this.notificationComponent(true, "fail");
                        console.log("Profile File Removal Failed:" + err);
                    }
                    else {
                        this.notificationComponent(true, "remove");
                        this.uploadProfile();
                    }

                }, (err) => {
                    this.notificationComponent(true, "fail");
                    console.log("Pre Signed Url Failed:" + err);
                });
        })
    }

    render() {
        const { classes } = this.props;
        let severity = this.state.showNotifFail ? "error" : this.state.showNotifSuccess ? "success" : this.state.showRemoveProfile ? "success" : "";
        let messageNotification = this.state.showNotifSuccess ? "The changes are saved successfully!" : this.state.showNotifFail ? "Saving changes failed!" : this.state.showRemoveProfile ? "Profile Image Removed Successfully" : "";
        const dataNodes = (this.props?.headerText === "Agency") ? agencyNodes :
            (this.props?.headerText === "Representatives") ? representativeNodes :
                (this.props?.headerText === "Crew") ? crewNodes :
                    (this.props?.headerText === "Talent") ? talentNodes :
                        (this.props?.headerText === "Production Companies") ? productionCompanyNodes : {};
        let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
            (this.state.showSuccess && (this.state.mode === "edit")) ? this.state.saveEditMessage :
                (this.state.showSuccess && (this.state.mode === "new")) ? `New ${this.props?.headerText === "Production Companies" ? "Production Company" : this.props?.headerText} has been created!` :
                    this.state.showReportFail ? this.state.reportFailMessage : this.state.showReportNotFound ? this.state.reportNotFoundMessage : "";
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveTitle : this.state.showReportFail ? this.state.reportFailMessage :
                this.state.showReportNotFound ? this.state.reportNotFoundMessage : "";
        return (
            <div className={"ModalContentContainer DefaultModal " + this.getWidthForModal()}>
                <NotificationComponent open={this.state.showNotifSuccess || this.state.showNotifFail} message={messageNotification} severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)} />
                <MDBModal overflowScroll staticBackdrop closeOnEsc={false} tabIndex='-1' show={this.props?.open ? this.props?.open : false}>

                    <MDBModalDialog size={this.props?.headerText !== "Reports" ? "md" : this.props?.headerText === "Crew" ? "lg" : "md"} className={(this.props?.headerText === "Reports" || this.props?.headerText === "Crew") ? this.getClasses() : null} >
                        <MDBModalBody>
                            <MDBModalContent>
                                <div>
                                    <div fluid className="ModalContainer padding-hor-12">
                                        {
                                            // this.handlePermissions() &&
                                            <MessageModal
                                                open={this.state.showError || this.state.showFail || this.state.showSuccess || this.state.showReportFail || this.state.showReportNotFound || false}
                                                title={comfirmModalTitle}
                                                message={message}
                                                hideCancel={this.state.showFail || this.state.showReportFail || this.state.showSuccess || this.state.showReportNotFound || false}
                                                primaryButtonText={"OK"}
                                                secondaryButtonText={"Cancel"}
                                                onConfirm={this.state.showReportNotFound ? () => this.setState({ showReportNotFound: false }) : this.state.editCancel ? () => this.editModal(true) : () => this.closPopup()}
                                                handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false, showReportFail: false, showReportNotFound: false, editCancel: false })} />
                                        }
                                        <MDBRow className="ModalContent">
                                            <MDBCol>
                                                <MDBRow className="ModalHeader">
                                                    <MDBCol md={6} id={"modal-title-" + this.props?.id} className="HeaderText" >
                                                        <div>
                                                            <span className="HeaderName">{this.props?.headerName}</span>
                                                            {( this.props?.headerText !== "Reports") ? <span className="aliasName">{(this.props?.akas) ? "AKA: " + this.props?.akas : this.state?.akas ? "AKA: " + this.state?.akas : "AKA: " + ""}</span> : null}
                                                        </div>
                                                    </MDBCol>
                                                    <MDBCol md={3} >
                                                        <span className="HeaderName">{this.state?.isEditing ? this.state?.unsavedDisplay : ""}</span>
                                                        {/* {this.props?.headerText !== "Reports" ?
                                                            <AutoCompleteSearchField 
                                                                placeholder={this.props?.searchPlaceholder}
                                                                headerText={this.props?.headerText} dataUrl={this.props.dataUrl}
                                                                disabled={this.props?.disableSearch}
                                                                objectType={this.props.objectType}
                                                                encryptionType={this.props?.headerText === "Talent" ? 2 : 1}
                                                                handleSearchSelect={this.handleSearchSelect}
                                                                searchAlphabetVariant={this.props?.headerText === "Talent" || this.props?.headerText === "Representatives" || this.props?.headerText === "Agency"}>
                                                                </AutoCompleteSearchField>
                                                            : null
                                                        } */}
                                                    </MDBCol>
                                                    <MDBCol md={1} className="Save">
                                                        {
                                                            this.props?.headerText !== "Reports" &&
                                                            <>
                                                                <BasicButton variant="outlined"
                                                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={20} />
                                                                        : this.state.isEditing ? "Save" : "Edit"}
                                                                    type="inline"
                                                                    ref={this.submitButtonRef}
                                                                    icon={this.state.isEditing ? "save" : "pen"}
                                                                    onClick={() => this.state.isEditing ? this.handleSubmit() : this.editModal()}
                                                                />

                                                            </>

                                                        }
                                                    </MDBCol>
                                                    {this.props?.headerText !== "Reports" &&
                                                        <MDBCol md={1} >
                                                            {this.state.isEditing ? (
                                                                <BasicButton variant="outlined"
                                                                    text={"Cancel"}
                                                                    type="inline"
                                                                    onClick={() => this.props?.headerText === "Talent" ? this.onEditCancel() : this.editModal(false) }
                                                                />

                                                            ) : null}
                                                        </MDBCol>}

                                                    <MDBCol md={1} className="closeIcon" onClick={this.onClose} ><CancelIcon style={{ fill: "white" }} /></MDBCol>
                                                </MDBRow>
                                                <MDBRow className="ModalBody">
                                                    <MDBCol md={2} className="ModalSideNav">
                                                        <div className="object-font">{this.props?.objectType}</div>
                                                        {<MDBCol className="ProfileDiv">
                                                            {/* {this.props?.headerText === "Representative" ? 
                                                       <MDBRow className="textHeader"><span className="text">{this.props?.headerText}</span></MDBRow> : null} */}
                                                            {this.props?.isProfileDiv ?
                                                                <MDBRow className="profilePic"><ProfilePictureUpload
                                                                    headerText={this.props?.headerText}
                                                                    navObj={this.state.navObj}
                                                                    mode={this.state.mode}
                                                                    imgMode={this.state.imgMode}
                                                                    isProfileChanged={this.state.isProfileChanged}
                                                                    profileImg={this.state.profileImg}
                                                                    handleImgMode={this.handleImgMode.bind(this)}
                                                                    handleProfileChange={this.handleProfileChange.bind(this)}
                                                                    handleProfileImg={this.handleProfileImg.bind(this)}
                                                                    uploadProfile={this.uploadProfile.bind(this)}
                                                                    handleImageType={this.handleImageType.bind(this)}
                                                                    removeProfileImg={this.removeProfileImg.bind(this)}
                                                                    isUpload={true}
                                                                    isEditing={this.state.isEditing}
                                                                /></MDBRow> : null}

                                                            {/* {(this.props?.headerText === "Agency" || this.props?.headerText === "Production Companies") ?
                                                                <MDBRow className="NameHeading">{this.state.list[dataNodes?.headerName] || ""}</MDBRow> :
                                                                this.props?.headerText !== "Crew" ?<MDBRow className="NameHeading">{((this.state.list[dataNodes?.headerName] || "") + ' ' + (this.state.list[dataNodes?.lastName] || ""))}</MDBRow>:null
                                                            } */}

                                                            {/* {this.props?.headerText !== "Agency" && this.props?.headerText !== "Representatives" && this.props?.headerText !== "Reports"
                                                                 ?
                                                                <MDBRow className="AkaNames">{this.state.list[dataNodes?.akas] ? this.getAkaNames(this.state.list[dataNodes?.akas]) : ""}</MDBRow> : null} */}

                                                            {/* {(this.props?.headerText !== "Talent" && this.props?.headerText !== "Reports") && <MDBRow className="Types">{this.state.list[dataNodes?.type] || ""}</MDBRow>} */}

                                                            {/* {this.props?.headerText !== "Reports" && <MDBRow className="Types">{this.state.list[dataNodes?.headerDetail] || ""}</MDBRow>} */}
                                                            <MDBRow>
                                                                <TabComponent
                                                                    headerText={this.props?.headerText}
                                                                    tabList={this.props?.tabList}
                                                                    tabValue={this.state.tabValue}
                                                                    handleTabChange={this.handleTabChange}
                                                                />
                                                            </MDBRow>

                                                            {/* <MDBRow> <TabComponent tabList={this.props?.tabList} tabValue={this.state.tabValue} handleTabChange={this.handleTabChange} /></MDBRow> */}

                                                        </MDBCol>}
                                                        <MDBRow className="footer">
                                                            {this.props?.headerText !== "Reports" && !this.state.hideNew ?
                                                                <BasicButton
                                                                    variant="outlined"
                                                                    text={this.props?.addButtonText}
                                                                    onClick={() => this.setState({ mode: "new", hideNew: true })} /> : null}
                                                        </MDBRow>
                                                    </MDBCol>
                                                    <MDBCol md={10} className="Content">
                                                        {this.getModalContent()}
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </div>


                            </MDBModalContent>
                        </MDBModalBody>
                    </MDBModalDialog>
                </MDBModal>
            </div>
        );
    }
}

export default withUserContext(withStyles(useStyles)(ModalComponent));
export const entityList = [
    { label: "Projects", value: "Projects", name: "Projects"},
    { label: "People", value: "People", name: "People" },
    { label: "Performers", value: "Performers", name: "Performers" },
    { label: "Representatives", value: "Representatives", name: "Representatives" },
    { label: "Crew", value: "Crew", name: "Crew" },
    { label: "Companies", value: "Companies", name: "Companies" },
    { label: "Contracts", value: "Contracts", name: "Contracts" },    
]


export const filterTypeDateOptions = [
    { value: 'between', text: 'Between' },
    { value: '>', text: 'Greater than' },
    { value: '<', text: 'Less than' },
    { value: 'not null', text: 'Any Value Entered' },
    { value: 'null', text: 'No Value Entered' },
]

export const nullCheckValues = ['null', 'not null','Any Value Entered','No Value Entered','Any','None']



export const adsInitialConfig = {
    headings: [
    {
        headingLabel: "Project Title",
        icon: "" ,
        width: "25%",
    },
    {
        headingLabel: "Studio",
        icon: "",
        width: "25%",
    },
    {
        headingLabel: "Status",
        icon: "",
        width: "25%",
    },
    {
        headingLabel: "SAP/GL # (Prod)",
        icon: "",
        width: "25%",
    }
],
    dataNodes: ["project_name", "studio_name","status", "gl_no_prod"],
    primaryKey: "project_id",
};

export const tableConfigs = {
    Projects : [
        {value : 'project_name', text: 'Project Title', isChecked: 1, def:true, tableName:'project',hyperLink : true},
        {value : 'studio_name', text: 'Studio', isChecked: 1, tableName:'project'},
        {value : 'distributer_name', text: 'Distirbutor', isChecked: 0, tableName:'project'},
        {value : 'status', text: 'Status', isChecked: 1, tableName:'project'},
        {value : 'project_start_date', text: 'Start Date', isChecked: 0,tableName:'project'},
        {value : 'wrap_date', text: 'Wrap Date', isChecked: 0,tableName:'project'},
        {value : 'release_date', text: 'Release Date', isChecked: 0,tableName:'project'},
        {value : 'assigned_to', text: 'Assigned To', isChecked: 0,tableName:'project'},
        {value : 'gl_no_prod', text: 'SAP/GL # (Prod)', isChecked: 1, tableName:'project'},
        {value : 'gl_no_dev', text: 'SAP/GL # (Dev)', isChecked: 0, tableName:'project'},
        {value : 'project_sag_id', text: 'SAG Prod. ID', isChecked: 0,  tableName:'project'},  
        {value : 'contract_type', text: 'Contract Type', isChecked: 0, tableName:'project'},
        {value : 'project_production_companies', text: 'Production Companies', isChecked: 0, tableName:'project_production_companies'},
        {value : 'project_production_companies_union', text: 'Unions', isChecked: 0, tableName:'project_production_companies'},
        {value : 'project_production_companies_signator', text: 'Signatories', isChecked: 0, tableName:'project_production_companies'},
        {value : 'project_notes', text: 'Project Notes', isChecked: 0, tableName:'project'},
    ] ,
    Performers : [
        {value : 'talent_name', text: 'Performer Name', isChecked: 1, def:true, tableName:'talent', hyperLink : true},
        {value : 'selected_alias_name', text: 'Aliases', isChecked: 0, tableName:'performer_deal' },
        {value : 'gender', text: 'Gender', isChecked: 0,  tableName:'talent'},
        {value : 'ethnicity', text: 'Ethnicity', isChecked: 0, tableName:'talent'},
        {value : 'citizen_of', text: 'Citizenship', isChecked: 0,tableName:'performer_deal'},
        {value : 'immigration_status', text: 'Immigration Status', isChecked: 0, tableName:'performer_deal'},
        {value : 'birth_date', text: 'DoB', isChecked: 0, tableName:'talent'},
        {value : 'talent_age', text: 'Age', isChecked: 0, tableName: 'talent'},
        {value : 'death_date', text: 'DoD', isChecked: 0, tableName:'talent'},
        {value : 'ssn', text: 'SSN', isChecked: 0, tableName:'talent'},
        {value : 'sag_id', text: 'SAG ID', isChecked: 0,tableName:'performer_deal'},
        {value : 'sag_status', text: 'SAG Status', isChecked: 0,tableName:'performer_deal'},
        {value : 'talent_notes', text: 'Talent Profile Notes', isChecked: 0,tableName:'talent'},
        {value : 'performer_deal_phone', text: 'Phones', isChecked: 0,tableName:'performer_deal_phone'},
        {value : 'perfomer_deal_email', text: 'Emails', isChecked: 0,tableName:'performer_deal_email'},
        {value : 'performer_deal_address', text: 'Addresses', isChecked: 0,tableName:'performer_deal_address'},
        {value : 'performer_deal_guardians', text: 'Guardian Names', isChecked: 0,tableName:'performer_deal_guardians'},
        {value : 'relation', text: 'Guardian Types', isChecked: 0,tableName:'performer_deal_guardians'},
        {value : 'performer_deal_guardians_phone', text: 'Guardian Phones', isChecked: 0,tableName:'performer_deal_guardians_phone'},
        {value : 'performer_deal_guardians_email', text: 'Guardian Emails', isChecked: 0,tableName:'performer_deal_guardians_email'},
        {value : 'performer_deal_guardians_address', text: 'Guardian Addresses', isChecked: 0,tableName:'performer_deal_guardians_address'},
        {value : 'role', text: 'Role Name', isChecked: 1,tableName:'performer_deal'},
        {value : 'deal_date', text: 'Deal Date', isChecked: 1,tableName:'performer_deal'},
        {value : 'production_company', text: 'Production Company', isChecked: 0,tableName:'performer_deal'},
        {value : 'union', text: 'Union', isChecked: 1,tableName:'performer_deal'},
        {value : 'i9_status', text: 'I9 Status', isChecked: 0,tableName:'performer_deal'},
        {value : 'comments', text: 'Performer Record Notes', isChecked: 0,tableName:'performer_deal'},
        {value : 'performer_deal_credit_type', text: 'Given Credits', isChecked: 0, tableName:'performer_deal_credit'},
        {value : 'credit_mt_et_text', text: 'Main Title/End Title Credit Text', isChecked: 0, tableName:'performer_deal_credit'},
        {value : 'credit_et_text', text: 'End Title Credit text', isChecked: 0, tableName:'performer_deal_credit'},
        {value : 'credit_billing_notes', text: 'Credit Billing Notes', isChecked: 0, tableName:'performer_deal_credit'},
        {value : 'guarantee_total', text: 'Total Guarantee Used', isChecked: 0, tableName:'performer_deal_work_activity'},
        {value : 'post_total', text: 'Total Post Free Used', isChecked: 0,tableName:'performer_deal_work_activity'},
        {value : 'basic_total', text: 'Total Basic Free Used', isChecked: 0,tableName:'performer_deal_work_activity'},
        {value : 'total_days_worked', text: 'Total Days Worked', isChecked: 0,tableName:'performer_deal_work_activity'},
        {value : 'confirmed_credit', text: 'Confirmed Credits', isChecked: 0, tableName:'confirmed_credit'}, //check
        {value : 'credit_as', text: 'Credited As', isChecked: 0, tableName:'performer_deal_billing'},
        {value : 'confirmed_by', text: 'Confirmed By', isChecked: 0,tableName:'performer_deal_billing'},
        {value : 'performer_deal_billing_notes', text: 'Billing Notes', isChecked: 0, tableName:'performer_deal_billing'},
        {value : 'project_name', text: 'Project Title', isChecked: 1, tableName:'project'},
        {value : 'studio_name', text: 'Studio', isChecked: 0,  tableName:'project'},
        {value : 'distributer_name', text: 'Distirbutor', isChecked: 0, tableName:'project'},
        {value : 'status', text: 'Status', isChecked: 0,  tableName:'project'},
        {value : 'project_start_date', text: 'Start Date', isChecked: 0,tableName:'project'},
        {value : 'wrap_date', text: 'Wrap Date', isChecked: 0,tableName:'project'},
        {value : 'release_date', text: 'Release Date', isChecked: 0,tableName:'project'},
        {value : 'assigned_to', text: 'Assigned To', isChecked: 0,tableName:'project'},
        {value : 'gl_no_prod', text: 'SAP/GL # (Prod)', isChecked: 0, tableName:'project'},
        {value : 'gl_no_dev', text: 'SAP/GL # (Dev)', isChecked: 0, tableName:'project'},
        {value : 'project_sag_id', text: 'SAG Prod. ID', isChecked: 0,  tableName:'project'},  
        {value : 'project_production_companies_signator', text: 'Signatories', isChecked: 0, tableName:'project_production_companies'},
        {value : 'project_notes', text: 'Project Notes', isChecked: 0, tableName:'project'},
    ],
    Contracts:[
        {value : 'talent_name', text: 'Performer Name', isChecked: 1, def:true,tableName:'talent'},
        {value : 'selected_alias_name', text: 'Aliases', isChecked: 0,  tableName:'performer_deal'},
        {value : 'role', text: 'Role Name', isChecked: 1, tableName:'performer_deal'},
        {value : 'deal_date', text: 'Deal Date', isChecked: 1, tableName:'performer_deal'},
        {value : 'production_company', text: 'Production Company', isChecked: 0,tableName:'performer_deal'},
        {value : 'union', text: 'Union', isChecked: 1,tableName:'performer_deal'},
        {value : 'sag_status', text: 'SAG Status', isChecked: 0,tableName:'performer_deal'},
        {value : 'i9_status', text: 'I9 Status', isChecked: 0,tableName:'performer_deal'},
        {value : 'talent_notes', text: 'Talent Profile Notes', isChecked: 0,tableName:'talent'},
        {value : 'comments', text: 'Performer Record Notes', isChecked: 0,tableName:'performer_deal'},
        {value : 'performer_type', text: 'Peformer Type', isChecked: 0, tableName:'performer_deal_contract'},
        {value : 'fee_type', text: 'Fee Type', isChecked: 0, tableName:'performer_deal_contract'},
        {value : 'contract_type', text: 'Contract Type', isChecked: 0, tableName:'project'},
        {value : 'rate', text: 'Rate', isChecked: 0, tableName:'performer_deal_contract'},
        {value : 'guarantee_free', text: 'Guarantee (Period+Type)', isChecked: 0, tableName:'performer_deal_contract'},
        {value : 'period', text: 'Period', isChecked: 0, tableName:'performer_deal_contract'},
        {value : 'start_date', text: 'Start Date', isChecked: 0, tableName:'performer_deal_contract'},
        {value : 'plus_minus_period_type', text: '+/- (days/weeks)', isChecked: 0, tableName:'performer_deal_contract'},
        {value : 'principal_free_period_type', text: 'Principal Free', isChecked: 0, tableName:'performer_deal_contract'},
        {value : 'post_free_period_type', text: 'Post Free', isChecked: 0, tableName:'performer_deal_contract'},
        {value : 'basic_free_period_type', text: 'Basic Free', isChecked: 0, tableName:'performer_deal_contract'},
        {value : 'sent_date', text: 'Contract Sent', isChecked: 0, tableName:'performer_deal_contract'},
        {value : 'returned_date', text: 'Contract Returned', isChecked: 0, tableName:'performer_deal_contract'},
        {value : 'revised_date', text: 'Contract Revised', isChecked: 0, tableName:'performer_deal_contract'},
        {value : 'info', text: 'Contract Info Text', isChecked: 0, tableName:'performer_deal_contract'},
        {value : 'text', text: 'Contract Text', isChecked: 0, tableName:'performer_deal_contract'},
        {value : 'void', text: 'Voided', isChecked: 0, tableName:'performer_deal_contract'},
        {value : 'total_amont', text: 'Total Amount', isChecked: 1},
        {value : 'performer_deal_credit_type', text: 'Given Credits', isChecked: 0, tableName:'performer_deal_credit'},
        {value : 'credit_mt_et_text', text: 'Main Title/End Title Credit Text', isChecked: 0, tableName:'performer_deal_credit'},
        {value : 'credit_et_text', text: 'End Title Credit text', isChecked: 0, tableName:'performer_deal_credit'},
        {value : 'credit_billing_notes', text: 'Credit Billing Notes', isChecked: 0, tableName:'performer_deal_credit'},
        {value : 'memo_type', text: 'Memo Type', isChecked: 0,tableName:'performer_deal_perks'},
        {value : 'memo_text', text: 'Memo Text', isChecked: 0,tableName:'performer_deal_perks'},
        {value : 'guarantee_total', text: 'Guarantee Used', isChecked: 0,tableName:'performer_deal_work_activity'},
        {value : 'post_total', text: 'Post Free Used', isChecked: 0,tableName:'performer_deal_work_activity'},
        {value : 'basic_total', text: 'Basic Free Used', isChecked: 0,tableName:'performer_deal_work_activity'},
        {value : 'total_days_worked', text: 'Total Days Worked', isChecked: 0,tableName:'performer_deal_work_activity'},
        {value : 'confirmed_credit', text: 'Confirmed Credits', isChecked: 0, tableName:'confirmed_credit'}, //check
        {value : 'credit_as', text: 'Credited As', isChecked: 0, tableName:'performer_deal_billing'},
        {value : 'confirmed_by', text: 'Confirmed By', isChecked: 0,tableName:'performer_deal_billing'},
        {value : 'gender', text: 'Gender', isChecked: 0,  tableName:'talent'},
        {value : 'ethnicity', text: 'Ethnicity', isChecked: 0, tableName:'talent'},
        {value : 'citizen_of', text: 'Citizenship', isChecked: 0,tableName:'performer_deal'},
        {value : 'immigration_status', text: 'Immigration Status', isChecked: 0, tableName:'performer_deal'},
        {value : 'birth_date', text: 'DoB', isChecked: 0, tableName:'talent'},
        {value : 'talent_age', text: 'Age', isChecked: 0, tableName: 'talent'},
        {value : 'death_date', text: 'DoD', isChecked: 0, tableName:'talent'},
        {value : 'ssn', text: 'SSN', isChecked: 0, tableName:'talent'},
        {value : 'sag_id', text: 'SAG ID', isChecked: 0,tableName:'performer_deal'},
        {value : 'sag_status', text: 'SAG Status', isChecked: 0,tableName:'performer_deal'},
        {value : 'performer_deal_phone', text: 'Phones', isChecked: 0,tableName:'performer_deal_phone'},
        {value : 'perfomer_deal_email', text: 'Emails', isChecked: 0,tableName:'performer_deal_email'},
        {value : 'performer_deal_address', text: 'Addresses', isChecked: 0,tableName:'performer_deal_address'},
        {value : 'performer_deal_guardians', text: 'Guardian Names', isChecked: 0,tableName:'performer_deal_guardians'},
        {value : 'relation', text: 'Guardian Types', isChecked: 0,tableName:'performer_deal_guardians'},
        {value : 'performer_deal_guardians_phone', text: 'Guardian Phones', isChecked: 0,tableName:'performer_deal_guardians_phone'},
        {value : 'performer_deal_guardians_email', text: 'Guardian Emails', isChecked: 0,tableName:'performer_deal_guardians_email'},
        {value : 'performer_deal_guardians_address', text: 'Guardian Addresses', isChecked: 0,tableName:'performer_deal_guardians_address'},
        {value : 'project_name', text: 'Project Title', isChecked: 1, tableName:'project'},
        {value : 'studio_name', text: 'Studio', isChecked: 0,   tableName:'project'},
        {value : 'distributer_name', text: 'Distirbutor', isChecked: 0, tableName:'project'},
        {value : 'status', text: 'Status', isChecked: 0,   tableName:'project'},
        {value : 'project_start_date', text: 'Start Date', isChecked: 0,tableName:'project'},
        {value : 'wrap_date', text: 'Wrap Date', isChecked: 0,tableName:'project'},
        {value : 'release_date', text: 'Release Date', isChecked: 0,tableName:'project'},
        {value : 'assigned_to', text: 'Assigned To', isChecked: 0,tableName:'project'},
        {value : 'gl_no_prod', text: 'SAP/GL # (Prod)', isChecked: 0, tableName:'project'},
        {value : 'gl_no_dev', text: 'SAP/GL # (Dev)', isChecked: 0, tableName:'project'},
        {value : 'project_sag_id', text: 'SAG Prod. ID', isChecked: 0,  tableName:'project'},  
        {value : 'project_production_companies_signator', text: 'Signatories', isChecked: 0, tableName:'project_production_companies'},
        {value : 'project_notes', text: 'Project Notes', isChecked: 0, tableName:'project'},
    ],
    People:[ 
        {value : 'person_name', text: 'Name', isChecked: 1, def:true},
        {value : 'person_aliases', text: 'Aliases', isChecked: 0},
        {value : 'person_phone', text: 'Phones', isChecked: 1},
        {value : 'person_email', text: 'Emails', isChecked: 1},
        {value : 'person_address', text: 'Addresses', isChecked: 1},
    ],
    Representatives:[ 
        {value : 'representative_name', text: 'Rep Name', isChecked: 1, def:true, tableName:'representative', hyperLink : true},
        {value : 'representative_occupation', text: 'Occupation', isChecked:1,  tableName:'performer_deal_representative'},
        {value : 'agency_name', text: 'Agency', isChecked: 1,   tableName:'representative'},
        {value : 'performer_deal_representative_phone', text: 'Phones', isChecked: 0,  tableName:'performer_deal_representative_phone'},
        {value : 'performer_deal_representative_email', text: 'Emails', isChecked: 0,  tableName:'performer_deal_representative_email'},
        {value : 'performer_deal_representative_address', text: 'Addresses', isChecked: 1,  tableName:'performer_deal_representative_address'},
    ],
    Crew:[ 
        {value : 'crew_name', text: 'Crew Name', isChecked: 1, def:true, tableName:'crew',hyperLink : true},
        {value : 'crew_occupation', text: 'Occupation', isChecked:1,  tableName:'crew'},
        {value : 'crew_assistant', text: 'Assistant Names', isChecked: 1,  tableName:'crew_assistant'},
        {value : 'crew_phone', text: 'Phones', isChecked: 0, tableName:'crew_phone'},
        {value : 'crew_email', text: 'Emails', isChecked: 0, tableName:'crew_email'},
        {value : 'project_crew_location', text: 'Location', isChecked: 1,  tableName:'project_crew'},
        {value : 'social_media_type', text: 'Social Media Type', isChecked: 0, tableName:'crew_social_media'},
        {value : 'social_media_account', text: 'Social Media Handles', isChecked: 0, tableName:'crew_social_media'},
        {value : 'crew_address', text: 'Addresses', isChecked: 1, tableName:'crew_address'},
    ],
    Companies:[ 
        {value : 'agency_name', text: 'Company Name', isChecked: 1, def:true, hyperLink : true, tableName:'agency'},
        {value : 'agency_occupation', text: 'Occupation', isChecked:1 ,tableName:'agency'},
        {value : 'agency_location_name', text: 'Location Names', isChecked: 1,  tableName:'agency_location'},
        {value : 'agency_location_phone', text: 'Location Phones', isChecked: 1, tableName:'agency_location_phone'},
        {value : 'agency_aliases', text: 'Aliases', isChecked: 0, tableName:'agency_alias'},
        {value : 'social_media_type', text: 'Social Media Type', isChecked: 0,tableName:'agency_social_media'},
        {value : 'social_media_account', text: 'Social Media Handles', isChecked: 0,tableName:'agency_social_media'},
        {value : 'agency_notes', text: 'Notes', isChecked: 0,tableName:'agency'},
        //{value : '', text: 'Comapny Notes', isChecked: 0},
        {value : 'agency_location_address', text: 'Location Addresses', isChecked: 1,tableName:'agency_location'},
    ],      
} 
 

const projectInnerFilter = [
    {
        type: "Core", isExpand: false, filterCategories: [
            { value: "project_name", text: "Project Title", type: "text"},
            { value: "studio_name", text: "Studio", type: "Select", tableName: "PROJECT_STUDIO", optionName: "studioDetails",  },
            { value: "distributor_name", text: "Distributor", type: "Select", tableName: "PROJECT_DISTRIBUTOR", optionName: "distributerDetails",  },
            { value: "status", text: "Status", type: "Select", tableName: "PROJECT_STATUS", optionName: "projectStatusDetails" , },
            { value: "project_type", text: "Project Type", type: "Select", tableName: "PROJECT_TYPE", optionName: "projectTypeDetails",  },
            { value: "gl_no_prod", text: "SAP/GL # Prod", type: "text"},
            { value: "contract_type", text: "Contract Type", type: "Select",tableName: "CONTRACT_TYPE", optionName: "contractTypeDetails",  },
            { value: "production_company", text: "Production Company", type: "text"}, // tableName: "PRODUCTION_COMPANIES", optionName: "prodCompDetails",valueKey:'production_company_id',textKey:'production_company'  },
            { value: "union", text: "Union", type: "Select", tableName: "UNION", optionName: "unionDetails",  },
        ]
    },                    {
        type: "More Details", isExpand: false, filterCategories: [
            { value: "alias_name", text: "Project Alias", type: "text"},
            { value: "start_date", text: "Start Date", type: "dateRange",compValue: 'between'  },
            { value: "wrap_date", text: "Wrap Date", type: "dateRange", compValue: 'between' },
            { value: "release_date", text: "Release Date", type: "dateRange", compValue: 'between' },
            { value: "gl_no_dev", text: "SAP/GL # Dev", type: "text"},
            { value: "gl_no_prod", text: "SAG Prod. ID", type: "text"},
            { value: "assigned_to", text: "Assigned To", type: "Search",optionName: "assignedToDetails"},
            { value: "signator", text: "Signator Name", type: "text", },
            { value: "project_notes", text: "Project Notes", type: "textArea" },
        ]
    },
    {
        type: "Locations", isExpand: false, filterCategories: [
            { value: "from_date", text: "From Date", type: "dateRange", compValue: 'between'  },
            { value: "to_date", text: "To Date", type: "dateRange",compValue: 'between' },
            { value: "location_name", text: "Location Name", type: "text"},
            { value: "comments", text: "Notes", type: "textArea" },
            { value: "email", text: "Email", type: "text"},
            { value: "phone", text: "Phone", type: "text"},
            { value: "address", text: "Address", type: "textArea" },
            { value: "city", text: "City", type: "text"},   
            { value: "state", text: "State/Province", type: "State", optionName:'stateDetails', compValue:'select'},
            { value: "country", text: "Country", type: "Select",optionName: "countryDetails", tableName: "Country" },
            { value: "postal_code", text: "Zip Code", type: "text", },               
        ]
    },
    {
        type: "Work Week", isExpand: false, filterCategories: [
            { value: "project_work_week_date", text: "Contains Date", type: "date", },
            { value: "days", text: "Total Days", type: "numRange",compValue: 'between' },             
        ]
    },
    {
        type: "Crew", isExpand: false, filterCategories: [
            { value: "crew_name", text: "Name/Alias", type: "text", },
            { value: "occupation", text: "Occupation", type: "Select", tableName: "crew_occupation", optionName: "crewOccupationDetails",  }, 
            { value: "email", text: "Email", type: "text"},
            { value: "phone", text: "Phone", type: "text"},  
            { value: "company", text: "Company", type: "text"},
            { value: "location_name", text: "Location", type: "text"},          
        ]
    },
    {
        type: "Status Date", isExpand: false, filterCategories: [
            { value: "from_date", text: "From Date", type: "dateRange", compValue: 'between'  },
            { value: "to_date", text: "To Date", type: "dateRange",compValue: 'between' },
            { value: "status", text: "Status", type: "Select",tableName: "status_dates_status", optionName: "statusDateStatusDetails",  }, 
            { value: "project_notes", text: "Status Notes", type: "textArea" },            
        ]   
    },
]

const performerInnerFilters = [
    {
        type: "Core", filterCategories: [
            { value: "talent_name", text: "Performer Name", type: "text"},//  tableName: "TALENT", optionName: "performerDetails", valueKey:'id',textKey:'talent_name'  },
            { value: "gender", text: "Gender", type: "Select", tableName: "Gender", optionName: "genderDetails",  },
            { value: "ethnicity", text: "Ethnicity", type: "Select", tableName: "Ethnicity", optionName: "ethnicityDetails",  },
            { value: "SSN", text: "SSN", type: "text"},
            { value: "birth_date", text: "Date of Birth", type: "dateRange", compValue: 'between'  },
            { value: "email", text: "Email", type: "text"},
            { value: "phone", text: "Phone", type: "text"}, 
        ]
    },
    {
        type: "Details", filterCategories: [
            { value: "selected_alias_name", text: "Aliases", type: "text",},
            { value: "talent_age", text: "Age", type: "numRange",compValue: 'between'  },
            { value: "death_date", text: "Date of Death", type: "dateRange", compValue: 'between'  },
            { value: "sag_id", text: "Sag Id", type: "text"},
            { value: "sag_status", text: "Sag Status", type: "Select", tableName: "SAG_STATUS", optionName: "sagStatusDetails",  },
            { value: "immigration_status", text: "Immigration Status", type: "Select", tableName: "IMMIGRATION_STATUS", optionName: "immigrationStatusDetails" },
            { value: "expiration_date", text: "Expiration Date", type: "dateRange", compValue: 'between'  },
            { value: "citizen_of", text: "Citizenship", type: "Select",tableName: 'Country',optionName: "countryDetails", },
            { value: "project_notes", text: "Talent Profile Notes", type: "textArea" }, 
        ]
    },
    {
        type: "Address", isExpand: false, filterCategories: [
            { value: "address", text: "Address", type: "textArea" }, 
            { value: "city", text: "City", type: "text"},
            { value: "state", text: "State/Province", type: "State", optionName:'stateDetails', compValue:'select'},
            { value: "country", text: "Country", type: "Select",tableName: 'Country',optionName: "countryDetails",  },
            { value: "zip", text: "Zip Code", type: "text", },          
        ]
    },
    {
        type: "Guardians", isExpand: false, filterCategories: [
            { value: "performer_deal_guardians", text: "Name", type: "text"},
            { value: "relation", text: "Type", type: "Select",optionName: "gRTypeDetails",tableName: "GUARDIANS_RELATION" },
            { value: "email", text: "Email", type: "text"},
            { value: "phone", text: "Phone", type: "text"},
            { value: "address", text: "Address", type: "textArea" }, 
            { value: "city", text: "City", type: "text"},
            { value: "state", text: "State/Province", type: "State", optionName:'stateDetails', compValue:'select'},
            { value: "country", text: "Country", type: "Select",optionName: "countryDetails",tableName: "Country"  },
            { value: "zip", text: "Zip Code", type: "text", },          
        ]
    },
    {
        type: "Linked Representation", isExpand: false, filterCategories: [
            { value: "selected_alias_name", text: "Representative Name/Alias", type: "text"},
            { value: "agency_name", text: "Agency Name/Alias", type: "text"},
            { value: "representative_occupation", text: "Occupation", type: "Select",optionName: "representativeOccupationDetails",tableName: "representative_occupation" },
            { value: "email", text: "Email", type: "text"},
            { value: "phone", text: "Phone", type: "text"},
            { value: "address", text: "Address", type: "textArea" }, 
            { value: "city", text: "City", type: "text"},
            { value: "state", text: "State/Province", type: "State", optionName:'stateDetails', compValue:'select'},
            { value: "country", text: "Country", type: "Select",optionName: "countryDetails",  tableName: "Country"},
            { value: "zip", text: "Zip Code", type: "text", },          
        ]
    },
    {
        type: "Deal Details", filterCategories: [
            { value: "role", text: "Role Name", type: "text",},
            { value: "deal_date", text: "Deal Date", type: "dateRange", compValue: 'between'  },
            { value: "production_company", text: "Production Company", type: "text"},// tableName: "PRODUCTION_COMPANIES", optionName: "prodCompDetails",valueKey:'production_company_id',textKey:'production_company'  },
            { value: "union", text: "Union", type: "Select", tableName: "UNION", optionName: "unionDetails",  },
            { value: "sag_status", text: "Sag Status", type: "Select", tableName: "SAG_STATUS", optionName: "sagStatusDetails",  },
            { value: "i9_status", text: "I9 Status", type: "Select", tableName: "I9_STATUS", optionName: "i9StatusDetails" },
            { value: "comments", text: "Performer Record Notes", type: "textArea" }, 
        ]
    },
    {
        type: "Credits", filterCategories: [
            { value: "performer_deal_credit_type", text: "Given Credits", type: "Search",  optionName: "givenCreditsDetails"},
            { value: "credit_mt_et_text", text: "Main Title / End Title Credit Text", type: "textArea" }, 
            { value: "credit_et_text", text: "End Title Credit Text", type: "textArea" },  
            { value: "credit_notes", text: "Billing Notes", type: "textArea" }, 
        ]
    },
    {
        type: "Billing", filterCategories: [
            { value: "confirmed_credit", text: "Confirmed Credit", type: "Search",  optionName: "confirmCreditsDetails"},
            { value: "credit_as", text: "Credited As", type: "text" }, 
            { value: "confirmed_by", text: "Confirmed By", type: "text" }, 
            { value: "project_notes", text: "Billing Notes", type: "textArea" }, 
        ]
    },
]

const contractInnerFilter = [
    {
        type: "Core", filterCategories: [
            { value: "performer_type", text: "Performer Type", type: "Select", optionName: "performerTypeDetails"}, 
            { value: "fee_type", text: "Fee Type", type: "Select", optionName: "feeTypeDetails",  },
            { value: "contract_type", text: "Contract Type", type: "Select", tableName: "CONTRACT_TYPE", optionName: "contractTypeDetails",  },
            { value: "rate", text: "Rate", type: "numRange", compValue: 'between' },
            { value: "guarentee_period", text: "Guarantee", type: "numRange", compValue: 'between',typeDropDown:'days'},
            { value: "start_date", text: "Start Date", type: "dateRange", compValue: 'between'  },
            { value: "total_amount", text: "Total Amount", type: "numRange", compValue: 'between'  },
        ]
    },
    {
        type: "Contract Details", filterCategories: [
            { value: "period", text: "Period", type: "Select", optionName: "periodDetails", tableName:'PERIOD'}, 
            { value: "plus_or_minus_period", text: "+/- (days/weeks)", type: "numRange", compValue: 'between', typeDropDown:'days', signType: '+'},
            { value: "principal_free_period", text: "Principal Free (days/weeks)", type: "numRange", compValue: 'between', typeDropDown:'days',  },
            { value: "post_free_period", text: "Post Free (days/weeks)", type: "numRange", compValue: 'between', typeDropDown:'days',  },
            { value: "basic_free_period", text: "Basic Free (days/weeks)", type: "numRange", compValue: 'between', typeDropDown:'days',  },
            { value: "sent_date", text: "Contract Sent", type: "dateRange", compValue: 'between'  },
            { value: "returned_date", text: "Contract Returned", type: "dateRange", compValue: 'between'  },
            { value: "revised_date", text: "Contract Revised", type: "dateRange", compValue: 'between'  },
            { value: "info", text: "Contract Info Text", type: "textArea"  },
            { value: "text", text: "Contract Text", type: "textArea"  },
            { value: "void", text: "Void", type: "Select", optionName: "voidDetails",}, 
        ]
    },
    {
        type: "Perks", filterCategories: [
            { value: "memo_type", text: "Memo Type", type: "Select", optionName: "memoTypeDetails", tableName:'MEMO_TYPE'}, 
            { value: "memo_text", text: "Memo Text", type: "textArea"  },
        ]
    },
    {
        type: "Work Activity", filterCategories: [
            { value: "work_date", text: "Work Date", type: "dateRange", compValue : 'between'}, 
            { value: "code", text: "Activity", type: "Search",  optionName: "activityTypeDetails",  },
            { value: "guarantee", text: "Guarantee Used", type: "numRange", compValue: 'between'  },
            { value: "principal", text: "Principal Free Used", type: "numRange", compValue: 'between' },
            { value: "post", text: "Post Free Used", type: "numRange", compValue: 'between',},
            { value: "basic", text: "Basic Free Used", type: "numRange", compValue: 'between',},
            { value: "total_days_worked", text: "Total Days Worked", type: "numRange", compValue: 'between'  },
            { value: "text", text: "Work Activity Text", type: "text"},
        ]
    },
]

export const allCriteriaConfig = [
    {
        type: "Projects", value: "Projects", filters: [
            {
                value: "Projects", text: "Projects", field: 'projects', isExpand: false, filterCategoryTypes: [...projectInnerFilter]
            },
            {
                value: "Performers", text: "Performers", field: 'performers', filterCategoryTypes: [...performerInnerFilters]
            },
        ]
    },
    {
        type: "Performers", value: "Performers", filters: [ 
            {
                value: "Performers", text: "Performers", field: 'performers', filterCategoryTypes:[...performerInnerFilters]
            },
            {
                value: "Contracts", text: "Contracts", field: 'contracts', filterCategoryTypes: [...contractInnerFilter]
            },
            {
                value: "Projects", text: "Projects", field: 'projects', filterCategoryTypes: [...projectInnerFilter]
            },
        ]
    },
    {
        type: "Contracts", value: "Contracts", filters: [ 
            {
                value: "Contracts", text: "Contracts", field: 'contracts', filterCategoryTypes:[...contractInnerFilter]
            },
            {
                value: "Performers", text: "Performers", field: 'performers', filterCategoryTypes: [...performerInnerFilters]
            },
            {
                value: "Projects", text: "Projects", field: 'projects', filterCategoryTypes: [...projectInnerFilter]
            },
        ]
    },
    {
        type: "People", value: "People", filters: [ 
            {
                value: "People", text: "People", field: 'people', filterCategoryTypes:[
                    {
                        type: "Core", isExpand: false, filterCategories: [
                            { value: "person_type", text: "Person Type", type: "Select", optionName: "personTypeOptions"},
                            { value: "name", text: "Name", type: "text", },
                            { value: "alias", text: "Aliases", type: "text", },
                            { value: "email", text: "Email", type: "text"},
                            { value: "phone", text: "Phone", type: "text"},
                            { value: "address", text: "Address", type: "textArea" }, 
                            { value: "city", text: "City", type: "text"},
                            { value: "state", text: "State/Province", type: "State", optionName:'stateDetails', compValue:'select'},
                            { value: "country", text: "Country", type: "Select",optionName: "countryDetails", tableName: "Country" },
                            { value: "zip", text: "Zip Code", type: "text", },  
                        ]
                    },

                ]
            },
            {
                value: "Projects", text: "Projects", field: 'projects', filterCategoryTypes: [projectInnerFilter[0],projectInnerFilter[1]]
            },
        ]
    },
    {
        type: "Representatives", value: "Representatives", filters: [ 
            {
                value: "Representative", text: "Representative", field: 'representative', filterCategoryTypes:[
                    {
                        type: "Core", isExpand: false, filterCategories: [
                            { value: "name", text: "Representative Name", type: "text"},//  tableName: "REPRESENTATIVE", optionName: "representativeDetails", valueKey:'id',textKey:'representative_name'  },
                            { value: "aliases", text: "Aliases", type: "text", },
                            { value: "occupation", text: "Occupation", type: "Select", tableName: "representative_occupation", optionName: "representativeOccupationDetails",  }, 
                            { value: "agency_name", text: "Agency", type: "text"},//  tableName: "AGENCY", optionName: "agencyDetails", valueKey:'id',textKey:'agency_name'  },
                            { value: "phone", text: "Phone", type: "text"}, 
                            { value: "email", text: "Email", type: "text"}, 
                        ]
                    },
                    {
                        type: "Address", isExpand: false, filterCategories: [ 
                            { value: "address", text: "Address", type: "textArea" }, 
                            { value: "city", text: "City", type: "text"},
                            { value: "state", text: "State/Province", type: "State", optionName:'stateDetails', compValue:'select'},
                            { value: "country", text: "Country", type: "Select",optionName: "countryDetails", tableName: "Country" },
                            { value: "zip", text: "Zip Code", type: "text", }, 
                        ]
                    },
                ]
            },
            {
                value: "Linked Performer", text: "Linked Performer", field: 'linked_performer', filterCategoryTypes:[...performerInnerFilters]
            }           
        ]
    },
    {
        type: "Crew", value: "Crew", filters: [ 
            {
                value: "Crew", text: "Crew", field: 'crew', filterCategoryTypes:[
                    {
                        type: "Core", isExpand: false, filterCategories: [
                            { value: "crew_id", text: "Crew Name", type: "text"},//  tableName: "CREW", optionName: "crewDetails", valueKey:'id',textKey:'crew_name'  },
                            { value: "crew_aliases", text: "Aliases", type: "text", },
                            { value: "occupation", text: "Occupation", type: "Select", tableName: "crew_occupation", optionName: "crewOccupationDetails",  }, 
                            { value: "location_name", text: "Location", type: "text"}, 
                            { value: "social_media_type", text: "Social Media Type", type: "Select",  tableName: "Social", optionName: "socialMedialDetails"},
                            { value: "social_media_handle", text: "Social Media Handle", type: "text"},
                            { value: "crew_assistant", text: "Assistant Name/Alias", type: "text"},//  tableName: "CREW_ASSISTANT", optionName: "crewAsstDetails", valueKey:'assistant_id',textKey:'crew_assistant'  },
                            { value: "phone", text: "Phone", type: "text"},
                            { value: "email", text: "Email", type: "text"},  
                        ]
                    },
                    {
                        type: "Address", isExpand: false, filterCategories: [ 
                            { value: "address", text: "Address", type: "textArea" }, 
                            { value: "city", text: "City", type: "text"},
                            { value: "state", text: "State/Province", type: "State", optionName:'stateDetails', compValue:'select'},
                            { value: "country", text: "Country", type: "Select",optionName: "countryDetails",tableName: "Country"  },
                            { value: "zip", text: "Zip Code", type: "text", }, 
                        ]
                    },
                ]
            },
            {
                value: "Projects", text: "Projects", field: 'projects', filterCategoryTypes:[...projectInnerFilter]
            }           
        ]
    },
    {
        type: "Companies", value: "Companies", filters: [ 
            {
                value: "Companies", text: "Companies", field: 'companies', filterCategoryTypes:[
                    {
                        type: "Core", isExpand: false, filterCategories: [
                            { value: "company_name", text: "Company Name", type: "text"},//  tableName: "AGENCY", optionName: "agencyDetails", valueKey:'id',textKey:'agency_name'  },
                            { value: "aliases", text: "Aliases", type: "text", },
                            { value: "occupation", text: "Occupation", type: "Select", tableName: "agency_occupation", optionName: "agencyOccupationDetails",  }, 
                            { value: "social_media_type", text: "Social Media Type", type: "Select",  tableName: "social", optionName: "socialMedialDetails"},
                            { value: "social_media_handle", text: "Social Media Handle", type: "text"},
                            { value: "project_notes", text: "Notes", type: "textArea"},
                        ]
                    },
                    {
                        type: "Locations", isExpand: false, filterCategories: [
                            { value: "location_name", text: "Location Name", type: "text"},
                            { value: "phone", text: "Location Phone", type: "text"},
                            { value: "address", text: "Address", type: "textArea" },
                            { value: "city", text: "City", type: "text"},   
                            { value: "state", text: "State/Province", type: "State", optionName:'stateDetails', compValue:'select'},
                            { value: "country", text: "Country", type: "Select",optionName: "countryDetails", tableName: "Country" },
                            { value: "postal_code", text: "Zip Code", type: "text", }, 
                            //{ value: "comments", text: "Company Notes", type: "textArea" },              
                        ]
                    },
                ]
            },
            {
                value: "Linked Representation", text: "Linked Representation", field: 'linked_representation', filterCategoryTypes:[
                    {
                        type: "Core", isExpand: false, filterCategories: [
                            { value: "name", text: "Representative Name/Alias", type: "text", },
                            { value: "occupation", text: "Occupation", type: "Select", tableName: "representative_occupation", optionName: "representativeOccupationDetails",  }, 
                            { value: "phone", text: "Phone", type: "text"}, 
                            { value: "email", text: "Email", type: "text"}, 
                            { value: "city", text: "City", type: "text"},
                            { value: "state", text: "State/Province", type: "State", optionName:'stateDetails', compValue:'select'},
                            { value: "country", text: "Country", type: "Select",optionName: "countryDetails", tableName: "Country" },
                            { value: "zip", text: "Zip Code", type: "text", }, 
                        ]
                    },
                ]
            },
            {
                value: "Linked Performer", text: "Linked Performer", field: 'linked_performer', filterCategoryTypes:[...performerInnerFilters]
            }          
        ]
    },    
];



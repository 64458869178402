import React, { Component } from 'react';
import Worksheet from './Worksheet';
import SagTimeReport from './SagTimeReport';
import Station12 from "./Station12";
import { withUserContext } from "../../../contexts/UserContext";
import { withReportContext } from "../../../contexts/ReportContext";
import { withNotificationContext } from "../../../contexts/NotificationContext";
import { intialReportDetails } from './Config';
import './Reports.scss'
import ResidualsPackage from './ResidualsPackage';
import TalentVoucher from './TalentVoucher';
import EPTimesheet from './EPTimesheet';
import EPCoversheet from './EPCoversheet';
import Contract from './Contract';
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { getFormattedDate } from "../../Common/Helper";

class ReportContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            reportDetails: {
                0: JSON.parse(JSON.stringify({ ...intialReportDetails })),
                1: JSON.parse(JSON.stringify({ ...intialReportDetails })),
                2: JSON.parse(JSON.stringify({ ...intialReportDetails })),
                3: JSON.parse(JSON.stringify({ ...intialReportDetails })),
                4: JSON.parse(JSON.stringify({ ...intialReportDetails })),
                5: JSON.parse(JSON.stringify({ ...intialReportDetails })),
                6: JSON.parse(JSON.stringify({ ...intialReportDetails })),
                7: JSON.parse(JSON.stringify({ ...intialReportDetails })),
                8: JSON.parse(JSON.stringify({ ...intialReportDetails })),
              },
            postInitiated: false,
            showOption:[],
            seasonOption:[],
            episodeOption:[],
            performerOption:[],
            selectedReportType:null,
        }
    }

    componentDidMount() {
        this.getShow("SHOW","");
        if(this.props.reportContext){
            this.getSeason("SHOW_SEASON", this.props.reportContext?.show_id?.value, "");
            this.getEpisode("SEASON_EPISODES", this.props.reportContext?.show_season_id, "");
            this.getPerformer("PERFORMER_CONTRACTS", "", this.props.reportContext?.episode_id);
            Object.keys(this.state.reportDetails).map(
                i => this.setState((prevState) => ({
                    reportDetails: {
                        ...prevState.reportDetails,
                        [i]: {
                            ...prevState.reportDetails[i],
                            ['episode_id']: this.props.reportContext?.episode_id || null,
                            ['performer_id']: this.props.reportContext?.performer_id || null,
                            ['seasonName']: this.props.reportContext?.seasonName || null,
                            ['show_id']: this.props.reportContext?.show_id || null,
                            ['show_season_id']: this.props.reportContext?.show_season_id || null,
                        },
                    }
                }))
            )
        }
    }

    getShow = (entity,searchString) => {
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetEntitySearch?entity=${entity}&searchString=${searchString}`,
            this.props.userContext?.active_tenant?.tenant_id
        )
            .then(response => {
                let formattedList = response?.data?.map(item => ({
                    text: item?.show_name,
                    value: item.show_id,
                }));
                this.setState({ showOption: formattedList });
            },
                (err) => {
                    console.log(err, "error in option")
                })
    }

    getSeason = (entity,showId,searchString) => {
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetEntitySearch?entity=${entity}&showId=${showId}&searchString=${searchString}`,
            this.props.userContext?.active_tenant?.tenant_id
        )
            .then(response => {
                let formattedList = response?.data?.map(item => ({
                    label:`${item?.season_name||''} (${item?.start_year||''}-${item?.end_year||''})`,
                    value: item.show_season_id,
                }));
                this.setState({ seasonOption: formattedList });
            },
                (err) => {
                    console.log(err, "error in option")
                })
    }

    getEpisode = (entity,showSeasonId,searchString) => {
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetEntitySearch?entity=${entity}&showSeasonId=${showSeasonId}&searchString=${searchString}`,
            this.props.userContext?.active_tenant?.tenant_id
        )
            .then(response => {
                let formattedList = response?.data?.map(item => ({
                    label: `${item.gl_code||''}-${item?.episode_name||''}`,
                    value: item.episode_id,
                }));
                this.setState({ episodeOption: formattedList });
            },
                (err) => {
                    console.log(err, "error in option")
                })
    }
    
    getPerformer = (entity,searchString,episodeId) => {
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetEntitySearch?entity=${entity}&searchString=${searchString}&episodeId=${episodeId}`,
            this.props.userContext?.active_tenant?.tenant_id
        )
            .then(response => {
                let formattedList = response?.data?.map(item => ({
                    label: item?.performer_name + " ("+getFormattedDate(item?.contract_date)+")",
                    value: item?.performer_id,
                    contract_type:item?.contract_type,
                    performer_id:item?.performer_id,
                    contract_id:item?.contract_id,
                    contract_date: item?.contract_date
                }));         
                     this.setState({ performerOption: formattedList });
            },
                (err) => {
                    console.log(err, "error in option")
                })
    }
    static getDerivedStateFromProps(props, state) {
        if (props.tabValue !== state.tabValue) {
            return {
                tabValue: props.tabValue,
            }
        }
        return null
    }

    handleGeneralEdit = (field, value) => {
        if(field === "Contract Only" || field === "Packet" || field ==="With Talent Voucher" ){
            this.setState({ selectedReportType: field });
        }else if(!this.state.selectedReportType){
            this.setState({ selectedReportType: "Contract Only" });
        }
        const { tabValue } = this.state;
        let newValue = value;
        this.setState((prevState) => ({
          reportDetails: {
            ...prevState.reportDetails,
            [tabValue]: {
              ...prevState.reportDetails[tabValue],
              [field]: newValue,
            },
          },
          postInitiated: false,
        }), () => {
          if (field === "show_id") {
            let newValue1 = newValue ? newValue.value : null;
            this.getSeason("SHOW_SEASON", newValue1, "");
          } else if (field === "show_season_id") {
            this.getEpisode("SEASON_EPISODES", newValue, "");
          } else if (field === "episode_id") {             
            this.getPerformer("PERFORMER_CONTRACTS", "", newValue);
          }
        });
      };
      
    getTabComponent = () => {
        switch (this.props.tabValue) {
            case 0: return <Worksheet
                reportDetails={this.state.reportDetails[0]}
                handleGeneralEdit={this.handleGeneralEdit}
                reportButtonShow={true}
                showOption={this.state.showOption}
                seasonOption ={this.state.seasonOption}
                episodeOption={this.state.episodeOption}
                performerOption={this.state.performerOption}
                handleClose={this.props.handleClose}
                tabValue={this.state.tabValue}
                getShow={this.getShow}
                preSelectedReportDetails={this.props.reportContext}
            />
            case 1: return <Station12
                reportDetails={this.state.reportDetails[1]}
                handleGeneralEdit={this.handleGeneralEdit}
                showOption={this.state.showOption}
                seasonOption ={this.state.seasonOption}
                episodeOption={this.state.episodeOption}
                performerOption={this.state.performerOption}
                handleClose={this.props.handleClose}
                getShow={this.getShow}
                preSelectedReportDetails={this.props.reportContext}
            />
            case 2: return <SagTimeReport
                reportDetails={this.state.reportDetails[2]}
                handleGeneralEdit={this.handleGeneralEdit}
                showOption={this.state.showOption}
                seasonOption ={this.state.seasonOption}
                episodeOption={this.state.episodeOption}
                performerOption={this.state.performerOption}
                handleClose={this.props.handleClose}
                tabValue={this.state.tabValue}
                getShow={this.getShow}
                preSelectedReportDetails={this.props.reportContext}
            />
            case 3: return <Worksheet
                reportDetails={this.state.reportDetails[3]}
                handleGeneralEdit={this.handleGeneralEdit}
                showOption={this.state.showOption}
                seasonOption ={this.state.seasonOption}
                episodeOption={this.state.episodeOption}
                performerOption={this.state.performerOption}
                handleClose={this.props.handleClose}
                tabValue={this.state.tabValue}
                getShow={this.getShow}
                preSelectedReportDetails={this.props.reportContext}
            />
            case 4: return <ResidualsPackage
                reportDetails={this.state.reportDetails[4]}
                handleGeneralEdit={this.handleGeneralEdit}
                showOption={this.state.showOption}
                seasonOption ={this.state.seasonOption}
                episodeOption={this.state.episodeOption}
                performerOption={this.state.performerOption}
                handleClose={this.props.handleClose}
                getShow={this.getShow}
                preSelectedReportDetails={this.props.reportContext}
            />
            case 5: return <TalentVoucher
                reportDetails={this.state.reportDetails[5]}
                handleGeneralEdit={this.handleGeneralEdit}
                talentVoucherShow={true}
                showOption={this.state.showOption}
                seasonOption ={this.state.seasonOption}
                episodeOption={this.state.episodeOption}
                performerOption={this.state.performerOption}
                handleClose={this.props.handleClose}               
                getShow={this.getShow}
                preSelectedReportDetails={this.props.reportContext}
            />
            case 6: return <Contract
                reportDetails={this.state.reportDetails[6]}
                handleGeneralEdit={this.handleGeneralEdit}
                showOption={this.state.showOption}
                seasonOption ={this.state.seasonOption}
                episodeOption={this.state.episodeOption}
                performerOption={this.state.performerOption}
                selectedReportType = {this.state.selectedReportType}
                handleClose={this.props.handleClose}
                getShow={this.getShow}                
                preSelectedReportDetails={this.props.reportContext}
            />
            case 7: return <EPTimesheet
                reportDetails={this.state.reportDetails[7]}
                handleGeneralEdit={this.handleGeneralEdit}
                talentVoucherShow={true}
                showOption={this.state.showOption}
                seasonOption ={this.state.seasonOption}
                episodeOption={this.state.episodeOption}
                performerOption={this.state.performerOption}
                handleClose={this.props.handleClose}
                getShow={this.getShow}
             
                preSelectedReportDetails={this.props.reportContext}
            />

            case 8: return <EPCoversheet
                reportDetails={this.state.reportDetails[8]}
                handleGeneralEdit={this.handleGeneralEdit}
                showOption={this.state.showOption}
                seasonOption ={this.state.seasonOption}
                episodeOption={this.state.episodeOption}
                performerOption={this.state.performerOption}
                handleClose={this.props.handleClose}
                tabValue={this.state.tabValue}
                getShow={this.getShow}
                preSelectedReportDetails={this.props.reportContext}
            />

        }
    }
    render() {
        return (
            <div className="allcasterReportContainer">
                {this.getTabComponent()}
            </div>
        );
    }

}
export default withUserContext(withNotificationContext(withReportContext(ReportContainer)));
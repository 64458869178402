import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import TableComponent from "../../components/SharedComponents/Table";
import TablePagination from "@material-ui/core/TablePagination";
import {
  handlePaginationList,
  updateTableSortConfigObject,
  tableSortList,
} from "../../Common/TableHelper";
import "./LandingPage.scss";
import { projectConfig } from "./LandingPageConfig";
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import LandingPageAdmin from "./LandingPageAdmin";
import MainViewModalComponent from "../../Common/MainViewModalLayout/Modal/Modal";
import Popover from '@material-ui/core/Popover';
import { withRouter } from "react-router-dom";
import { withUserContext } from "../../../contexts/UserContext";
import axios from 'axios';
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import  BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import Project from "../Project/Project";
import { Link } from 'react-router-dom';


class LandingPageTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 10,
      setNewProjectModal: false,
      page: 0,
      renderList: [],
      projectsList: [],
      optionsName: [],
      selectedShow: null,
      config: projectConfig,
      isLoading: false,
      lastAccessedProjectName: "",
      isEditModalOpen: false,
      mode: null,
      id: "",
      isCopyModalOpen: false,
      sortBy: null,
      isSortByAsc: true,
      sortCount: 0,
      fileFieldpopover: false,
      fileFieldValue: null,
      lastAccessedProjectId: '',
      searchValue:null,
      searchProjectId: null,
      episodeOptions:[],
      episodeId: null,
      isEpisodeFlage: false,
      selectedGlCode: null,
    };
  }

  componentDidMount = () => {
    this.getLandingPageTableData();
  };


  handleFavIcon = (item, id) => {
    this.setState({ isTableLoading: true });
    const data = { project_id: item.project_id};
    if(item.is_favorite === 1){
      data["is_favorite"] = 0
    }else if(item.is_favorite === 0){
      data["is_favorite"] = 1
    }else if(item.is_favorite === true){
      data["is_favorite"] = 0
    }else{
      data["is_favorite"]= 1
    }
    CasterService.postDataParams(
      Constants.CasterServiceBaseUrl + "/casterTvJwpUpdateFavoriteShow",data, this.props.userContext?.active_tenant?.tenant_id,
    )
      .then((res) => {this.getLandingPageTableData()})
      .catch((error) => {
        this.setState({ isTableLoading: false });
        console.log(error, "fav error");
      });
  }


  getLandingPageTableData = () => {
    this.setState({ isFetchingTableData: true });
    const myObject = {
      order_by_clause: this.state.sortBy,
      order_by: (this.state.isSortByAsc === true ? 'DESC' : 'ASC'),
      search_text: this.state.searchValue,
      page_count: this.state.rowsPerPage,
      page_no: this.state.page + 1,
      project_id: this.state.searchProjectId
    };
    const base64String = btoa(JSON.stringify(myObject));
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterTvJwpDashboardLandingDetails?input=${base64String}`,
      this.props?.userContext?.active_tenant?.tenant_id,
      true
    )
      .then((res) => {
        let tableData = res.data.records;

        const tableDataWithFavorite = tableData.map(row => ({
          ...row,
       //is_favorite: localStorage.getItem(`project_${row.project_id}_is_favorite`) === '1',
        }));

        // const sortData = [...tableDataWithFavorite].sort((a, b) => b.is_favorite - a.is_favorite);
        let favSortData=tableData.map(item=>{item['is_favorite'] = item?.is_favorite === 1 ? true : false;
         return item;})
        this.setState({
          renderList: handlePaginationList(0, this.state.rowsPerPage, favSortData),
          projectsList: res.data.query_record_count,
          isFetchingTableData: false,
          lastAccessedProjectId: res.data.last_accessed_project_id,
          lastAccessedProjectName: res.data.last_accessed_project_name,
          isEditModalOpen: false,
          isLoading: false,
          isTableLoading: false
        }, () => {
          // this.handleTableColumnClick("Project");
        });
        this.handleCopyModalClose();
        this.handleEditModalClose();
      })
      .catch((error) => {
        this.setState({ isFetchingTableData: false });
        console.log(error, "error in Landingpagetable get method");
      });
  };

  handleEditModalClose = () => {
    this.setState({ isEditModalOpen: false });
  };

  handleCopyModalClose = () => {
    this.setState({ isCopyModalOpen: false });
  };

  handleCopyModal = (mode, id) => {
    this.setState({ id: id, isCopyModalOpen: true, mode: mode })
  }

  handleInlineEdits = (mode, id) => {
    this.setState({ id: id, isEditModalOpen: true, mode: mode })
  }

  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      })
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })
  }

  handleTableSort = (colName) => {
    this.setState({
      isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
      config: updateTableSortConfigObject(JSON.parse(JSON.stringify({ ...projectConfig })), this.state.isSortByAsc, this.state.sortBy, colName),
    }, () => {
      let list = tableSortList("Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
      this.setState({
        sortBy: colName,
        page:0,
        renderList: list
      },()=>{  this.getLandingPageTableData();});
    })
  }

  getSortNode = (col) => {
    switch (col) {
      case "Project": return "project_name";
      case "Show season Year": return "season_year";
      case "Outlet": return "outlet_name";
      case "Type": return "project_type_name";
      case "Episodes": return "episode_count";
    }
  }

  navigationCallback = (item, node) => {
    if (node == 'project_name') {
      this.props.history.push(`/caster-tv-jwp/project-view/prid/${item.project_id}`)
    }
  }

  handleFileField = (item, node) => {
    if (node === 'project_notes') {
      this.setState({ fileFieldpopover: true, fileFieldValue: item.project_notes })
    }
  }

  handleClickModal = (event) => {
    this.setState({
      fileFieldpopover: event.currentTarget
    })
  };

  handleCloseModal = () => {
    this.setState({
      fileFieldpopover: null
    })
  };


  setJson = (field, value) => {
    switch (field) {
      case 'pagePerRow':
        this.setState({ rowsPerPage: value, page: 0 }, () => {
          this.getLandingPageTableData();
        });
        break;
      case 'page':
        this.setState({ page: value }, () => {
          this.getLandingPageTableData();
        });
        break;
    }
  }

  fetchSearchDetails = (value) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    if (value !== "") {
      let id = this.state.searchProjectId
      CasterService.getDataWithCancel(
        Constants.CasterServiceBaseUrl +
        `/casterTvJwpEntitySearch?entity=${"project"}&searchString=${value}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
        .then(response => {
          let projectTypeName = response?.data?.map((item) => item.project_type_name)
          const searchString = value 

          let filteredData = response?.data?.filter(item => item.name.toLowerCase().includes(searchString.toLowerCase()));
          let glCodeData = response?.data?.filter(item => item.sap_gl_code.toLowerCase().includes(searchString.toLowerCase()));
          let formattedList=[];
          let glFormattedList=[];
          let projectId = null
          if(filteredData.length !== 0){
            projectId=filteredData[0].id
          }else if(glCodeData.length !== 0){
            projectId=glCodeData[0].id
          }
           console.log("filteredData",filteredData,glCodeData,projectId)
          this.setState({searchProjectId: id, searchValue: value},()=>{
            this.getLandingPageTableData()
          })
          },
          (err) => {
            console.log(err, "error in option")
          })
    } else {
      this.setState({ optionsName: [] });
    }
  }

  fetchGlCodeDetails = (value) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    if(value !== "") {
      let id = this.state.searchProjectId
      CasterService.getDataWithCancel(
        Constants.CasterServiceBaseUrl +
        `/casterTvJwpEntitySearch?entity=${"gl_code"}&searchString=${value}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
        .then(response => {
          let formattedList = response?.data?.map(item => ({
              text: item?.gl_code,
              value: item.episode_id,
              project_id: item.project_id,
              episodeItem: item
          }));
          this.setState({ episodeOptions: formattedList, episodeList: response.data },()=>{
            
          });
      },
          (err) => {
              console.log(err, "error in option")
          })
    } else {
      this.setState({ optionsName: [] });
    }
  }
  handleSelectedShow = (newValue) => {
    if (newValue) {
      this.setState({
        selectedShow: newValue,
        page: 0,
      })
    } else {
      this.setState({searchValue:null,selectedShow: null,searchProjectId:null},()=>this.getLandingPageTableData())
      
    }
  };


  handleshowNewProjectModal = () => {
    this.setState({ setNewProjectModal: true });
  };

  hideNewProjectModal = () => {
    this.setState({ setNewProjectModal: false });
  };

  
  getProjectEpisodes = (projectId, seasonId) => {
    CasterService.getData(
        Constants.CasterServiceBaseUrl +
        `/casterTvJwpEpisode?entityType=${gl_code}`,
        this.props.userContext?.active_tenant?.tenant_id
    )
        .then(response => {
            let formattedList = response?.data?.map(item => ({
                label: item?.gl_code,
                value: item.episode_id,
                project_id: item.project_id,
                episodeItem: item
            }));
            this.setState({ episodeOptions: formattedList, episodeList: response.data },()=>{
            });
        },
            (err) => {
                console.log(err, "error in option")
            })
  }

  handleEpisodeOnChange=(newValue)=>{
    if (newValue) {
      let project = this.state.episodeOptions?.find(item => item.value === newValue.value)
            let list = this.state.episodeList?.find(item=> item.episode_id === project.value)
            this.setState({isEpisodeFlage: true},()=>{
              this.props.history.push({    // no need
                pathname: `/caster-tv-jwp/project-view/prid/${list.project_id}`,
                state: this.state.isEpisodeFlage,list
              });
              // this.props.history.push(`/caster-tv-jwp/project-view/prid/${project.project_id}`,this.state.isEpisodeFlage,project);
            })
            // <Project />
    } else {
      this.setState({searchValue:null,selectedGlCode: null,searchProjectId:null},()=>this.getLandingPageTableData())
      
    }
  }

  handleProjectChange=(value)=>{
    if(value === ""){
      this
    }
  }

  render() {
    const className = this.state.fileFieldValue === null ? "fa-file-grey" : "fa-file";
    let openModal = Boolean(this.state.fileFieldpopover);
    let idModal = openModal ? 'modal-popover' : undefined;
    const url = `/caster-tv-jwp/project-view/prid/${this.state.lastAccessedProjectId}`

    const tableDataWithFavorite = this.state.renderList.map(row => ({
      ...row,
      // is_favorite: localStorage.getItem(`project_${row.project_id}_is_favorite`) === '1',
    }));

    const sortData = [...tableDataWithFavorite].sort((a, b) => b.is_favorite - a.is_favorite);
    return (
      <>
        {this.state.isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <div className="LandingContainer">
            <MDBRow>
              <MDBCol xs={6} md={6} sm={4}>
                <span className="landingPageUserName">
                  Welcome,{`${this.props?.userContext?.user_profile?.name ? (' ' + this.props?.userContext?.user_profile?.name) : null}`}
                  </span>
              </MDBCol>
              <MDBCol xs={6} md={6} sm={4}>
                <span className="floatRight">
                  <span className="firstLabel">Most Recently Viewed</span>
                  <br />
                  {this.state.lastAccessedProjectName !== null ?
                    <span className="floatRight">
                      <Link to={url}>{`${this.state.lastAccessedProjectName}`}</Link>
                    </span>
                    : ''
                  }
                </span>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={3} className="m-top-project">
                {/* <SearchSelectField
                  loading={this.state.isLoading}
                  id={"program"}
                  placeholder={"Search For a Project or GL Code"}
                  options={this.state.optionsName || []}
                  width={'100%'}
                  detail_selected={this.state.selectedShow}
                  valueSelected={(e, value) => this.handleSelectedShow(value)}
                  searchText={this.fetchSearchDetails}
                  multiple={false}
                  searchSelect={true}
                /> */}
                <BasicTextField 
                 value={this.state.selectedShow}
                 onKeyUp={(e) => {
                   if (e.key === "Enter") {
                      this.fetchSearchDetails(e.target.value)
                   
                   }
       
                 }}
                 onChange={(e) => this.handleSelectedShow(e.target.value)}
                />
              </MDBCol>
              <MDBCol md={2} className="search-program display-flex field-style">
              <SearchSelectField
                  loading={this.state.isglLoading}
                  id={"program"}
                  placeholder={"Search For Episode GL Code"}
                  options={this.state.episodeOptions || []}
                  width={'100%'}
                  detail_selected={this.state.selectedGlCode}
                  valueSelected={(e, value) => this.handleEpisodeOnChange(value)}
                  searchText={this.fetchGlCodeDetails}
                  multiple={false}
                  searchSelect={true}
                />
                    </MDBCol>
              <MDBCol md={3} className={"newProjectButton"}>
                <BasicButton
                  variant="contained"
                  type="inline"
                  className="outlined-btn"
                  text="New Project"
                  icon={"plus-circle"}
                  onClick={this.handleshowNewProjectModal}
                />
              </MDBCol>
              <MDBCol  className="HeaderSearchField">
                <span className="floatRight sortPadding">
                </span>
              </MDBCol>
            </MDBRow>
            <MDBRow className="projectTableComponet">
            {this.state.isTableLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
            ) : (
              <MDBContainer>
                <MDBRow className="g-0" id={"project-table"}>
                  <TableComponent
                    list={this.state.renderList || []}
                    isLoading={this.state.isFetchingTableData}
                    config={this.state.config}
                    editCallback={this.handleInlineEdits.bind(this, "Edit")}
                    editCopyCallback={this.handleCopyModal.bind(this, "Copy")}
                    sortCallback={this.handleTableColumnClick}
                    hyperLinkNavigationCallback={(item, node) => this.navigationCallback(item, node)}
                    fileFieldCallback={(item, node) => this.handleFileField(item, node)}
                    favIconCallback={(item, id) => this.handleFavIcon(item, id)}
                    className={className}
                  />
                </MDBRow>
                <MDBRow className="Pagination">
                  <TablePagination
                    component="div"
                    count={
                      this.state.projectsList
                    }
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    page={this.state.page}
                    onChangePage={(e, page) => {
                      this.setJson('page', page)
                    }}
                    rowsPerPage={this.state.rowsPerPage}
                    onRowsPerPageChange={(e) => {
                      this.setJson('pagePerRow', e.target.value)
                    }}
                  />
                </MDBRow>
              </MDBContainer>
            )}
            </MDBRow>
          </div>
        )}
        {this.state.isTableLoading && this.state.isEditModalOpen ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) :
          (
            <>
              <MainViewModalComponent
                open={this.state.isEditModalOpen}
                handleClose={() => this.handleEditModalClose()}
                headerText={"Project"}
                modalName={"New Project"}
                mode={this.state.mode}
                id={this.state.id}
                getLandingPageTableData={this.getLandingPageTableData}
                handleEditModalClose={this.handleEditModalClose}
              />
            </>
          )
        }
        {this.state.isTableLoading && this.state.isCopyModalOpen ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <>
            <MainViewModalComponent
              open={this.state.isCopyModalOpen}
              handleClose={() => this.handleCopyModalClose()}
              headerText={"to new Project"}
              modalName={"New Project"}
              mode={this.state.mode}
              id={this.state.id}
              getLandingPageTableData={this.getLandingPageTableData}
              handleCopyModalClose={this.handleCopyModalClose}
            />
          </>
        )}
        {this.state.fileFieldpopover && this.state.fileFieldValue !== null &&
          <div className="popover-text">
            <Popover
              id={idModal}
              open={openModal}
              className={"popover-text"}
              anchorEl={this.state.fileFieldpopover}
              onClose={this.handleCloseModal}
              anchorReference="anchorOrigin"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              getContentAnchorEl={null}
              PaperProps={{
                style: {
                  width: '13%', borderRadius: '1px', left: '1000px', top: '1000px',
                  marginTop: '-130px', marginLeft: "-55px"
                }
              }}
            >
              {`${this.state.fileFieldValue}`}
            </Popover>
          </div>
        }
        {this.state.setNewProjectModal && (
          <MainViewModalComponent
            open={this.state.setNewProjectModal}
            handleClose={this.hideNewProjectModal}
            headerText={"Project"}
            mode={"New"}
            modalName={"New Project"}
            getLandingPageTableData={() => this.getLandingPageTableData()}
          />
        )}
      </>
    );
  }
}

export default withRouter(withUserContext(LandingPageTable)); 

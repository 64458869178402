export const DemoParentTabs = [
  {
    label: "Existing Deal Memos",
    value: 1,
  },
  {
    label: "Create a Deal Memo",
    value: 2,
  },
];

export const DealMemoDateType = [
  {
    label:"Firm",
    value:"isFirm"
  },
  {
    label:"On/About",
    value:"onAbout"
  },
  {
    label:"Soft Work Window (+/-)5 days",
    value:"softWorkWindow(+/-)5days"
  }
]

export const DealMemoPickUpDrops = {
  oneX : '1st PickUp Contract',
  twoX : '2nd PickUp Contract',
  threeX : '3rd PickUp Contract'
}

export const DealMemoPickUpDropscount = {
  oneX : 1,
  twoX : 2,
  threeX : 3
}


export const createDealMemoRecords = {
  talentDetails: {
    talent_name: null,
    first_name: null,
    last_name: null,
    role: null,
    role_type_id: null,
    role_type: null,
    preferred_pronouns: null,
    talent_email: null,
    ssn: null,
    loanout: null,
    adress: null,
    cell_no: null,
    federal_id_no: null,
    is_minor: null,
  },
  guardianDetails: {
    guardian_name: null,
    guardian_email: null,
    guardian_phone: null,
    // minor_dob: null,
    coogan_account: null,
    has_work_permit: null,
    // expiration_date: null,
  },
  representativeDetails: {
    agency: null,
    agent: null,
    rep_office_number: null,
    rep_email: null,
    rep_cell_number: null,
    management_company: null,
    management_office_number: null,
    manager: null,
    mc_email: null,
    mc_cell_number: null,
    mc_office_number: null
  },
  dealPointDetails: {
    // start_date: null,
    rate: null,
    per: null,
    per_period_id: null,
    guarantee: null,
    guarantee_episodes: null,
    contract_type: null,
    contract_type_name:null,
    out_of_episodes: null,
    is_drop_pickup: null,
    drop_pickup_count:null,
    drop_pickup_total:null,
    final_recal_date: null,
    billing: null,
    dressing: null,
    travel_accommodations: null,
    to_be_billed_as: null,
    additional_terms: null,
    work_permit: null,
    is_on_about: null,
    contract_no: null,
    drop_deal_memo_id: null,
    is_10_percentage: 0,
    is_frim_check: 0,
    dealPointDetails:0,
    dateType:null
  },
};

export const talenInfoRecords = {
  talent_first_name: null,
  talent_last_name: null,
  role_name: null,
  role_id: null,
  role_type: null,
  preferred_pronouns: null,
  email: null,
  social_security: null,
  loanout: null,
  adress: null,
  cell_number: null,
  federal_no: null,
  minor: null,
};

export const representativeInfoRecord = {
  agency_name: null,
  agent_name: null,
  office_number: null,
  email: null,
  cell_number: null,
  management_office: null,
  management_office_number: null,
  manger_name: null,
  mangement_email: null,
  management_cell_number: null,
};

export const dealPoints = {
  // start_Date: "",
  rate: null,
  per: null,
  days_id: null,
  guarantee: null,
  no_of_guarantee_episode: null,
  contact_type: null,
  drop_and_pick_up_deal: null,
  final_recal_date: null,
  billing: null,
  dressing: null,
  travel_accomodation: null,
  to_be_billed_as: null,
  additional_terms: null,
  work_permit: null,
};

export const GuardianInfoRecord = {
  guardian_name: null,
  guardian_email: null,
  guardian_phone: null,
  minor_dob: null,
  coogan_account: null,
  work_permit: null,
  expiration_date: null,
};

export const existingDemoConfig = {
  headings: [
    {
      headingLabel: "Role#",
      width: "10%",
    },
    {
      headingLabel: "Role Name",
      width: "10%",
    },
    {
      headingLabel: "Talent",
      icon: "",
      width: "10%",
    },
    {
      headingLabel: "To Be Billed As",
      width: "10%",
    },   
    {
      headingLabel: "Role Type",
      width: "10%",
    },
    {
      headingLabel: "Start Date",
      icon: "",
      width: "10%",
    },
    {
      headingLabel: "Contract Type",
      width: "10%",
    },
    {
      headingLabel: "Rate/Guarantee",
      width: "10%",
    },
    {
      headingLabel: "Last Revised Date",
      icon: "",
      width: "10%",
    },
    {
      headingLabel: " ",
      width: "11%",
    },
    {
      headingLabel: " ",
      width: "10%",
    },
  ],
  dataNodes: [
    "role_id",
    "role",
    "talent_name",
    "to_be_billed_as",
    "role_type_name",
    "start_date",
    "contract_type_name",
    "rate",
    "updated_at",
    "DealMemo",
    "Contract"
  ],
  primaryKey: "deal_memo_id",
  hyperlinks: ["role"],
  buttonWithTextAndIcon: ["DealMemo", "Contract"],
};

export const existingDemoRenderList = [
  {
    role_name: "Bartender",
    talent: "Amy Jones",
    to_be_billed_as: "Amy Jones II",
    role_type: "Recurring",
    start_Date: "12/05/2023",
    contact_type: "3-day",
    rate_guarantee: "$1,200.00, 2 week guarantee",
  },
  {
    role_name: "Johnson",
    talent: "evin Spacey",
    to_be_billed_as: "evin Spacey",
    role_type: "Special",
    start_Date: "12/12/2023",
    contact_type: "Schedule F",
    rate_guarantee: "$1,200.00, 2 week guarantee",
  },
  {
    role_name: "-",
    talent: "-",
    to_be_billed_as: "-",
    role_type: "-",
    start_Date: "-",
    contact_type: "-",
    rate_guarantee: "-",
  },
  {
    role_name: "-",
    talent: "-",
    to_be_billed_as: "-",
    role_type: "-",
    start_Date: "-",
    contact_type: "-",
    rate_guarantee: "-",
  },
  {
    role_name: "-",
    talent: "-",
    to_be_billed_as: "-",
    role_type: "-",
    start_Date: "-",
    contact_type: "-",
    rate_guarantee: "-",
  },
  {
    role_name: "-",
    talent: "-",
    to_be_billed_as: "-",
    role_type: "-",
    start_Date: "-",
    contact_type: "-",
    rate_guarantee: "-",
  },
];  
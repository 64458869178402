import React, { Component } from "react";
import { MDBRow, MDBCol, MDBIcon, MDBContainer } from "mdbreact";
import Radio from "@material-ui/core/Radio";
import { CircularProgress } from "@material-ui/core";
import SearchFieldWithAddValue from "../SharedComponents/SearchFieldWithAddValue";
import * as Constants from "../../../constants/constants";
import CasterService from "../../../services/service";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import MessageModal from "../../components/SharedComponents/MessageModal";
import PhoneComponent from "../../Common/DefaultPhoneComponent/PhoneComponent";
import { initialObjectConfig } from '../Talent/Config';
import { validateEmail, validateAllPhone, getFormattedDate } from '../../Common/Helper'
import InputSelectRadio from "../../Common/InputSelectRadioInline/InputSelectRadio";
import AddressComponent from "../../Common/AddressComponent/AddressComponent";
import './EditPerformerContact.scss';
import AddGuardian from "../../Common/AddGuardian/AddGuardian";
import Modal from '../../Common/MainViewModalLayout/Modal/Modal';



export default class EditPerformerContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            performerContactDetails: {},
            phoneOptions: [],
            emailOptions: [],
            addressOptions: [],
            stateOptions: [],
            countryOptions: [],
            showAddressPopup: false,
            showGuardianPopup: false,
            setPopOver: null,
            addressSelected: {},
            guardianList: [],
            guardianSelected: {},
            postPerformerContact: false,
            openMessageModal: false,
            is_update_talent_profile: 0,
            showError: false,
            showFail: false,
            showSuccess: false,
            fieldChanged: false,
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            cancelClicked: false,
            errorMessage: "Should have atleast one Guardian",
            guardianError: false
        }
    }

    componentDidMount = () => {
        this.formatPerformerContactDetails(this.props?.performerList)
        this.formatGuardianContactDetails(this.props?.guardianList)
        this.getLookupValues('Phone');
        this.getLookupValues('Email');
        this.getLookupValues('Address');
        this.getLookupValues('State');
        this.getLookupValues('Country');
    }

    // formatGuardianContactDetails = (data) => {
    //     let initObjectConfig = JSON.parse(JSON.stringify(initialObjectConfig));
    //     let phoneInitObject = JSON.parse(JSON.stringify(initialObjectConfig));
    //     data?.map((item) => {
    //         if (item.guardian_email_list?.length > 0) {
    //             let formattedObj = item?.guardian_email_list?.map(i => {
    //                 return {
    //                     object_id: i.performer_deal_guardians_email_id,
    //                     object_type: i.email_type,
    //                     value: i.email,
    //                     is_primary: i.is_primary,
    //                     is_delete: 0
    //                 }
    //             })
    //             item.guardian_email_list = [initObjectConfig, ...formattedObj]
    //         } else {
    //             item.guardian_email_list?.push(initObjectConfig);
    //         }
    //         if (item?.guardian_phone_list?.length > 0) {
    //             let formattedObj = item?.guardian_phone_list?.map(i => {
    //                 return {
    //                     object_id: i?.performer_deal_guardians_phone_id,
    //                     object_type: i?.phone_type,
    //                     value: i?.phone,
    //                     is_primary: i.is_primary,
    //                     is_delete: 0,
    //                     ext: i?.ext
    //                 }
    //             })
    //             item.guardian_phone_list = [phoneInitObject, ...formattedObj]
    //         } else {
    //             item.guardian_phone_list = [];
    //             item.guardian_phone_list.push(phoneInitObject);
    //         }
    //         item.showCollapsible = false;
    //     })
    //     this.setState({ guardianList: data })
    // }
    formatGuardianContactDetails = (guardians_list) => {
        let formattedGuardian = guardians_list?.map(item => {
            let isPrimaryPhoneExist = item?.guardian_phone_list?.findIndex(i => i.is_primary == 1)
            let isPrimaryEmailExist = item?.guardian_email_list?.findIndex(i => i.is_primary == 1)
            item.showCollapsible = false;
            item.guardian_phone_list = item?.guardian_phone_list?.map((phone, index) => {
                if ((isPrimaryPhoneExist == index) || (isPrimaryPhoneExist == -1 && index == 0)) {
                    phone.showAsRow = true
                }
                else {
                    phone.showAsRow = false
                }
                phone.phone_with_ext = `+${phone.ext} ${phone.phone}`
                return {
                    object_id: phone?.performer_deal_guardians_phone_id,
                    object_type: phone?.phone_type,
                    value: phone?.phone,
                    is_delete: phone?.is_delete || 0,
                    is_primary: phone?.is_primary,
                    ext: phone?.ext
                }
            })
            item.guardian_email_list = item?.guardian_email_list?.map((email, index) => {
                if ((isPrimaryEmailExist == index) || (isPrimaryEmailExist == -1 && index == 0)) {
                    email.showAsRow = true
                }
                else {
                    email.showAsRow = false
                }
                return {
                    object_id: email?.performer_deal_guardians_email_id,
                    object_type: email?.email_type,
                    value: email?.email,
                    is_delete: email?.is_delete || 0,
                    is_primary: email?.is_primary,
                    ext: null
                };
            })
            return item;
        })
        console.log("Foramtted Guardian", formattedGuardian);
        this.setState({ guardianList: formattedGuardian })
    }

    formatPerformerContactDetails = (item) => {
        let initObjectConfig = JSON.parse(JSON.stringify(initialObjectConfig));
        let phoneInitObject = JSON.parse(JSON.stringify(initialObjectConfig));
        // data?.map((item) => {
        if (item?.email?.length > 0) {
            let formattedObj = item?.email?.map(i => {
                return {
                    object_id: i.performer_deal_email_id,
                    object_type: i.email_type,
                    value: i.email,
                    is_primary: i.is_primary,
                    is_delete: 0
                }
            })
            item.email = [initObjectConfig, ...formattedObj]
        } else {
            item.email?.push(initObjectConfig);
        }
        if (item?.phone?.length > 0) {
            let formattedObj = item?.phone?.map(i => {
                return {
                    object_id: i?.performer_deal_phone_id,
                    object_type: i?.phone_type,
                    value: i?.phone,
                    is_primary: i.is_primary,
                    is_delete: 0,
                    ext: i?.ext
                }
            })
            item.phone = [phoneInitObject, ...formattedObj]
        } else {
            item.phone = [];
            item.phone.push(phoneInitObject);
        }
        // })
        this.setState({ performerContactDetails: item })
    }

    getLookupValues(type) {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`)
            .then(response => {
                if (!response || response?.data?.error) {
                    console.log("error", response?.data?.error)
                }
                else {
                    let formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, label: item?.lookup_value }))
                    if (type == 'Phone') {
                        this.setState({ phoneOptions: formattedList })
                    } else if (type == 'Email') {
                        this.setState({ emailOptions: formattedList })
                    } else if (type == 'Address') {
                        this.setState({ addressOptions: formattedList })
                    } else if (type == 'State') {
                        let formattedListState = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, text: item?.lookup_value }))
                        this.setState({ stateOptions: formattedListState })
                    } else if (type == 'Country') {
                        let formattedListCountry = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, text: item?.lookup_value }))
                        this.setState({ countryOptions: formattedListCountry })
                    }
                }
            }, err => {
                console.log("error from lookup")
            })
    }

    getFullAddress(item) {
        let { address, city, state, country, zip } = item;
        if (state?.includes('-')) {
            let splitArray = state?.split("-")
            state = splitArray[1]
        }
        return <div>
            {address ? <p> {address} </p> : ''}
            {<p>{`${city ? city : ''} ${state ? city ? ', ' + state : state : ''} ${zip ? state ? ' ' + zip : city ? ', ' + zip : zip : ''}`}</p>}
            {country ? (country !== "United States" && country !== "USA" && country !== "US") ? <p> {country} </p> : '' : ''}
        </div>
        // return `${address ? address + ',' : ''} ${city ? city + ',' : ''} ${state ? state + ',' : ''} ${zip ? zip : ''} ${(country !== "United States" && country !== "USA" && country !== "US") ? country ?  ',' + country : "" : ''}`;
    }

    onAddressChange(id, key, value) {
        let addressList = this.state?.performerContactDetails?.address?.length > 0 ? JSON.parse(JSON.stringify(this.state?.performerContactDetails?.address)) : [];
        let addressIndex = addressList?.findIndex(i => i.performer_deal_address_id == id);
        if (key == 'is_primary') {
            addressList?.map(i => i.is_primary = 0);
            addressList[addressIndex][key] = 1;
        } else if (key == 'is_delete') {
            addressList[addressIndex][key] = 1;
        } else if (id) {
            addressList[addressIndex] = value;
        } else {
            let newAddressList = addressList.filter(item => !item.is_delete)
            if (newAddressList?.length == 0) {
                value.is_primary = 1
            }
            else if (value["is_primary"] === 1) {
                addressList?.map(i => i.is_primary = 0);
            }
            value.is_delete = 0
            value.performer_deal_address_id = Date.now();
            value.type = "new"
            addressList?.push(value);
        }
        this.handleGeneralEdit('address', addressList);
    }

    addRemoveObject(list, value) {
        let objectList = [...this.state?.performerContactDetails?.[list]];
        objectList = objectList?.filter((i, index) => index != 0);
        let initObjectConfig = JSON.parse(JSON.stringify(initialObjectConfig));
        value.type = 'new'
        value.object_id = Date.now();
        value.is_primary = objectList?.length == 0 ? 1 : 0
        objectList = [initObjectConfig, ...objectList, value]
        this.handleGeneralEdit(list, objectList)
    }

    onChangeObject(list, id, key, value) {
        let objectList = [...this.state?.performerContactDetails?.[list]];
        let objectIndex = id ? objectList?.findIndex(i => i.object_id == id) : 0;
        if (key == 'is_primary') {
            objectList?.map(i => i.is_primary = 0);
            value = 1;
        }
        objectList[objectIndex][key] = value;
        this.handleGeneralEdit(list, objectList)
    }

    handleGeneralEdit = (field, value) => {
        this.setState(prevState => ({
            performerContactDetails: {
                ...prevState.performerContactDetails,
                [field]: value
            }
        })
        )
        this.fieldChanged(true, "change");
    }

    showPopup(type, id, data = {}) {
        let htmlElement = document.getElementById(id);
        this.setState({ setPopOver: htmlElement, [`${type}Selected`]: { ...data }, })
        switch (type) {
            case "guardian":
                this.setState({ showGuardianPopup: true })
                break;
            case "address":
                this.setState({ showAddressPopup: true })
                break;
            default: break;
        }
    }

    onClosePopup(type) {
        this.setState({ setPopOver: null, [`${type}Selected`]: {} })
        switch (type) {
            case "guardian":
                this.setState({ showGuardianPopup: false })
                break;
            case "address":
                this.setState({ showAddressPopup: false })
                break;
            default: break;
        }
    }

    onAddressSave(address) {
        console.log(address);
        this.onClosePopup('address')
        this.onAddressChange(this.state.addressSelected?.performer_deal_address_id, 'address', address)
    }

    toggleCollapse(id) {
        this.guardianChange(id, 'showCollapsible', true)
    }

    guardianChange(id, key, value) {
        let guradianList = JSON.parse(JSON.stringify(this.state?.guardianList));
        let guradianIndex = guradianList?.findIndex(i => i.performer_deal_guardians_id == id);
        if (key == 'is_primary') {
            guradianList?.map(i => i.is_primary = 0);
            guradianList[guradianIndex][key] = 1;
        } else if (key == 'is_delete') {
            guradianList[guradianIndex][key] = 1;
        }
        else if (key == 'showCollapsible') {
            guradianList[guradianIndex][key] = !guradianList[guradianIndex][key];
        } else if (id) {
            guradianList[guradianIndex] = value;
        }
        else {
            let newGuardianList = guradianList.filter(item => !item.is_delete)
            if (newGuardianList?.length == 0) {
                value.is_primary = 1
            } else if (value["is_primary"] === 1) {
                guradianList?.map(i => i.is_primary = 0);
            }
            // if (guradianList?.length == 0) {
            //     value.is_primary = 1
            // }
            value.performer_deal_guardians_id = Date.now();
            value.type = "new"
            guradianList?.push(value);
        }
        this.setState({ guardianList: guradianList })
        // this.handleGeneralEdit('guardians_list',guradianList)
    }

    getPhoneText(item) {
        return `${item?.value ? item?.value : ''} ${item?.ext ? 'ext.' + item?.ext : ''}`
    }

    getFullGuardianAddress(item) {
        // let { guardian_address, guardian_city, guardian_state, guardian_country, guardian_zip } = item;
        // return `${guardian_address ? guardian_address + ',' : ''} ${guardian_city ? guardian_city + ',' : ''} ${guardian_state ? guardian_state + ',' : ''} ${guardian_zip ? guardian_zip : ''} ${(guardian_country !== "United States" && guardian_country !== "USA" && guardian_country !== "US") ? guardian_country ? ',' + guardian_country : "" : ''}`;
        let { guardian_address, guardian_city, guardian_state, guardian_country, guardian_zip } = item;
        if (guardian_state?.includes('-')) {
            let splitArray = guardian_state?.split("-")
            guardian_state = splitArray[1]
        }
        return <div className="guardianAddressBorder">
            {guardian_address ? <p> {guardian_address} </p> : ''}
            {<p>{`${guardian_city ? guardian_city : ''} ${guardian_state ? guardian_city ? ', ' + guardian_state : guardian_state : ''} ${guardian_zip ? guardian_state ? ' ' + guardian_zip : guardian_city ? ', ' + guardian_zip : guardian_zip : ''}`}</p>}
            {guardian_country ? (guardian_country !== "United States" && guardian_country !== "USA" && guardian_country !== "US") ? <p> {guardian_country} </p> : '' : ''}
        </div>
    }

    onGuardianSave(guardian) {
        console.log(guardian);
        this.onClosePopup('guardian')
        guardian.selected_alias_name = `${guardian.guardian_first_name ? guardian.guardian_first_name : ""} ${guardian.guardian_middle_name ? guardian.guardian_middle_name : ""} ${guardian.guardian_last_name ? guardian.guardian_last_name : ""} ${guardian.guardian_suffix ? guardian.guardian_suffix : ""}`
        this.guardianChange(this.state.guardianSelected?.performer_deal_guardians_id, 'guardians_list', guardian)
    }

    formatPostJson = () => {
        let performerList = JSON.parse(JSON.stringify({ ...this.state.performerContactDetails }))
        performerList.email = performerList.email?.slice(1) || [];
        performerList.phone = performerList.phone?.slice(1) || [];
        if (performerList.email?.length > 0) {
            performerList.email = performerList.email?.reduce((acc, i) => {
                i.object_id = i?.type == 'new' ? null : i?.object_id
                if (i?.object_id || (!i?.object_id && !i?.is_delete))
                    acc = [...acc, {
                        performer_deal_email_id: i?.object_id,
                        email_type: i?.object_type,
                        email: i?.value,
                        is_delete: i?.is_delete || 0,
                        is_primary: i?.is_primary

                    }]
                return acc;
            }, [])
        }
        if (performerList.address?.length > 0) {
            performerList.address = performerList.address?.reduce((address, i) => {
                // if(i?.type == 'new')
                address = [...address, {
                    performer_deal_address_id: i?.type == 'new' ? null : i?.performer_deal_address_id,
                    address: i?.address,
                    address_type: i?.address_type,
                    city: i?.city,
                    country: i?.country,
                    is_default: i?.is_default,
                    is_delete: i?.is_delete,
                    is_primary: i?.is_primary,
                    performer_deal_id: i?.performer_deal_id,
                    state: i?.state,
                    zip: i?.zip
                }]
                return address
            }, [])
        }

        if (performerList.phone?.length > 0) {
            performerList.phone = performerList.phone?.reduce((acc, i) => {
                i.object_id = i?.type == 'new' ? null : i?.object_id
                if (i?.object_id || (!i?.object_id && !i?.is_delete))
                    acc = [...acc, {
                        performer_deal_phone_id: i?.object_id,
                        phone_type: i?.object_type,
                        phone: i?.value,
                        is_delete: i?.is_delete || 0,
                        is_primary: i?.is_primary,
                        ext: i?.ext ? parseInt(i?.ext) : null

                    }]
                return acc;
            }, [])
        }
        let guardianList = JSON.parse(JSON.stringify(this.state.guardianList))
        guardianList = guardianList?.reduce((acc, item) => {
            item.performer_deal_guardians_id = item?.type == 'new' ? null : item?.performer_deal_guardians_id;
            item.performer_deal_guardians_address_id = item?.type == 'new' ? null : item?.performer_deal_guardians_address_id;
            if (item?.performer_deal_guardians_id || (!item?.performer_deal_guardians_id && !item?.is_delete)) {
                item.guardian_email_list = item?.guardian_email_list?.reduce((accEmail, i) => {
                    i.object_id = i?.type == 'new' ? null : i?.object_id
                    if (i?.object_id || (!i?.object_id && !i?.is_delete))
                        accEmail = [...accEmail, {
                            performer_deal_guardians_email_id: i?.object_id,
                            email_type: i?.object_type,
                            email: i?.value,
                            is_delete: i?.is_delete || 0,
                            is_primary: i?.is_primary

                        }]

                    return accEmail;
                }, [])
                item.guardian_phone_list = item?.guardian_phone_list?.reduce((accPhone, i) => {
                    i.object_id = i?.type == 'new' ? null : i?.object_id
                    if (i?.object_id || (!i?.object_id && !i?.is_delete))
                        accPhone = [...accPhone, {
                            performer_deal_guardians_phone_id: i?.object_id,
                            phone_type: i?.object_type,
                            phone: i?.value,
                            is_delete: i?.is_delete || 0,
                            is_primary: i?.is_primary,
                            ext: i?.ext ? parseInt(i?.ext) : null
                        }]
                    return accPhone
                }, [])
                acc = [...acc, item]
            }
            return acc;
        }, [])
        this.setState({ performerContactDetails: performerList, guardianList: guardianList }, () => {
            this.postPerformerContact();
        })
    }

    // validationForSave = () => {
    //     let canSave = false;
    //     if ((this.props?.age && this?.props?.age < 18) || (this.props?.monthsAge && this.props?.age === 0) && (this.props?.age != 0 && this.props?.monthsAge != 0)) {
    //         if(this.state.guardianList.length > 0) {
    //             this.state.guardianList?.forEach((g) => {
    //                 if (g.guardian_first_name != null && g.guardian_last_name != null && g.relation != null && g.guardian_email_list?.length > 0) {
    //                     g.guardian_email_list?.forEach((email) => {
    //                         if(email.value != null && email.is_delete != true) {
    //                             canSave = true;
    //                         } else {
    //                             canSave = false
    //                         }
    //                     })
    //                 }
    //                 else {
    //                     canSave = false
    //                     this.setState({ errorMessage: "Guardian should have an email"}, () => {
    //                         this.fieldChanged(true, "guardianError");
    //                     });
    //                 }
    //             })
    //             if (canSave) {
    //                 this.formatPostJson()
    //             }
    //             else if (canSave === false) {
    //                 this.setState({ errorMessage: "Guardian should have an email"}, () => {
    //                     this.fieldChanged(true, "guardianError");
    //                 });
    //             }
    //         }
    //         else {
    //             this.fieldChanged(true, "guardianError");
    //         }
    //     }
    //     else {
    //         this.formatPostJson()
    //     }
    // }

    postPerformerContact = () => {
        this.setState({ postPerformerContact: true })
        let postJson = {
            "performer_deal_id": this.state.performerContactDetails?.performer_deal_id,
            "is_send_payment_to": this.state.performerContactDetails?.is_send_payment_to,
            "is_update_talent_profile": this.state.is_update_talent_profile,
            "type": "PERFORMER",
            "performer": this.state.performerContactDetails,
            "guardians": this.state.guardianList
        }
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/casterFeaturePerformerDealContact`, postJson, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ postPerformerContact: false }, () => {
                        this.fieldChanged(true, "fail");
                    })
                    console.log("Error posting Talent Performer Details data");
                }
                else {
                    if (this.state.guardianList?.length > this.props?.guradianList || this.state?.guradianList?.filter(i => i?.is_delete)?.length > 0) {
                        this.getTalentPerformerDetails();
                    }
                    this.setState({ postPerformerContact: false }, () => {
                        this.fieldChanged(true, "success");
                        this.props?.getContactDetails();
                        this.props?.getGuardianDetails();
                        this.props?.getPeformerDetailsFlags()
                        this.props?.onClose();
                    })
                    console.log("Posted successfully", this.state.performerDetails)
                }
            },
                (err) => {
                    this.setState({ postPerformerContact: false }, () => {
                        this.fieldChanged(true, "fail");
                    })
                    console.log("Error posting Talent Perfomer data", err)
                })
    }

    updateTalentProfile = () => {
        this.setState({ is_update_talent_profile: 1, openMessageModal: false }, () => {
            this.formatPostJson()
        })
    }

    updateRecord = () => {
        if (this.state.is_update_talent_profile !== 0) {
            this.setState({ is_update_talent_profile: 0, openMessageModal: false })
        }
        this.setState({ openMessageModal: false }, () => {
            this.formatPostJson()
        })
    }

    closPopup = () => {
        this.props?.onClose()
    }

    fieldChanged = (value, mode = "error") => {
        switch (mode) {
            case "change": return this.setState({ fieldChanged: value });
            case "error": return this.setState({ fieldChanged: value });
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
            case "guardianError": return this.setState({ guardianError: value });
        }
    }

    render() {
        let confirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveTitle : this.state.guardianError ? this.state.unsavedTitle : "";
        let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveEditMessage : this.state.guardianError ? this.state.errorMessage : "";
        return (
            <div>
                {
                    <MessageModal
                        open={this.state.showError || this.state.showFail || this.state.showSuccess || this.state.guardianError || false}
                        title={confirmModalTitle}
                        message={message}
                        hideCancel={this.state.showFail || this.state.showSuccess || this.state.guardianError || false}
                        primaryButtonText={"OK"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={() => this.state.guardianError ? this.setState({ guardianError: false }) : this.closPopup()}
                        handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false, guardianError: false })} />
                }
                <MDBContainer className="PerformerContact">
                    <br />
                    <div className="performer-header">Edits to the below Contact information will be updated to the Talent profile</div>
                    <br />
                    <MDBRow className="performer-name">
                        PERFORMER | {this.props?.talent_name}
                    </MDBRow>
                    <br />
                    <MDBRow>
                        <MDBCol md={5}>
                            <div className='addLabel' id="newAddressBtn" onClick={() => this.showPopup('address', 'newAddressBtn', 'new')}>
                                <span>
                                    <MDBIcon icon="plus-circle" />
                                    Add Address
                                </span>
                            </div>
                            {this.state?.performerContactDetails?.address?.filter(i => i?.is_delete == 0)?.map((item, index) => (
                                <>
                                    <MDBRow className='addressRow'>
                                        <MDBCol md={4} className="noPadding">
                                            <p className='spanText'>{item?.address_type}</p>
                                        </MDBCol>
                                        <MDBCol md={6} className="actualAddress">
                                            <p className={`spanText ${item?.is_primary == 1 ? 'primaryStarIcon' : ''}`}>{this.getFullAddress(item)}</p>
                                        </MDBCol>
                                        <MDBCol md={2} className='noPadding iconContainer'>
                                            <>
                                                <MDBIcon icon="pen" onClick={() => this.showPopup('address', 'newAddressBtn', item)} />
                                                <input
                                                    name='addressPrimary'
                                                    id={`radioAddress_${item?.performer_deal_address_id}`}
                                                    size="small"
                                                    type="radio"
                                                    value={item?.performer_deal_address_id}
                                                    defaultChecked={item?.is_primary == 1 ? true : false}
                                                    onChange={(e) => this.onAddressChange(item?.performer_deal_address_id, 'is_primary', e)}
                                                />
                                                <MDBIcon icon="times" onClick={() => this.onAddressChange(item?.performer_deal_address_id, 'is_delete', 1)} />
                                            </>
                                        </MDBCol>
                                    </MDBRow>
                                </>
                            ))}
                        </MDBCol>
                        <MDBCol md={4}>
                            {this.state?.performerContactDetails?.phone?.filter(i => i?.is_delete == 0)?.map((phone, index) => (
                                <PhoneComponent
                                    radioGroupName='performerPhoneRadio'
                                    showAsLabel={false}
                                    isEditing={true}
                                    objectItem={phone}
                                    ext={true}
                                    inValidInput={"Please enter valid phone"}
                                    addCallback={(obj) => this.addRemoveObject('phone', obj)}
                                    removeCallback={(id, key, value) => this.onChangeObject('phone', id, key, value)}
                                    editCallback={(id, key, value) => this.onChangeObject('phone', id, key, value)}
                                    selectOptions={this.state?.phoneOptions}
                                    showAdd={index == 0 ? true : false}
                                    showRemove={index != 0 ? true : false}
                                    showRadio={index != 0 ? true : false}
                                    inputValidateMethod={validateAllPhone}
                                />
                            ))}
                        </MDBCol>
                        <MDBCol md={3} id="performer-contact-email">
                            {this.state?.performerContactDetails?.email?.filter(i => i?.is_delete == 0)?.map((emailItem, index) => (
                                <InputSelectRadio
                                    radioGroupName='performerEmailRadio'
                                    inputPlaceholder="Enter Email"
                                    showAsLabel={false}
                                    isEditing={true}
                                    hideActions={false}
                                    objectItem={emailItem}
                                    addCallback={(emailObj) => this.addRemoveObject('email', emailObj)}
                                    removeCallback={(id, key, value) => this.onChangeObject('email', id, key, value)}
                                    editCallback={(id, key, value) => this.onChangeObject('email', id, key, value)}
                                    selectOptions={this.state?.emailOptions}
                                    showAdd={index == 0 ? true : false}
                                    showRemove={index != 0 ? true : false}
                                    showRadio={index != 0 ? true : false}
                                    inputValidateMethod={validateEmail}
                                />
                            ))}
                        </MDBCol>
                    </MDBRow>
                    <br />
                    {(this.props?.age === 0 && this.props?.monthsAge) || this.props?.age < 18 ? this.state?.guardianList?.filter(i => !i.is_delete)?.map((item, index) => (
                        <>
                            <MDBRow className='guradianRow' key={`header_${item?.performer_deal_guardians_id}`}>
                                <MDBCol md={11} className='noPadding' onClick={() => this.toggleCollapse(item?.performer_deal_guardians_id)}>
                                    <MDBRow>
                                        <MDBCol md={3}>
                                            <div className={`small-text guardianName ${item?.is_primary == 1 ? 'primaryStarIcon' : ''}`}>
                                                <div className='upperCase'>{item?.relation}</div>
                                                <div className={`spanText leftBorder`}>{item?.selected_alias_name}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md={3}>
                                            {item?.guardian_phone_list?.filter(i => i?.is_delete == 0)?.map((phoneItem, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <div className='small-text'>
                                                            <div className=''>{phoneItem?.object_type}</div>
                                                            <div className={`spanText leftBorder ${phoneItem?.is_primary == 1 ? 'primaryStarIconBorder' : ''}`}>{this.getPhoneText(phoneItem) || ''}</div>
                                                        </div>)
                                                }
                                                return null;
                                            })}
                                        </MDBCol>
                                        <MDBCol md={3}>
                                            {item?.guardian_email_list?.filter(i => i?.is_delete == 0)?.map((emailItem, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <div className='small-text'>
                                                            <div className=''>{emailItem?.object_type}</div>
                                                            <div className={`spanText leftBorder ${emailItem?.is_primary == 1 ? 'primaryStarIconBorder' : ''}`}>{emailItem?.value}</div>
                                                        </div>)
                                                }
                                                return null;
                                            }
                                            )}
                                        </MDBCol>
                                        <MDBCol md={3}>
                                            <div className='small-text'>
                                                <div className='spanText'>{item?.guardian_address_type}</div>
                                                <div className='spanText leftBorder'>{this.getFullGuardianAddress(item)}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol md={1} className='noPadding iconContainer'>
                                    {!item?.showCollapsible ? <MDBIcon icon="chevron-down" onClick={() => this.toggleCollapse(item?.performer_deal_guardians_id)} /> :
                                        <MDBIcon icon="chevron-up" onClick={() => this.toggleCollapse(item?.performer_deal_guardians_id)} />}
                                    <>
                                        <MDBIcon icon="arrows-alt-v" className='transform_arrow' onClick={() => this.showPopup('guardian', 'newGuardianBtn', item)} />
                                        <input
                                            name='guradianPrimary'
                                            id={`radio_${item?.performer_deal_guardians_id}`}
                                            size="small"
                                            type="radio"
                                            value={item?.performer_deal_guardians_id}
                                            defaultChecked={item?.is_primary == 1 ? true : false}
                                            onChange={(e) => this.guardianChange(item?.performer_deal_guardians_id, 'is_primary', 1)}
                                        />
                                        <MDBIcon icon="times" onClick={() => this.guardianChange(item?.performer_deal_guardians_id, 'is_delete', 1)} />
                                    </>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow key={`collapse_${item?.performer_deal_guardians_id}`} className={`guradianRow collapse ${item?.showCollapsible ? 'show' : ''}`}>
                                <MDBCol md={10} className='noPadding'>
                                    <MDBRow>
                                        <MDBCol md={3}>
                                            <span>  </span>
                                        </MDBCol>
                                        <MDBCol md={3}>
                                            {item?.guardian_phone_list?.filter((i) => i?.is_delete == 0)?.map((phoneItem, index) => {
                                                if (index != 0) {
                                                    return (
                                                        <div className='small-text'>
                                                            <span className=''>{phoneItem?.object_type}</span>
                                                            <span className={`spanText leftBorder ${phoneItem?.is_primary == 1 ? 'primaryStarIconBorder' : ''}`}>{this.getPhoneText(phoneItem) || ''}</span>
                                                        </div>)

                                                }
                                                return null;
                                            })}
                                        </MDBCol>
                                        <MDBCol md={3}>
                                            {item?.guardian_email_list?.filter(i => i?.is_delete == 0)?.map((emailItem, index) => {
                                                if (index != 0) {
                                                    return (
                                                        <div className='small-text'>
                                                            <span className=''>{emailItem?.object_type}</span>
                                                            <span className={`spanText leftBorder ${emailItem?.is_primary == 1 ? 'primaryStarIconBorder' : ''}`}>{emailItem?.value}</span>
                                                        </div>)
                                                }
                                                return null;
                                            }
                                            )}
                                        </MDBCol>
                                        <MDBCol md={3}>
                                            <span>  </span>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </>
                    )) : ""}
                    <MDBRow>
                        <MDBCol md={3}>
                            {this.props.age < 18 && this.props?.birth_date != null ?
                                <div className='addLabel' id="newGuardianBtn" onClick={() => this.showPopup('guardian', 'newGuardianBtn', 'new')}>
                                    <span>
                                        <MDBIcon icon="plus-circle" />
                                        Add Guardian
                                    </span>
                                </div>
                                : null}
                        </MDBCol>
                        <MDBCol md={5}></MDBCol>
                        <MDBCol md={2} className="d-flex justify-content-end">
                            <BasicButton
                                text={this.state?.postPerformerContact ? <CircularProgress color="inherit" size={20} /> : "Save"}
                                variant="contained"
                                type="inline"
                                icon={"save"}
                                onClick={this.updateTalentProfile.bind(this)}
                            />
                        </MDBCol>
                        <MDBCol md={2} className="d-flex justify-content-end">
                            <BasicButton
                                text="Cancel"
                                variant="outlined"
                                type="inline"
                                icon="times"
                                onClick={this.props?.onClose}
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                {this.state?.showAddressPopup &&
                    <AddressComponent
                        open={this.state?.showAddressPopup}
                        setPopOver={this.state?.setPopOver}
                        handleClose={this.onClosePopup.bind(this, 'address')}
                        onSave={(address) => { this.onAddressSave(address) }}
                        mode={this.state.addressSelected?.performer_deal_address_id ? 'edit' : 'new'}
                        id={this.state.addressSelected?.performer_deal_address_id || null}
                        selectedAddress={this.state?.addressSelected}
                        popOverClose={() => this.onClosePopup('address')}
                    />
                }

                {this.state?.showGuardianPopup &&
                    <AddGuardian
                        open={this.state?.showGuardianPopup}
                        isEditing={true}
                        setPopOver={this.state?.setPopOver}
                        handleClose={this.onClosePopup.bind(this, 'guardian')}
                        onSave={(guardian) => { this.onGuardianSave(guardian) }}
                        mode={this.state.guardianSelected?.performer_deal_guardians_id ? 'edit' : 'new'}
                        id={this.state.guardianSelected?.performer_deal_guardians_id}
                        selectedGuardian={this.state?.guardianSelected}
                        stateOptions={this.state?.stateOptions}
                        countryOptions={this.state?.countryOptions}
                        emailOptions={this.state?.emailOptions}
                        phoneOptions={this.state?.phoneOptions}
                        addressOptions={this.state?.addressOptions}
                    />}
                {
                    this.state.openMessageModal &&
                    <Modal
                        modalName={"Performer Confirmation Modal"}
                        open={this.state.openMessageModal}
                        confirmationMessage={"Would you like to save this data for this talent record only, or also update their global Talent Profile?"}
                        primaryButtonText={"This Record Only"}
                        secondaryButtonText={"Also update Talent profile"}
                        handleClose={() => { this.setState({ openMessageModal: false }) }}
                        updateTalentProfile={() => { this.updateTalentProfile() }}
                        updateRecord={() => { this.updateRecord() }}
                        mode="Both"
                        headerText=""
                    />
                }
            </div>
        )
    }
}
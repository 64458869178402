import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from "../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import { withUserContext } from "../../../contexts/UserContext";
import { CircularProgress } from "@material-ui/core";
import { Popover } from "@material-ui/core";
import "./NewProductionCompany.scss";
import CrewModal from "../../components/Crew/CrewContainer";
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import NotificationComponent from "../../Common/NotificationComponent/NotificationComponent";
import { initialNewProdCompanyDetails } from "./Config";
import ModalComponent from "../../Common/DefaultModalLayout/Modal/Modal";
import PhoneComponent from "../../Common/DefaultPhoneComponent/PhoneComponent";
import { validatePlusCharacter, validateEmail, validateAllPhone, checkZipCode, formatZipCode } from "../../Common/Helper";
import InputSelectRadio from "../../Common/InputSelectRadioInline/InputSelectRadio";
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import axios from 'axios';
import BasicTextArea from '../../components/SharedComponents/BasicTextArea/BasicTextArea';
import messages from '../Messages.json';
import sizeLimits from '../SizeLimits.json';

class NewProductionCompanyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newProdCompanyDetails: initialNewProdCompanyDetails,
      isSubmit: false,
      isSaveEdit: false,
      showError: false,
      showSuccess: false,
      showFail: false,
      saveFlag: false,
    };
  }
  getLookupValues = (type) => {
    CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`, this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {

        let formattedList = response.data?.map(item => ({ value: item?.lookup_value, label: item?.lookup_value, id: item?.lookup_data_id }));
        if (type == "Country") {
          let formattedListCountry = response.data?.map(item => ({ value: item?.lookup_value, text: item?.lookup_value, id: item?.lookup_data_id }));
          let defaultCountry = formattedListCountry.filter(item => {
            return (item.value === "United States" || item.value === "USA" || item.value === "US")
          })
          if (defaultCountry.length > 0) {
            this.setState((prevState) => ({
              newProdCompanyDetails: {
                ...prevState?.newProdCompanyDetails,
                ["country"]: { value: defaultCountry[0]?.id, text: defaultCountry[0]?.value }
                // ["country"]: { value: defaultCountry[0]?.value, text: defaultCountry[0]?.value, id: defaultCountry[0]?.id }
              },
              countryList: formattedListCountry
            }))
          }
        }
        else if (type == "State") {
          let formattedListState = response.data?.map(item => ({ value: item?.lookup_data_id, text: item?.lookup_value }));
          //this.setState({ stateList: formattedListState });
          this.setState({
            stateList: formattedListState
          })
        }
      },
        (err) => {
          console.log("Error in fetching lookup:", err)
        })
  }
  componentDidMount() {
    if (this.props?.prodCompanyData) {
      this.getLookupValues('Country');
      this.getLookupValues('State');
      this.setState((prevState) => ({
        newProdCompanyDetails: {
          ...prevState.newProdCompanyDetails,
          ["production_company"]: this.props?.prodCompanyData?.value,
        },
      }));
    }
    // this.handleOnchange("first_name", this.props?.crewData?.value);
  }
  handleResetNotify = () => {
    this.setState({ showFail: false, showSuccess: false });
    if (this.state.saveFlag) {
      this.setState({ saveFlag: false });
    }
  };

  handleOnchange = (field, value) => {
    if (field === "country") {
      this.setState((prevState) => ({
        newProdCompanyDetails: {
          ...prevState.newProdCompanyDetails,
          ["state"]: null,
          ["country"]: value
        },
      }));
    } else if (field == 'state' && (this.state.newProdCompanyDetails?.state?.value)) {
      let defaultCountry = this.state.countryList.find(item => {
        return (item.text === "United States" || item.text === "USA" || item.text === "US")
      })
      this.setState((prevState) => ({
        newProdCompanyDetails: {
          ...prevState?.newProdCompanyDetails,
          ['country']: defaultCountry,
          // ...(field == 'country') && { 'state': null }
        }
      }))
    } else {
      this.setState((prevState) => ({
        newProdCompanyDetails: {
          ...prevState.newProdCompanyDetails,
          [field]: value,
        },
      }));
    }
  };
  notificationComponent = (value, mode = "fail") => {
    switch (mode) {
      case "success":
        return this.setState({ showSuccess: value, saveFlag: true });
      case "fail":
        return this.setState({ showFail: value });
    }
  };


  validateBeforeSave() {
    let isValid = false;
    this.setState({ showError: false })
    let address = { ...this.state?.newProdCompanyDetails?.address }
    address = { ...address, country: address?.country?.value };
    address?.country === 'United States' || address?.country === 'USA' || address?.country === 'US' ? address?.state ? address = { ...address, state: address?.state?.value } : null : null
    isValid = (address?.zip?.length > 0 ? (address?.country === 'United States' || address?.country === 'USA' || address?.country === 'US' ? (address?.zip.length <= sizeLimits?.intlZipCharacterLimit && (address?.zip?.length == sizeLimits?.intlZipCharacterLimit ? checkZipCode(address?.zip) : true)) : true) : true)
    if (isValid) {
      this.setState({ showError: false }, () => { this.onSave() })
    }
    else {
      this.setState({ showError: true })
    }
  }

  onSave = () => {
    let postJson = { ...this.state.newProdCompanyDetails };
    if (typeof postJson.state == "object") {
      postJson.state = this.state.newProdCompanyDetails?.state?.text
    }
    if (typeof postJson.country == "object") {
      postJson.country = this.state.newProdCompanyDetails?.country?.text
    }
    this.setState({ isSubmit: true })
    CasterService.postDataParams(
      Constants.CasterServiceBaseUrl + "/casterFeatureProductionCompany",
      postJson,
      this.props.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (response?.data?.error) {
          this.setState({ isSubmit: false, showError: false });
          this.notificationComponent(true, "fail");
        } else {
          this.setState({ isSubmit: false, showError: false });
          this.props?.setNewObjectSelected('production_company', postJson);
          this.notificationComponent(true, "success");
          this.props?.handleClose();
        }
      },
      (err) => {
        this.setState({ isSubmit: false, showError: false });
        this.notificationComponent(true, "fail");
      }
    );
  }
  render() {
    let severity = this.state.showFail
      ? "error"
      : this.state.showSuccess
        ? "success"
        : "";
    let messageNotification = this.state.showSuccess
      ? "The changes are saved successfully!"
      : this.state.showFail
        ? "Saving changes failed!"
        : "";
    return (
      <div disableEnforceFocus>
        <NotificationComponent
          open={this.state.showSuccess || this.state.showFail}
          message={messageNotification}
          severity={severity}
          handleResetNotify={this.handleResetNotify.bind(this)}
        />
        <Popover
          open={this.props.open}
          anchorEl={this.props?.setPopOver}
          onClose={() => {
            this.props?.handleClose();
          }}
          className="newProdCompanyPopover"
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{ style: { width: "45%", height: '55%', backgroundColor: "#F2F2F2" } }}
        >
          <MDBContainer className="NewProdCompanyModalContainer caster-feature">
            {/* {this.props?.isDashboard ? (
              <MDBRow>
                <MDBCol md={12}>
                  <div>New Production Company will be displayed in application as:</div>
                </MDBCol>
              </MDBRow>
            ) : null} */}
            <>
              <MDBRow className='bottomMargin'>
                <MDBCol md={6}>
                  <BasicTextField
                    label={"Production Company Name"}
                    value={this.state.newProdCompanyDetails?.production_company}
                    disabled={true}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className='bottomMargin'>
                <MDBCol md={6}>
                  <BasicTextField
                    label={"Signatory"}
                    value={this.state.newProdCompanyDetails?.signator}
                    placeholder="Enter Signatory"
                    isMandatory={false}
                    onChange={(e) =>
                      this.handleOnchange("signator", e.target.value)
                    }
                    handleAlphabetVariantSelect={(value) => this.handleOnchange("signator", value)}
                    isAlphabetVar={true}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md={8}>
                  <BasicTextArea
                    id={"addressLine"}
                    rows={4}
                    value={this.state?.newProdCompanyDetails?.address}
                    limit={sizeLimits.addressCharacterLimit}
                    limitWarning={messages.exceed100CharacterWarning || ""}
                    label={"Address"}
                    // placeholder={"Address"}
                    showAsLabel={this.props.disabled || false}
                    onChange={(e) => this.handleOnchange("address", e.target.value.length > 0 ? e.target.value : null)}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md={6}>
                  <BasicTextField id="city"
                    value={this.state?.newProdCompanyDetails?.city}
                    onChange={(e) => this.handleOnchange('city', e.target.value)}
                    label={"City"}
                    // placeholder="City"
                    disabled={this.props.disabled || false}
                    limit={sizeLimits.cityCharacterLimit}
                    showAsLabel={this.props.disabled || false}
                    inValidInput={messages.invalidInput || ""}
                    limitWarning={messages.exceed20CharacterWarning || ""} />
                </MDBCol>
                <MDBCol md={6} className="state">
                  {
                    (this.state?.newProdCompanyDetails?.country?.text === 'United States' || this.state?.newProdCompanyDetails?.country?.text === 'USA' || this.state?.newProdCompanyDetails?.country?.text === 'US') ?
                      <SearchSelectField
                        id={"state"}
                        disabled={this.props.disabled || false}
                        detail_selected={this.state?.newProdCompanyDetails?.state}
                        value={this.state?.newProdCompanyDetails?.state || []}
                        options={this.state.stateList || []}
                        //valueSelected={(e, value) => this.handleOnchange('state', value)}
                        onChange={(e, value) => this.handleOnchange('state', value)}
                        width={'100%'}
                        showAsLabel={this.props.disabled || false}
                        label={"State"}
                      />
                      :
                      <BasicTextField id="state"
                        placeholder={"State/Province"}
                        limit={sizeLimits.stateCharacterLimit}
                        disabled={this.props.disabled || false}
                        inValidInput={messages.invalidInput || ""}
                        label={"State"}
                        limitWarning={messages.exceed20CharacterWarning || ""}
                        // placeholder={"State/Province"}
                        value={this.state?.newProdCompanyDetails?.state}
                        onChange={(e) => this.handleOnchange('state', e.target.value)}
                        // disabled={this.props.list[addressConfig?.countryId] ? false : true}
                        showAsLabel={this.props.disabled || false} />

                  }
                </MDBCol>
              </MDBRow>
              <MDBRow className='bottomMargin'>
                <MDBCol md={4}>
                  <BasicTextField id="zipCode"
                    disabled={this.props.disabled || false}
                    // limit={(this.state?.newProdCompanyDetails?.country == 'United States' && this.state?.newProdCompanyDetails?.country == 'USA' && this.state?.newProdCompanyDetails?.country == 'US') ? sizeLimits.intlZipCharacterLimit : null}
                    // limitWarning={(this.state?.newProdCompanyDetails?.country != 'United States' && this.state?.newProdCompanyDetails?.country != 'USA' && this.state?.newProdCompanyDetails?.country != 'US') ? null :  messages.addressLimitIntl}
                    value={this.state?.newProdCompanyDetails?.zip}
                    onChange={(e) => (this.state?.newProdCompanyDetails?.country?.value == 'United States' || this.state?.newProdCompanyDetails?.country?.value == 'USA' || this.state?.newProdCompanyDetails?.country?.value == 'US') ? this.handleOnchange('zip', formatZipCode(e.target.value)) :
                      this.handleOnchange('zip', e.target.value)}
                    // onChange={(e) => 
                    //     this.handleOnchange('zip', this.props?.isTalent ? formatZipCode(e.target.value) : e.target.value)}
                    // placeholder="Zip Code"
                    label={"Postal Code"}
                    showAsLabel={this.props.disabled || false}
                    inValidInput={messages.invalidInput}
                    fieldValid={this.state?.newProdCompanyDetails?.zip ? ((this.state?.newProdCompanyDetails?.country?.value == 'United States' || this.state?.newProdCompanyDetails?.country?.value == 'USA' || this.state?.newProdCompanyDetails?.country?.value == 'US') ? (this.state.address?.zip?.length > 9 ? !checkZipCode(this.state.address?.zip) ? true : false : false) : false) : false} />
                </MDBCol>
                <MDBCol md={6} className="countryRow">
                  <SearchSelectField
                    id={"country"}
                    disabled={this.props.disabled || false}
                    detail_selected={this.state?.newProdCompanyDetails?.country}
                    value={this.state?.newProdCompanyDetails?.country}
                    //valueSelected={(e, value) => this.handleOnchange('country', value)}
                    options={this.state.countryList || []}
                    onChange={(e, value) => this.handleOnchange('country', value)}
                    width={'100%'}
                    showAsLabel={this.props.disabled || false}
                    label={"Country"}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className='bottomMargin'>
                <MDBCol md={8}></MDBCol>
                <MDBCol md={2} className="d-flex justify-content-end">
                  <BasicButton
                    type="inline"
                    variant="contained"
                    text={this.state.isSubmit ? <CircularProgress color="inherit" size={20} /> : " "}
                    icon={this.state.isSubmit ? "" : "save"}
                    onClick={() => this.validateBeforeSave()}
                  />
                </MDBCol>
                <MDBCol md={2} className="d-flex justify-content-end">
                  <BasicButton
                    type="inline"
                    variant="outlined"
                    text=" "
                    icon={"times"}
                    onClick={this.props?.handleClose}
                  />
                </MDBCol>

              </MDBRow>
            </>
          </MDBContainer>
        </Popover>
      </div>
    );
  }
}
export default withUserContext(NewProductionCompanyModal);